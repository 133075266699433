import React from 'react';
import Grid from '@material-ui/core/Grid';

import SelectBox from '../../../UI/inputs/SelectBox';
import withErrorHandler from '../../../../hoc/withErrorHandler';

import { intraoralAreaOfInterest } from '../../../../utils/forms/options/form-options';

const DigitalImpression = ({ values, handleChange, scanOption, errors }) => {
	return (
		<Grid container item spacing={2} alignItems="flex-start">
			<Grid item xs={12} className="global-input-width">
				<SelectBox
					label="Area of Interest"
					placeHolder="Select"
					name="intraoralDetails.cadcam_area"
					value={values?.intraoralDetails?.cadcam_area}
					onChange={handleChange}
					options={intraoralAreaOfInterest}
					required={true}
					translatedOptions={true}
					error={errors?.intraoralDetails?.cadcam_area}
				/>
			</Grid>
		</Grid>
	);
};

export default withErrorHandler(DigitalImpression);
