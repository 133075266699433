import instance from '../../axios/ctdentAxios';

import {
	CTDENT_STRIPE_SAVE_CARD,
	CTDENT_STRIPE_INVOICES,
	CTDENT_STRIPE_DEBIT_BALANCE,
	DOCTOR_STRIPE_INVOICE_DETAIL,
	CTDENT_SAVE_BILLING_DETAILS,
	CTDENT_SAVE_BILLING_INVOICE_EMAIL_DETAILS,
	CTDENT_SAVE_BILLING_ADDRESS_DETAILS,
} from '../../assets/conf/api-endpoints';
import { apiConfig } from '../common.service';

// * save card details
export async function stripeSaveCard(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_STRIPE_SAVE_CARD,
		requestParam,
		reqConfig,
	);
	return response;
}

// * save card details
export async function saveBillingDetails(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_SAVE_BILLING_DETAILS,
		requestParam,
		reqConfig,
	);
	return response;
}

// * save billing invoice email details
export async function saveBillingInvoiceEmailDetails(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_SAVE_BILLING_INVOICE_EMAIL_DETAILS,
		requestParam,
		reqConfig,
	);
	return response;
}

// * save billing invoice email details
export async function saveBillingAddressDetails(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_SAVE_BILLING_ADDRESS_DETAILS,
		requestParam,
		reqConfig,
	);
	return response;
}

// * fetch all invoices
export async function getAllInvoices(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.get(CTDENT_STRIPE_INVOICES, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

// * fetch invoice detail
export async function getInvoiceDetail(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.get(DOCTOR_STRIPE_INVOICE_DETAIL, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

// * fetch debit balance
export async function getDebitBalance(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.get(CTDENT_STRIPE_DEBIT_BALANCE, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}
