import React from "react";

/**
 * This is HOC to wrap a component with error boundaries
 * @param Component
 * @returns {WithErrorHandler}
 */
function withErrorHandler(Component) {
  class WithErrorHandler extends React.Component {
    constructor(props) {
      super(props);
      //* Construct the initial state
      this.state = {
        hasError: false,
        error: null,
        errorInfo: null,
      };
    }

    componentDidCatch(error, info) {
      //* Update state if error happens
      this.setState({ hasError: true, error, errorInfo: info });

      //* Report errors
      console.log(error, info, this.props);
    }

    render() {
      //* if state contains error we render fallback component
      if (this.state.hasError) {
        const { error, errorInfo } = this.state;
        return (
          <div>
            <h2>Something went wrong.</h2>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {error && error.toString()}
            </details>
          </div>
        );
      } else {
        return <Component {...this.props} />;
      }
    }
  }
  return WithErrorHandler;
}

export default withErrorHandler;
