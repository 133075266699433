import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

import AlertDialog from '../dialog/AlertDialog';
import { doctorProfileRoutes } from '../../../routing/routes/doctorRoutes';

import { AUTH_HOME, USER_MENU_ID } from '../../../utils/constants';
import { getRouteByLayoutAndPath } from '../../../utils/routing.utils';
import { removeLocalStorageItem } from '../../../utils/globals.utils.js';
import { useNavbarStyles } from './AppNavbar.style';
import { useSelector } from 'react-redux';
import { selectLoggedInUser } from '../../../store/globalSlice';
import { useState } from 'react';
import { doctorLogout } from '../../../services/auth.service';
import AppSpinner from '../../UI/spinner/AppSpinner';

const NavbarUserMenu = (props) => {
	const [loader, setLoader] = useState(false);
	const { t } = useTranslation();
	const history = useHistory();
	const classes = useNavbarStyles();
	const doctor = useSelector(selectLoggedInUser);

	const isMenuOpen = Boolean(props.anchorEl);

	const [open, setOpen] = React.useState(false);

	//user logged out
	const handleAccept = () => {
		setOpen(false);
		setLoader(true);
		doctorLogout()
		.then(() => {
			setLoader(false);
			removeLocalStorageItem('userId');
			removeLocalStorageItem('authToken');
			history.push(AUTH_HOME);
		})
		.catch(() => {
			setLoader(false);
			removeLocalStorageItem('userId');
			removeLocalStorageItem('authToken');
			history.push(AUTH_HOME);
		});
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleLogout = () => {
		props.closeMenuClicked();
		setOpen(true);
	};

	return (
		<React.Fragment>
			{loader && <AppSpinner />}
			<Popper
				open={isMenuOpen}
				anchorEl={props.anchorEl}
				role={undefined}
				transition
				disablePortal
				style={{
					zIndex: 99,
					marginTop: '9px',
				}}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={props.closeMenuClicked}>
								<MenuList id={USER_MENU_ID} className={classes.menuList}>
									{doctorProfileRoutes.filter(d => d.role.some(d => doctor?.roles?.includes(d))).map((route) => (
										<MenuItem
											key={route.name}
											component={Link}
											onClick={props.closeMenuClicked}
											to={getRouteByLayoutAndPath(route.layout, route.path)}
											className={classes.menuIem}
										>
											{t(route.name)}
										</MenuItem>
									))}
									<Divider className={classes.divider} variant="middle" />
									<MenuItem onClick={handleLogout} className={classes.menuIem}>
										{t('globals.logout')}
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>

			<AlertDialog
				isOpen={open}
				onClose={handleClose}
				onAccept={handleAccept}
				title="Logout"
				content="Are you sure you want to logout?"
				cancelText={t('globals.cancel')}
				acceptText={t('globals.accept')}
			/>
		</React.Fragment>
	);
};

export default NavbarUserMenu;
