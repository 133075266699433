import React, { Fragment } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { getLocalStorageItem } from './globals.utils';

/**
 * @param routes
 * @returns <Route> element which redirect to layout+path (for example: /dashboard/team-dashboard)
 */
export const getRouteElements = (routes) => {
	const authToken = getLocalStorageItem('authToken');

	return routes.map((prop, key) => (
		<Fragment>
			{authToken ? (
				<Route
					exact
					key={key}
					path={prop.layout + prop.path}
					component={prop.component}
				/>
			) : (
				<Redirect to="/auth/sign-in" key="redirect" />
			)}
		</Fragment>
	));
};

export const getAuthRouteElements = (routes) => {
	return routes.map((prop, key) => {
		return (
			<Route
				exact
				key={key}
				path={prop.layout + prop.path}
				component={prop.component}
			/>
		);
	});
};

export const getRouteByLayoutAndPath = (layout, path) => {
	return layout + path;
};
