import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
} from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import {
	checkChristmasModal,
	christmasModalStorage,
} from '../../utils/globals.utils';

export const ChristmasPopup = () => {
	const [maxWidth] = useState('md');

	const [open, setOpen] = useState(false);

	const handleClose = () => {
		christmasModalStorage();
		setOpen(false);
	};

	useEffect(() => {
		if (checkChristmasModal()) {
			setOpen(true);
		}
	}, []);

	return (
		<Fragment>
			<Dialog
				maxWidth={maxWidth}
				fullWidth={true}
				open={open}
				aria-labelledby="max-width-dialog-title"
				onBackdropClick="false"
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
			>
				<DialogTitle>Christmas and New Year Closure</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2} direction="column">
						<Grid container item spacing={2} wrap justifyContent="space-around">
							<Grid item>
								<DialogContentText>
									For your information, our branches will be closed between
									Christmas and the New Year.
								</DialogContentText>
								<DialogContentText>
									We will be closed from 24/12/2022 and will be open again on
									3/1/2023
								</DialogContentText>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => handleClose()}
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};
