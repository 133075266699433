import updatedInstance from '../axios/ctdentAxios';

import {
	CTDENT_APPOINTMENT_PRICE_BREAKDOWN,
	CTDENT_POST_ORDER_REPORT,
	DOCTOR_PATIENT_LIST,
	ORDER_REPORT_CALCULATE_PRICE,
} from '../assets/conf/api-endpoints';
import { apiConfig } from './common.service';

// * post order report
export async function postOrderReport(requestParam) {
	const reqConfig = apiConfig();
	const response = await updatedInstance.post(
		CTDENT_POST_ORDER_REPORT,
		requestParam,
		reqConfig,
	);
	return response;
}

/**
 * GET REQUEST: Patient list
 * @param {Object} requestParam
 * @returns {Object}
 */
export async function fetchPatientList(requestParam) {
	const reqConfig = apiConfig();
	const response = await updatedInstance.get(DOCTOR_PATIENT_LIST, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

/**
 * POST REQUEST: Calculate appointment price
 * @param {Object} requestParam
 * @returns {Object}
 */
export async function postCalculatePrice(requestParam) {
	const reqConfig = apiConfig();
	const response = await updatedInstance.post(
		CTDENT_APPOINTMENT_PRICE_BREAKDOWN,
		requestParam,
		reqConfig,
	);
	return response;
}
