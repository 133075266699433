import { alpha, makeStyles } from '@material-ui/core/styles';

export const useNavbarStyles = makeStyles(
	(theme) => ({
		grow: {
			flexGrow: 1,
		},
		searchDiv: {
			display: 'flex',
		},
		logo: {
			marginTop: '9px',
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: alpha(theme.palette.common.black, 0.15),
			'&:hover': {
				backgroundColor: alpha(theme.palette.common.black, 0.25),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit',
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '20ch',
			},
		},
		sectionDesktop: {
			display: 'none',
			minWidth: '315px',
			width: 'unset',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
			},
		},
		sectionMobile: {
			display: 'flex',
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
		},
		menuList: {
			background: '#333',
			color: '#fff',
		},
		menuIem: {
			'&:hover': {
				background: '#63676A',
			},
		},
		menuButton: {
			// marginRight: theme.spacing(1),
		},
		divider: {
			background: '#fff',
		},
		hide: {
			display: 'none',
		},
		appBar: {
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			backgroundColor: '#000',
		},
		appBarShift: {
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		subtilte: {
			color: '#FFFFFF',
			marginLeft: '10px',
		},
		version: {
			fontSize: 'small',
			display: 'inline-block',
			alignSelf: 'flex-end',
			marginLeft: '0.2em',
		},
	}),
	{ index: 1 },
);
