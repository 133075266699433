import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import goToAssist from '../../../assets/images/assist.png';
import teamViewer from '../../../assets/images/teamViewer.png';

const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(1),
		color: '#fff',
		textTransform: 'unset',
		borderColor: '#63676A',
		'&:hover': {
			borderColor: '#fff',
		},
	},
	btnImage: {
		background: '#fff',
		borderRadius: '50px',
	},
}));

const NavbarSupport = () => {
	const classes = useStyles();

	const handleRedirect = (type) => {
		if (type === 'assist') {
			window !== undefined &&
				window.open('https://www.fastsupport.com', '_blank');
		} else {
			window !== undefined &&
				window.open(
					'https://www.ct-dent.co.uk/downloads/files/TeamViewerQS_en.exe',
					'_blank',
				);
		}
	};

	return (
		<React.Fragment>
			<Button
				variant="outlined"
				className={classes.button}
				startIcon={<img src={goToAssist} alt="" />}
				onClick={() => handleRedirect('assist')}
			>
				GoToAssist
			</Button>
			<Button
				variant="outlined"
				className={classes.button}
				startIcon={<img src={teamViewer} className={classes.btnImage} alt="" />}
				onClick={handleRedirect}
			>
				Teamviewer
			</Button>
		</React.Fragment>
	);
};

export default NavbarSupport;
