import { makeStyles } from '@material-ui/core/styles';

export const useAuthStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
		background:
			'linear-gradient(160deg, rgba(0,0,0,1) 82%, rgba(7,41,77,1) 99%)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		color: '#fff',
	},
	aiImage: {
		width: '50%',
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		color: '#fff',
	},
	heading: {
		fontWeight: 400,
	},
	subHeading: {
		fontWeight: 600,
	},

	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '402px',
		padding: '20px',
		boxShadow: '0px 0px 16px rgba(99, 103, 106, 0.16)',
		borderRadius: '8px',
	},
	forgotPassPaper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '402px',
		padding: '20px',
		boxShadow: '0px 0px 16px rgba(99, 103, 106, 0.16)',
		borderRadius: '8px',
		[theme.breakpoints.up('sm')]: {
			width: '468px',
		},
	},
	// * Signup
	signupPaper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		boxShadow: '0px 0px 16px rgba(99, 103, 106, 0.16)',
		borderRadius: '8px',
		marginTop: '20px',
	},
	signupTitle: {
		fontFamily: 'Roboto',
		fontSize: '28px',
		fontStyle: 'normal',
		fontWeight: '300',
		lineHeight: '33px',
		letterSpacing: '0.01em',
		textAlign: 'left',
		marginTop: '25px',
		'& > span': {
			fontWeight: '400',
		},
	},
	sectionTitle: {
		fontFamily: 'Roboto',
		fontSize: '20px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '21px',
		letterSpacing: '0em',
		textAlign: 'left',
	},
	signupLabel: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '500',
		lineHeight: '21px',
		letterSpacing: '0em',
		textAlign: 'left',
		color: '#63676A',
		marginBottom: '2px',
	},

	patientInfo: {
		fontFamily: 'Roboto',
		fontSize: '18px',
		fontStyle: 'normal',
		fontWeight: '500',
		lineHeight: '19px',
		letterSpacing: '0.01em',
		textAlign: 'left',
		marginTop: '16px',
		marginDown: '16px',
		'& a': {
			color: '#C8DA2E',
		},
	},

	loginHeading: {
		fontFamily: 'Roboto',
		fontSize: '28px',
		fontStyle: 'normal',
		fontWeight: '300',
		lineHeight: '33px',
		letterSpacing: '0.01em',
		'& > span': {
			fontWeight: '400',
		},
	},
	inputLabel: {
		fontSize: '15px',
		fontWeight: '500',
		lineHeight: '21px',
		letterSpacing: '0em',
	},
	forgotPassword: {
		fontSize: '12px',
		fontWeight: '500',
		lineHeight: '14px',
		letterSpacing: '0em',
		color: '#63676A',
	},
	signUp: {
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '21px',
		letterSpacing: '0em',
		color: '#3D7983',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
		'& .MuiTextField-root': {
			marginTop: '2px',
		},
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		minWidth: '200px',
	},
	mobileBackground: {
		background: 'linear-gradient(160deg, rgba(0,0,0,1) 82%, rgba(7,41,77,1) 99%)',
		boxShadow: 'unset',
		padding: '0px 15px',
	},
	mobileColorBackground: {
		background: '#000',
		boxShadow: 'unset',
		padding: '0px',
	},
	desktopBackground: {
		background: '#F4F4F4',
		boxShadow: 'unset',
		padding: '0px 15px',
	}
}));
