import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH } from '../../../utils/constants';

export const useStyles = makeStyles((theme) => ({
	drawer: {
		width: DRAWER_WIDTH,
		flexShrink: 0,
	},
	drawerPaper: {
		backgroundColor: '#1B1A1E',
		marginTop: '78px',
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	list: {
		marginTop: '10px',
	},
	listItem: {
		height: '60px',
	},
	listItemText: {
		fontSize: '16px',
		lineHeight: '21px',
		letterSpacing: '0em',
	},
	divider: {
		background: '#484848',
	},
	bottomPush: {
		position: "fixed",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
		width: DRAWER_WIDTH,
	},
	footerText: {
		color: "#fff",
		fontSize: '16px',
		lineHeight: '21px',
		letterSpacing: '0em',
	}
}));
