//when selecting an area of interest (mandible, maxilla etc) it disables teeth from selection
import {
	ALL_TEETH_ARRAY,
	LOWER_LEFT_JAW_TEETH,
	LOWER_RIGHT_JAW_TEETH,
	UPPER_LEFT_JAW_TEETH,
	UPPER_RIGHT_JAW_TEETH,
} from '../assets/conf/teeth.configuration';

export const getQuadrantTeeth = (id) => {
	if (LOWER_LEFT_JAW_TEETH.includes(id)) {
		return LOWER_LEFT_JAW_TEETH;
	} else if (LOWER_RIGHT_JAW_TEETH.includes(id)) {
		return LOWER_RIGHT_JAW_TEETH;
	} else if (UPPER_LEFT_JAW_TEETH.includes(id)) {
		return UPPER_LEFT_JAW_TEETH;
	} else if (UPPER_RIGHT_JAW_TEETH.includes(id)) {
		return UPPER_RIGHT_JAW_TEETH;
	}
}

/**
 *
 *  return list of availableTeeth for TeethSelection component, according to selected are of Interest
 */
export const getAvailableTeethByAreaOfInterest = (
	areaOfInterest,
	teethList,
) => {
	switch (areaOfInterest) {
		//when selecting a teeth with Quadrant area of interest - only teeth from the specific quadrant can be chosen
		case 'sectional':
			if (LOWER_LEFT_JAW_TEETH.includes(teethList[0]) || LOWER_RIGHT_JAW_TEETH.includes(teethList[0])) {
				return [...LOWER_LEFT_JAW_TEETH, ...LOWER_RIGHT_JAW_TEETH];
			} else if (UPPER_LEFT_JAW_TEETH.includes(teethList[0]) || UPPER_RIGHT_JAW_TEETH.includes(teethList[0])) {
				return [...UPPER_LEFT_JAW_TEETH, ...UPPER_RIGHT_JAW_TEETH];
			} else {
				return ALL_TEETH_ARRAY;
			}

		case 'quadrant':
			if (LOWER_LEFT_JAW_TEETH.includes(teethList[0])) {
				return LOWER_LEFT_JAW_TEETH;
			} else if (LOWER_RIGHT_JAW_TEETH.includes(teethList[0])) {
				return LOWER_RIGHT_JAW_TEETH;
			} else if (UPPER_LEFT_JAW_TEETH.includes(teethList[0])) {
				return UPPER_LEFT_JAW_TEETH;
			} else if (UPPER_RIGHT_JAW_TEETH.includes(teethList[0])) {
				return UPPER_RIGHT_JAW_TEETH;
			} else {
				return ALL_TEETH_ARRAY;
			}
		// return getAvailableTeethForSectionalArea(teethList[0]);
		case 'mandible':
			return [...LOWER_LEFT_JAW_TEETH, ...LOWER_RIGHT_JAW_TEETH];
		case 'maxilla':
			return [...UPPER_LEFT_JAW_TEETH, ...UPPER_RIGHT_JAW_TEETH];
		case 'both_jaws':
			return ALL_TEETH_ARRAY;
		case 'ortho':
			// no option to change teeth
			return ALL_TEETH_ARRAY;
		default:
			return [];
	}
};

/**
 *
 * @param areaOfInterest
 */
export const isCheckboxes = (areaOfInterest) => {
	switch (areaOfInterest) {
		case 'Quadrant':
			return false;
		case 'Sectional':
			return false;
		case 'Mandible':
			return false;
		case 'Maxilla':
			return false;
		case 'Both Jaws':
			return false;
		case 'Ortho':
			return false;
		default:
			return false;
	}
};

/**
 *  When selecting a teeth with Sectional area of interest -
 *  Only teeth from the specific section(upper jaw/lower jaw) can be chosen
 *  Furthermore - only 2 teeth from each side can be chosen
 */
function getAvailableTeethForSectionalArea(teeth) {
	// console.log((parseInt(teeth) + 1).toString());
	return ALL_TEETH_ARRAY;
	if (!teeth) {
		return ALL_TEETH_ARRAY;
	}
	switch (teeth) {
		case '11':
			return ['11', '12', '13', '21', '22'];
		case '12':
			return ['12', '13', '14', '11', '21'];
		case '17':
			return ['17', '15', '16', '18'];
		case '18':
			return ['18', '16', '17'];
		case '21':
			return ['21', '11', '12', '22', '23'];
		case '22':
			return ['22', '23', '24', '21', '11'];
		case '27':
			return ['27', '28', '26', '25'];
		case '28':
			return ['28', '27', '26'];
		case '41':
			return ['41', '42', '43', '31', '32'];
		case '42':
			return ['42', '43', '44', '41', '31'];
		case '47':
			return ['47', '45', '46', '48'];
		case '48':
			return ['48', '46', '47'];
		case '31':
			return ['31', '41', '42', '32', '33'];
		case '32':
			return ['32', '33', '34', '31', '41'];
		case '37':
			return ['37', '38', '36', '35'];
		case '38':
			return ['38', '37', '36'];

		default:
			//2 teeth from each side
			return [
				(parseInt(teeth) + 2).toString(),
				(parseInt(teeth) + 1).toString(),
				parseInt(teeth).toString(),
				(parseInt(teeth) - 1).toString(),
				(parseInt(teeth) - 2).toString(),
			];
	}
}

export const getFrameByAreaOfInterest = (areaOfInterest, teethList) => {
	switch (areaOfInterest) {
		case 'quadrant':
			if (LOWER_LEFT_JAW_TEETH.includes(teethList[0])) {
				return 'https://sketchfab.com/models/58b2a42e82344b10901ae0c6c655f142/embed?autostart=1';
			} else if (LOWER_RIGHT_JAW_TEETH.includes(teethList[0])) {
				return 'https://sketchfab.com/models/a60f637b3fe3428ea21a5beeba44e875/embed?autostart=1';
			} else if (UPPER_LEFT_JAW_TEETH.includes(teethList[0])) {
				return 'https://sketchfab.com/models/b95753359544480293ee4ae224da6991/embed?autostart=1';
			} else if (UPPER_RIGHT_JAW_TEETH.includes(teethList[0])) {
				return 'https://sketchfab.com/models/c18945b63e5c4bb5b6b787a0cd56c95e/embed?autostart=1';
			} else {
				return '';
			}
		//According to different sections
		case 'sectional':
			if (['16', '17', '18'].includes(teethList[0])) {
				return 'https://sketchfab.com/models/9667eda8719b4fd3b901a80d30e33625/embed?autostart=1';
			} else if (['13', '14', '15'].includes(teethList[0])) {
				return 'https://sketchfab.com/models/e3fecc7d77a2475aa8b920a801c0264b/embed?autostart=1';
			} else if (['11', '12', '21', '22'].includes(teethList[0])) {
				return 'https://sketchfab.com/models/2ef19d2a14654dc88679f921e5df9657/embed?autostart=1';
			} else if (['23', '24', '25'].includes(teethList[0])) {
				return 'https://sketchfab.com/models/0092a12f04b049609e09a09eb8a1b590/embed?autostart=1';
			} else if (['26', '27', '28'].includes(teethList[0])) {
				return 'https://sketchfab.com/models/805e76a445634687b7f5276a12247fcb/embed?autostart=1';
			} else if (['46', '47', '48'].includes(teethList[0])) {
				return 'https://sketchfab.com/models/49ef75e6b4a743dfa4f9e5e6b0977f87/embed?autostart=1';
			} else if (['43', '44', '45'].includes(teethList[0])) {
				return 'https://sketchfab.com/models/2bb85398d83f4bc88a38e8144775f9b4/embed?autostart=1';
			} else if (['31', '32', '41', '42'].includes(teethList[0])) {
				return 'https://sketchfab.com/models/3c54c09e6fbc4db6a445294adb5499f8/embed?autostart=1';
			} else if (['33', '34', '35'].includes(teethList[0])) {
				return 'https://sketchfab.com/models/0092a12f04b049609e09a09eb8a1b590/embed?autostart=1';
			} else if (['36', '37', '38'].includes(teethList[0])) {
				return 'https://sketchfab.com/models/35c1ec93979a42068bf2f90e6629c539/embed?autostart=1';
			} else {
				return '';
			}
		case 'mandible':
			return 'https://sketchfab.com/models/ce8a904d788d41969352b64ea85757d4/embed?autostart=1';
		case 'maxilla':
			return 'https://sketchfab.com/models/6acc16e7bfba4bc8aee242cabe14a9fe/embed?autostart=1';
		case 'both_jaws':
			return 'https://sketchfab.com/models/990df4995e89463985d877b0f67792be/embed?autostart=1';
		case 'ortho':
			return 'https://sketchfab.com/models/f75db968212e44c8be5fba2b6ac5abb1/embed?autostart=1';
		default:
			return '';
	}
};
