import React from 'react';

import FeedbackIcon from '@material-ui/icons/Feedback';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import calendarIcon from '../../assets/images/sidemenu/calendarIcon.svg';
import downloadIcon from '../../assets/images/sidemenu/downloadIcon.svg';
import invoiceIcon from '../../assets/images/sidemenu/invoiceIcon.svg';
import myReferralIcon from '../../assets/images/sidemenu/myReferralIcon.svg';
import orderReportIcon from '../../assets/images/sidemenu/orderReportIcon.svg';
import referPatientIcon from '../../assets/images/sidemenu/referPatientIcon.svg';
import { PARTNER_ROLE } from '../../utils/constants';

// * Lazy loading of components

// * Referral/Appointment components
const LazyMyReferredPatients = React.lazy(() =>
	import('../../containers/doctors/MyReferredPatients'),
);
const LazyReferPatientBranchList = React.lazy(() =>
	import('../../containers/doctors/refer-patient/BranchList'),
);
const LazyReferPatient = React.lazy(() =>
	import('../../containers/doctors/refer-patient/ReferPatient'),
);
const LazyReferSuccess = React.lazy(() =>
	import('../../containers/doctors/refer-patient/Success'),
);

// * Invoice components
const LazyInvoices = React.lazy(() =>
	import('../../containers/doctors/invoices'),
);
const LazyInvoicesOld = React.lazy(() =>
	import('../../containers/doctors/invoices/InvoicesOld'),
);
const LazyInvoiceDetail = React.lazy(() =>
	import('../../containers/doctors/invoices/InvoiceDetail'),
);

// * Order report components
const LazyOrderReport = React.lazy(() =>
	import('../../containers/doctors/order-report/OrderReport'),
);
const LazyOrderSuccess = React.lazy(() =>
	import('../../containers/doctors/order-report/Success'),
);

// * Sidebar components
const LazyDownloadReferral = React.lazy(() =>
	import('../../containers/doctors/download-referal/DownloadReferral'),
);
const LazyDoctorsCalendar = React.lazy(() =>
	import('../../containers/doctors/calendar/DoctorsCalendar'),
);

// * Profile menu components
const LazyDoctorProfile = React.lazy(() =>
	import('../../containers/doctors/profile/DoctorProfile'),
);
const LazyPriceList = React.lazy(() =>
	import('../../containers/doctors/resources/PriceList'),
);
const LazyMyPayment = React.lazy(() =>
	import('../../containers/doctors/MyPayment'),
);
const LazyAgreementsAndPolicies = React.lazy(() =>
	import('../../containers/doctors/resources/AgreementsAndPolicies'),
);
const LazyJoinAccounts = React.lazy(() =>
	import('../../containers/doctors/profile/JoinAccounts'),
);

// * Help menu components
const LazyCBCTInterpretation = React.lazy(() =>
	import('../../containers/doctors/resources/CBCTInterpretation'),
);

// * Other components
// const LazyPatientFeedback = React.lazy(() =>
// 	import('../../containers/doctors/patient-feedback/PatientFeedbacks'),
// );
const LazySubDownloadReferral = React.lazy(() =>
	import('../../containers/doctors/download-referal/SubDownloadReferral'),
);
const LazyReferPatientSubBranchList = React.lazy(() =>
	import('../../containers/doctors/refer-patient/SubBranchList.jsx'),
);
const LazyMasterPricelist = React.lazy(() =>
	import('../../containers/root-admin/master-pricelist'),
);
const LazyDoctorPricelistDiscounts = React.lazy(() =>
	import('../../containers/root-admin/doctor-pricelist-discounts'),
);
const LazyDoctorPricelistDiscountsNew = React.lazy(() =>
	import('../../containers/root-admin/doctor-pricelist-discounts/doctors-pricelist-discounts')
);
const LazyMasterAdminTracingInfo = React.lazy(() =>
	import('../../containers/master-admin/tracing/tracing-info')
);
const LazyDoctorInvisible = React.lazy(() =>
	import('../../containers/root-admin/invisible-shares/invisible-shares')
);
const LazyDoctorReport = React.lazy(() =>
	import('../../containers/root-admin/reports/reports')
);
const LazyDoctorSalesRepRequestsApprovals = React.lazy(() =>
	import('../../containers/root-admin/sales-rep-requests-approvals/sales-rep-requests-approvals')
);

// * routes names are equivalent to i18n translate file names, and are translated within the React components
export const doctorMenuRoutes = [
	{
		path: '/my-patients',
		layout: '/dashboard',
		name: 'routes.doctors.myReferredPatients',
		icon: myReferralIcon,
		role: ['doctor', 'root', 'master-admin', PARTNER_ROLE],
		component: LazyMyReferredPatients,
	},
	{
		path: '/refer-patient-branch-list',
		layout: '/dashboard',
		name: 'routes.doctors.referPatient',
		icon: referPatientIcon,
		role: ['doctor', 'root', 'master-admin'],
		component: LazyReferPatientBranchList,
	},
	{
		path: '/download-a-referral',
		layout: '/dashboard',
		name: 'routes.doctors.downloadReferral',
		icon: downloadIcon,
		role: ['doctor', 'root', 'master-admin'],
		component: LazyDownloadReferral,
	},
	{
		path: '/calendar-overview',
		layout: '/dashboard',
		name: 'routes.doctors.calendar',
		icon: calendarIcon,
		role: ['doctor', 'root', 'master-admin'],
		component: LazyDoctorsCalendar,
	},
];

export const partnerMenuRoutes = [
	{
		path: '/my-patients',
		layout: '/dashboard',
		name: 'routes.doctors.myReferredPatients',
		icon: myReferralIcon,
		role: ['doctor', 'root', 'master-admin'],
		component: LazyMyReferredPatients,
	},
];

export const doctorSecondaryRoutes = [
	{
		path: '/order-report',
		layout: '/dashboard',
		name: 'routes.doctors.orderReport',
		icon: orderReportIcon,
		role: ['doctor', 'root', 'master-admin', PARTNER_ROLE],
		component: LazyOrderReport,
	},
	{
		path: '/invoices',
		layout: '/dashboard',
		name: 'routes.doctors.invoices',
		icon: invoiceIcon,
		role: ['doctor', 'root', 'master-admin'],
		component: LazyInvoices,
	},
];

export const doctorResourceRoutes = [
	{
		path: '/cbct-pathology',
		layout: '/dashboard',
		name: 'routes.doctors.cbct',
		role: ['doctor', 'root', 'master-admin'],
		component: LazyCBCTInterpretation,
	},
];

export const doctorProfileRoutes = [
	{
		path: '/doctor-profile',
		layout: '/dashboard',
		name: 'routes.doctors.myProfile',
		icon: MonetizationOnIcon,
		role: ['doctor', 'root', 'master-admin', PARTNER_ROLE],
		component: LazyDoctorProfile,
	},
	{
		path: '/price-list',
		layout: '/dashboard',
		name: 'routes.doctors.priceList',
		icon: MonetizationOnIcon,
		role: ['doctor', 'root', 'master-admin'],
		component: LazyPriceList,
	},
	{
		path: '/my-payment',
		layout: '/dashboard',
		name: 'routes.doctors.myPayment',
		role: ['doctor', 'root', 'master-admin'],
		component: LazyMyPayment,
	},
	{
		path: '/agreements-and-policies',
		layout: '/dashboard',
		name: 'routes.doctors.agreementsAndPolicies',
		icon: FeedbackIcon,
		role: ['doctor', 'root', 'master-admin', PARTNER_ROLE],
		component: LazyAgreementsAndPolicies,
	},
	{
		path: '/join-accounts',
		layout: '/dashboard',
		name: 'routes.doctors.joinAccounts',
		icon: FeedbackIcon,
		role: ['doctor', 'root', 'master-admin'],
		component: LazyJoinAccounts,
	},
];

export const doctorOtherRoutes = [
	{
		path: '/refer-patient',
		layout: '/dashboard',
		name: 'routes.doctors.referPatient',
		role: ['doctor', 'root', 'master-admin', PARTNER_ROLE],
		component: LazyReferPatient,
	},
	{
		path: '/download-a-referral/london',
		layout: '/dashboard',
		name: 'routes.doctors.downloadReferral',
		role: ['doctor', 'root', 'master-admin'],
		component: LazySubDownloadReferral,
	},
	{
		path: '/refer-patient-branch-list/london',
		layout: '/dashboard',
		name: 'routes.doctors.referPatient',
		role: ['doctor', 'root', 'master-admin'],
		component: LazyReferPatientSubBranchList,
	},
	{
		path: '/refer-success',
		layout: '/dashboard',
		name: 'routes.doctors.referSuccess',
		role: ['doctor', 'root', 'master-admin'],
		component: LazyReferSuccess,
	},
	{
		path: '/order-success',
		layout: '/dashboard',
		name: 'routes.doctors.orderSuccess',
		role: ['doctor', 'root', 'master-admin'],
		component: LazyOrderSuccess,
	},
	{
		path: '/invoices-old',
		layout: '/dashboard',
		name: 'routes.doctors.invoices',
		role: ['doctor', 'root', 'master-admin'],
		component: LazyInvoicesOld,
	},
	{
		path: '/invoices-details/:id',
		layout: '/dashboard',
		name: '',
		role: ['doctor', 'root', 'master-admin'],
		component: LazyInvoiceDetail,
	},
];

export const rootMenuRoutes = [
	/*{
		path: '/translations',
		layout: '/root',
		name: 'routes.root.translations',
		role: ['root'],
		icon: orderReportIcon,
		component: LazyTranslationsList,
	},*/
	{
		path: '/master-pricelist',
		layout: '/root',
		name: 'routes.root.masterPriceList',
		role: ['root'],
		icon: orderReportIcon,
		component: LazyMasterPricelist,
	},
	// {
	// 	path: '/doctor-pricelist-discounts',
	// 	layout: '/root',
	// 	name: 'routes.root.doctorPriceListDiscounts',
	// 	role: ['root'],
	// 	icon: orderReportIcon,
	// 	component: LazyDoctorPricelistDiscounts,
	// },
	{
		path: '/doctor-pricelist-discounts-new',
		layout: '/root',
		name: 'routes.root.doctorPriceListDiscountsNew',
		role: ['root'],
		icon: orderReportIcon,
		component: LazyDoctorPricelistDiscountsNew,
	},
	{
		path: '/operations',
		layout: '/root',
		name: 'routes.root.operations',
		role: ['root'],
		icon: orderReportIcon,
		component: LazyDoctorInvisible,
	},
	{
		path: '/sales-rep-requests-approvals',
		layout: '/root',
		name: 'routes.root.salesRepRequestsApprovals',
		role: ['root'],
		icon: orderReportIcon,
		component: LazyDoctorSalesRepRequestsApprovals,
	},
	{
		path: '/reports',
		layout: '/root',
		name: 'routes.root.reports',
		role: ['root'],
		icon: orderReportIcon,
		component: LazyDoctorReport,
	},
];

export const masterAdminMenuRoutes = [
	{
		path: '/tracing-discount-injections',
		layout: '/master-admin',
		name: 'routes.masterAdmin.tracingInfo',
		role: ['master-admin'],
		icon: orderReportIcon,
		component: LazyMasterAdminTracingInfo,
	},
];

export const doctorRoutes = doctorMenuRoutes
	.concat(doctorResourceRoutes)
	.concat(doctorSecondaryRoutes)
	.concat(doctorProfileRoutes)
	.concat(doctorOtherRoutes)
	.concat(rootMenuRoutes)
	.concat(masterAdminMenuRoutes);

export const rootRoutes = rootMenuRoutes;
