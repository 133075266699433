import { createSlice } from '@reduxjs/toolkit';

export const globalSlice = createSlice({
	name: 'global',
	initialState: {
		role: 'doctor',
		locale: 'en',
		branch: {
			id: 1,
			value: 'london',
			name: 'London',
			address: 'Mock London Address',
			dayStart: '08:00:00',
			dayEnd: '20:00:00',
			slotDuration: '00:05:00',
			hiddenDays: [0],
		},
		loggedInUser: null,
		activeAccount: null,
	},
	reducers: {
		setRole: (state, action) => {
			state.role = action.payload.role;
		},
		setBranch: (state, action) => {
			state.branch = action.payload.branch;
		},
		setLocale: (state, action) => {
			state.locale = action.payload.locale;
		},
		setLoggedInUser: (state, action) => {
			state.loggedInUser = action.payload;
		},
		setActiveAccount: (state, action) => {
			state.activeAccount = action.payload;
		},
	},
});

export const {
	setRole,
	setBranch,
	setLocale,
	setLoggedInUser,
	setActiveAccount,
} = globalSlice.actions;

export const selectRole = (state) => state.global.role;
export const selectBranch = (state) => state.global.branch;
export const selectLocale = (state) => state.global.locale;
export const selectLoggedInUser = (state) => state.global.loggedInUser;
export const selectActiveAccount = (state) => state.global.activeAccount;

export default globalSlice.reducer;
