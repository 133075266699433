export const titles = [
	{
		value: 'Mr',
		name: 'containers.doctors.registration.titles.mr',
	},
	{
		value: 'Ms',
		name: 'containers.doctors.registration.titles.ms',
	},
	{
		value: 'Mrs',
		name: 'containers.doctors.registration.titles.mrs',
	},
	{
		value: 'Miss',
		name: 'containers.doctors.registration.titles.miss',
	},
	{
		value: 'Dr',
		name: 'containers.doctors.registration.titles.dr',
	},
	{
		value: 'Prof',
		name: 'containers.doctors.registration.titles.prof',
	},
	{
		value: 'Lady',
		name: 'containers.doctors.registration.titles.lady',
	},
	{
		value: 'Lord',
		name: 'containers.doctors.registration.titles.lord',
	},
];

export const genders = [
	{
		value: 'male',
		label: 'globals.male',
	},
	{
		value: 'female',
		label: 'globals.female',
	},
];

export const yesNo = [
	{
		value: false,
		label: 'globals.no',
	},
	{
		value: true,
		label: 'globals.yes',
	},
];

export const scannerPreference = [
	{
		value: 1,
		label: 'forms.patientReferral.moritaAccuitomo',
		tooltip: '',
	},
	{
		value: 0,
		label: 'forms.patientReferral.kavoOP3D',
		tooltip: '',
	},
];

export const preferredPayment = [
	{
		value: 'doctor',
		label: 'Doctor Pays',
	},
	{
		value: 'patient',
		label: 'Patient Pays',
	},
];

export const intraoralAreaOfInterest = [
	{
		value: 'mandible',
		label: 'dental.mandible',
	},
	{
		value: 'maxilla',
		label: 'dental.maxilla',
	},
	{
		value: 'both_jaws',
		label: 'dental.bothJaws',
	},
];

export const bookingOptions = [
	{
		value: 'us',
		label: 'forms.createAppointment.usToArrange',
	},
	{
		value: 'patient',
		label: 'forms.createAppointment.patientWillArrange',
	},
];

export const cbctAreaOfInterest = [
	{
		value: 'sectional',
		label: 'dental.sectional',
	},
	{
		value: 'quadrant',
		label: 'dental.quadrant',
	},
	{
		value: 'mandible',
		label: 'dental.mandible',
	},
	{
		value: 'maxilla',
		label: 'dental.maxilla',
	},
	{
		value: 'bothJaws',
		label: 'dental.bothJaws',
	},
	{
		value: 'bothJawsOrtho',
		label: 'dental.bothJawsOrtho',
	},
];

export const cbctAreaOfInterest_ = [
	{
		value: 'sectional',
		label: 'dental.sectional',
	},
	{
		value: 'quadrant',
		label: 'dental.quadrant',
	},
	{
		value: 'mandible',
		label: 'dental.mandible',
	},
	{
		value: 'maxilla',
		label: 'dental.maxilla',
	},
	{
		value: 'both_jaws',
		label: 'dental.bothJaws',
	},
	{
		value: 'ortho',
		label: 'dental.bothJawsOrtho',
	},
];

export const scanner = [
	{
		value: 'Separate scans for upper jaw and lower jaw (DP700 scanner)',
		label: 'scanner.separateScansForUpperJawAndLowerJaw',
	},
	{
		value: 'Both jaws scans (MoritaF170 scanner)',
		label: 'scanner.bothJawsScans',
	},
]

//TODO - from server
export const intraoralScanningOptions = [
	{
		value: 'digital_impression',
		label: 'dental.intraoral.digitalImpression',
	},
	{
		value: 'orthodontic_aligners',
		label: 'dental.intraoral.orthodonticsAligners',
	},
	// {
	// 	value: 'radiographic_template',
	// 	label: 'dental.intraoral.radiographicTemplate',
	// },
];

export const radiographicTemplateOption = [
	{
		value: 'denture_marked',
		label: 'Denture already marked',
	},
	{
		value: 'denture_to_be_marked_by_ct_dent',
		label: 'Denture to be marked by CT Dent',
	},
	{
		value: 'separate_template',
		label: 'Separate Stent',
	},
];

// * Options for signup form starts
export const hearFrom = [
	{ value: 'Sales Rep', label: 'Sales Rep' },
	{ value: 'Colleague', label: 'Colleague' },
	{ value: 'Conference', label: 'Conference' },
	{ value: 'Magazine', label: 'Magazine' },
	{ value: 'Web search', label: 'Web search' },
	{ value: 'Social media', label: 'Social media' },
	{ value: 'Other', label: 'Other' },
];

export const pricelistDiscountArea = [
	{ value: 'all', label: 'All'},
	{ value: 'by_area', label: 'By Area'}
];
export const areaOfInterest = [
	{
		id: 1,
		value: 1,
		label: 'Sectional',
	},
	{
		id: 7,
		value: 7,
		label: 'Quadrant',
	},
	{
		id: 3,
		value: 3,
		label: 'One Jaw',
	},
	// {
	// 	id: 3,
	// 	value: 3,
	// 	label: 'Maxilla',
	// },
	{
		id: 5,
		value: 5,
		label: 'Both Jaws',
	},
	{
		id: 6,
		value: 6,
		label: 'Both Jaws (Ortho)',
	},
];
// * Options for signup form ends

export const paymentOptions = [
	{
		value: 'doctor',
		label: 'Doctor',
	},
	{
		value: 'patient',
		label: 'Patient',
	},
]