import React, { Fragment } from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
	label: {
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '500',
		lineHeight: '21px',
		letterSpacing: '0em',
		color: '#63676A',
	},
	input: {
		marginTop: '5px',
		marginBottom: '5px',
	},
}));

const TextInput = ({
	onChange,
	label,
	placeHolder = '',
	name = '',
	value = '',
	variant = 'outlined',
	type = 'text',
	size = 'small',
	margin = 'normal',
	className = '',
	helperText = '',
	minRows = 4,
	multiline = false,
	required = false,
	error = false,
	disabled = false,
	readOnly = false,
	shrink = true,
	style = null,
	color = 'primary',
	fullWidth = true,
	...props
}) => {
	const classes = useStyle();

	return (
		<Fragment>
			{ label &&
				<Typography className={classes.label}>
					{label} {required && '*'}
				</Typography>
			}
			<TextField
				className={`${classes.input} ${className}`}
				type={type}
				placeholder={placeHolder}
				name={name}
				value={value}
				onChange={onChange}
				variant={variant}
				size={size}
				margin={margin}
				required={required}
				error={error}
				disabled={disabled}
				minRows={minRows}
				multiline={multiline}
				readOnly={readOnly}
				helperText={helperText}
				fullWidth={fullWidth}
				style={style}
				color={color}
				InputLabelProps={{
					shrink: shrink,
				}}
				{...props}
			/>
		</Fragment>
	);
};

export default TextInput;
