//TODO: get from conf. management
//hiddenDays: [0] means hide Sundays
import london from '../images/branch/London.jpeg';
import colchester from '../images/branch/colchester.jpg';
import manchester from '../images/branch/manchester.jpg';
import birmingham from '../images/branch/Birmingham.jpg';
import nottingham from '../images/branch/Nottingham.png';
import bristol from '../images/branch/Bristol.jpg';
import leeds from '../images/branch/Leeds.png';
import finchley from '../images/branch/Finchley.png';
import newMalden from '../images/branch/NewMalden.png';
import hornchurch from '../images/branch/Hornchurch.png';
import henley from '../images/branch/Henley.jpg';
// import hongKong from "../images/branch/HongKong.jpg";

export const branches = [
	{
		id: 1,
		value: 'london',
		image: london,
		name: 'London',
		address: 'Mock London Address',
		dayStart: '08:00:00',
		dayEnd: '20:00:00',
		slotDuration: '00:05:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 19,
		value: 'manchester',
		image: manchester,
		name: 'Manchester',
		address: 'Mock Manchester Address',
		dayStart: '09:00:00',
		dayEnd: '17:00:00',
		slotDuration: '00:15:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 20,
		value: 'birmingham',
		image: birmingham,
		name: 'Birmingham',
		address: 'Mock Birmingham Address',
		dayStart: '09:00:00',
		dayEnd: '17:00:00',
		slotDuration: '00:15:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 30,
		value: 'leeds',
		image: leeds,
		name: 'Leeds',
		address: 'Mock Leeds Address',
		dayStart: '09:00:00',
		dayEnd: '17:00:00',
		slotDuration: '00:15:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 31,
		value: 'bristol',
		image: bristol,
		name: 'Bristol',
		address: 'Mock Bristol Address',
		dayStart: '09:00:00',
		dayEnd: '17:00:00',
		slotDuration: '00:15:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 32,
		value: 'nottingham',
		image: nottingham,
		name: 'Nottingham',
		address: 'Mock Nottingham Address',
		dayStart: '09:00:00',
		dayEnd: '17:00:00',
		slotDuration: '00:15:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 35,
		value: 'colchester',
		image: colchester,
		name: 'Colchester',
		address: 'Mock Colchester Address',
		dayStart: '09:00:00',
		dayEnd: '17:00:00',
		slotDuration: '00:15:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 36,
		value: 'henley',
		image: henley,
		name: 'Henley',
		address: 'Henley',
		dayStart: '08:00:00',
		dayEnd: '20:00:00',
		slotDuration: '00:15:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 101,
		value: 'london',
		image: finchley,
		name: 'London A',
		address: 'Mock London Address',
		dayStart: '08:00:00',
		dayEnd: '20:00:00',
		slotDuration: '00:05:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 102,
		value: 'london',
		image: newMalden,
		name: 'London B',
		address: 'Mock London Address',
		dayStart: '08:00:00',
		dayEnd: '20:00:00',
		slotDuration: '00:05:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 103,
		value: 'london',
		image: hornchurch,
		name: 'London N',
		address: 'Mock London Address',
		dayStart: '08:00:00',
		dayEnd: '20:00:00',
		slotDuration: '00:05:00',
		hiddenDays: [0],
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
];

export const subBranches = [
	{
		id: 1,
		value: 'london',
		image: london,
		name: 'London',
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 2,
		value: 'london',
		image: london,
		name: 'London 102',
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 3,
		value: 'london',
		image: london,
		name: 'London 103',
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
	{
		id: 3,
		value: 'london',
		image: london,
		name: 'London 104',
		referralLink: 'https://ct-dent-co-uk-test.s3.eu-west-2.amazonaws.com/referral/Referral-Form.pdf',
	},
];

export const locales = [
	{
		name: 'en',
		value: 'en',
	},
	{
		name: 'it',
		value: 'it',
	},
];

export const PENDING = 'pending';
export const IDLE = 'idle';
