import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { isValidArray } from '../../../utils/globals.utils';

const useStyle = makeStyles((theme) => ({
	label: {
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '500',
		lineHeight: '21px',
		letterSpacing: '0em',
		color: '#63676A',
	},
	input: {
		marginTop: '5px',
		marginBottom: '5px',
	},
}));

const SelectBox = ({
	onChange,
	onBlur,
	fullWidth = true,
	label = '',
	placeHolder = 'Select',
	name = '',
	value = '',
	variant = 'outlined',
	size = 'small',
	margin = 'normal',
	className = '',
	formControlClassName = '',
	helperText = '',
	required = false,
	error = false,
	disabled = false,
	readOnly = false,
	style = null,
	labelStyle = null,
	color = 'primary',
	translatedOptions = false,
	options = [],
}) => {
	const { t } = useTranslation();
	const classes = useStyle();

	return (
		<Fragment>
			<Typography className={classes.label} style={labelStyle}>
				{label} {required && '*'}
			</Typography>
			<FormControl
				className={`${classes.input} ${formControlClassName}`}
				variant={variant}
				disabled={disabled}
				error={error}
				required={required}
				size={size}
				color={color}
				style={style}
				margin={margin}
				fullWidth={fullWidth}
			>
				<Select
					className={className}
					id={`select-helper-id-${name}`}
					name={name}
					value={value}
					onChange={onChange}
					error={error}
					onBlur={onBlur}
					inputProps={{ readOnly: readOnly }}
					displayEmpty={placeHolder ? true : false}
				>
					{placeHolder && <MenuItem value="">{placeHolder}</MenuItem>}
					{isValidArray(options) &&
						options.map((opt) => (
							<MenuItem
								key={opt?.value || opt?.id}
								value={opt?.value || opt?.id}
							>
								{translatedOptions ? (
									<> {t(opt.name || opt.label)}</>
								) : (
									<>{opt.name || opt.label}</>
								)}
							</MenuItem>
						))}
				</Select>
				{helperText && <FormHelperText>{helperText}</FormHelperText>}
			</FormControl>
		</Fragment>
	);
};

export default SelectBox;
