import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const AlertDialog = ({
	isOpen,
	onClose,
	onAccept,
	title = 'Title',
	content = '',
	cancelText = 'Cancel',
	acceptText = 'Accept',
	fullWidth = false,
	maxWidth = 'lg',
}) => {
	return (
		<Dialog
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			open={isOpen}
			onClose={onClose}
		>
			<DialogTitle>{title}</DialogTitle>

			{content && (
				<DialogContent>
					<DialogContentText>{content}</DialogContentText>
				</DialogContent>
			)}

			<DialogActions>
				<Button onClick={onClose} color="secondary" variant="text">
					{cancelText}
				</Button>
				<Button
					onClick={onAccept}
					color="primary"
					autoFocus
					variant="contained"
				>
					{acceptText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AlertDialog;
