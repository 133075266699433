import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';

import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';

import AppNavbar from '../../components/UI/navbar/AppNavbar';
import AppDrawer from '../../components/UI/drawer/AppDrawer';
import DashboardRouter from '../../routing/DashboardRouter';

import { RESPONSIVE_MOBILE_QUERY, TABLET_MOBILE_QUERY } from '../../utils/constants';
import withErrorHandler from '../../hoc/withErrorHandler';
import { useLayoutStyles } from './DashboardLayout.style';
import { useSelector } from 'react-redux';
import { selectLoggedInUser } from '../../store/globalSlice';

const DashboardLayout = (props) => {
	const classes = useLayoutStyles();
	const isTabletOrMobile = useMediaQuery(TABLET_MOBILE_QUERY);
	const isMobile = useMediaQuery(RESPONSIVE_MOBILE_QUERY);
	const user = useSelector(selectLoggedInUser);

	//Drawer is open as default on desktop, closed on mobile
	const [open, setOpen] = React.useState(isTabletOrMobile ? false : true);

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleMobileDrawerClose = () => {
		if(isMobile){
			setOpen(false);
		}
	};

	useEffect(() => {
		if (isTabletOrMobile) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	}, [isTabletOrMobile]);

	return (
		<React.Fragment>
			<AppNavbar
				open={open}
				openDrawerClicked={handleDrawerOpen}
				closeDrawerClicked={handleDrawerClose}
			/>
			<AppDrawer open={open} closeDrawerClicked={handleDrawerClose} />
			<main
				className={
					!isTabletOrMobile
						? clsx(classes.content, { [classes.contentShift]: open })
						: classes.content
				}
			>
				<Toolbar />
				<div className={classes.appBarSpacer} />
				<Container maxWidth={false}>
					<Box onClick={handleMobileDrawerClose}>
						<DashboardRouter user={user} userId={props.userId} />
					</Box>
				</Container>
				<div className={classes.appBarSpacer} />
			</main>
			{/* <AppFooter drawerOpen={open} /> */}
		</React.Fragment>
	);
};

export default withErrorHandler(DashboardLayout);
