import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { HELP_MENU_ID, USER_MENU_ID } from '../../../utils/constants';
import { getDoctorFullName } from '../../../utils/globals.utils';

import { useNavbarStyles } from './AppNavbar.style';

const NavbarSections = (props) => {
	const classes = useNavbarStyles();

	return (
		<React.Fragment>
			<Grid
				container
				direction="row"
				className={classes.sectionDesktop}
				alignItems="center"
				justifyContent="flex-end"
			>
				{props.loggedInUser && (
					<Grid item>
						<Grid
							container
							direction="row"
							alignItems="center"
							onClick={props.profileMenuClicked}
							style={{ cursor: 'pointer' }}
						>
							<Typography variant="subtitle1" className={classes.subtilte}>
								{getDoctorFullName(props.loggedInUser)}
							</Typography>
							<IconButton
								edge="end"
								aria-label="help"
								aria-controls={USER_MENU_ID}
								aria-haspopup="true"
								style={{ marginLeft: '-12px' }}
							>
								<ExpandMoreIcon htmlColor="#fff" fontSize="medium" />
							</IconButton>
						</Grid>
					</Grid>
				)}
				<Grid item>
					<Grid
						container
						direction="row"
						alignItems="center"
						onClick={props.helpMenuClicked}
						style={{ cursor: 'pointer' }}
					>
						<IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={HELP_MENU_ID}
							aria-haspopup="true"
						>
							<HelpOutlineIcon htmlColor="#fff" fontSize="medium" />
						</IconButton>
						<Typography variant="subtitle1" className={classes.subtilte}>
							Help
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<div className={'mobile-view-account-cls ' + classes.sectionMobile}>
				{props.loggedInUser && (
					<IconButton
						edge="end"
						aria-label="account of current user"
						aria-controls={HELP_MENU_ID}
						aria-haspopup="true"
						onClick={props.profileMenuClicked}
						color="inherit"
					>
						<AccountCircleIcon htmlColor="#fff" fontSize="large" />
					</IconButton>
				)}
				<IconButton
					edge="end"
					aria-label="account of current user"
					aria-controls={HELP_MENU_ID}
					aria-haspopup="true"
					onClick={props.helpMenuClicked}
					color="inherit"
				>
					<HelpOutlineIcon htmlColor="#fff" fontSize="large" />
				</IconButton>
			</div>
		</React.Fragment>
	);
};

export default NavbarSections;
