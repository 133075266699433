import React, { Fragment } from 'react';

import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

import MenuItems from './MenuItems';
import withErrorHandler from '../../../hoc/withErrorHandler';
import { resourceRoutes } from '../../../routing/routes/routes';
import {
	doctorMenuRoutes,
	rootRoutes,
} from '../../../routing/routes/doctorRoutes';

import { useStyles } from './AppDrawer.style';
import { DRAWER_WIDTH, PARTNER_ROLE } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectActiveAccount,
	selectLoggedInUser,
	setActiveAccount,
} from '../../../store/globalSlice';
import SelectBox from '../inputs/SelectBox';
import {
	capitalizeFirstLetter,
	isValidArray,
} from '../../../utils/globals.utils';
import { getConnectedAccounts } from '../../../store/calendarSlice';
import { Typography } from '@material-ui/core';

const AppDrawer = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const doctor = useSelector(selectLoggedInUser);
	const activeAccountId = useSelector(selectActiveAccount);
	const connectedAccounts = useSelector(getConnectedAccounts);

	const [accountOptions, setAccountOptions] = React.useState([]);

	const handleAccountChange = (e) => {
		dispatch(setActiveAccount(e?.target?.value));
	};

	React.useEffect(() => {
		const currentUser = [
			{
				label: `${capitalizeFirstLetter(doctor?.title)} ${doctor?.forename} ${
					doctor?.surname
				}`,
				value: doctor?.id,
			},
		];

		if (doctor && isValidArray(connectedAccounts)) {
			const connectedAccOptions = connectedAccounts.map((acc) => {
				return {
					label: acc?.connected_doctor_name,
					value: acc?.connected_doctor_id,
				};
			});
			setAccountOptions([...currentUser, ...connectedAccOptions]);
		} else if (doctor) {
			setAccountOptions(currentUser);
		}
	}, [doctor, connectedAccounts]);

	return (
		<Drawer
			className={'left_side_menu ' + classes.drawer}
			variant="persistent"
			anchor="left"
			open={props.open}
			classes={{
				paper: classes.drawerPaper,
			}}
			PaperProps={{ style: { width: DRAWER_WIDTH } }}
		>
			<Hidden lgUp>
				<Box padding={2}>
					<SelectBox
						style={{ background: '#fff', borderRadius: '5px' }}
						placeHolder=""
						options={accountOptions}
						value={isValidArray(accountOptions) ? activeAccountId : ''}
						onChange={handleAccountChange}
					/>
				</Box>
			</Hidden>
			<Divider className={classes.divider} />
			<MenuItems
				routes={doctorMenuRoutes
					.filter((d) => d.role.some((d) => doctor?.roles?.includes(d)))
					.map((d) => {
						if (
							d.path === '/my-patients'
						) {
							if(doctor?.roles?.includes(PARTNER_ROLE)){
								d.name = 'routes.doctors.myCases';
							}else{
								d.name = 'routes.doctors.myReferredPatients';
							}
						}
						return d;
					})}
				menuItemClicked={props.closeDrawerClicked}
			/>
			{(doctor?.roles?.includes('root') || doctor?.roles?.includes('doctor')) &&
				resourceRoutes.length > 0 && (
					<Fragment>
						<Divider className={classes.divider} />
						<MenuItems routes={resourceRoutes} />
					</Fragment>
				)}

			{doctor?.roles?.includes('root') && (
				<Fragment>
					<Divider className={classes.divider} />
					<MenuItems
						routes={rootRoutes}
						menuItemClicked={props.closeDrawerClicked}
					/>
				</Fragment>
			)}
			{doctor?.roles?.includes(PARTNER_ROLE) && (
				<Box component="div" className={classes.bottomPush}>
					<Typography align="center" className={classes.footerText}>
						Partner Account
					</Typography>
				</Box>
			)}
		</Drawer>
	);
};

export default withErrorHandler(AppDrawer);
