import { createSlice } from "@reduxjs/toolkit";

import { fetchInvoices } from "../services/doctors/invoice.service";
import { getResponseTime } from "../utils/globals.utils";

export const invoiceSlice = createSlice({
  name: "invoice",

  initialState: {
    error: {},
    invoicesLoading: false,
    invoicesData: null,
  },

  reducers: {
    // * Invoices
    invoicesRequestLoading(state, action) {
      if (!state.invoicesLoading) {
        state.invoicesLoading = true;
      }
    },

    invoicesRequestSucceed(state, action) {
      state.invoicesLoading = false;
      state.invoicesData = action.payload;
      getResponseTime(action?.payload?.data?.responseTime)
    },

    invoicesRequestFailed(state, action) {
      if (state.invoicesLoading) {
        state.invoicesLoading = false;
        state.error = action.payload;
      }
    },
  },
});

export const {
  invoicesRequestLoading,
  invoicesRequestSucceed,
  invoicesRequestFailed,
} = invoiceSlice.actions;

/**
 * REDUX THUNKS
 * Thunks dispatch action creators
 * */

// * Invoices
export const getInvoices = (requestParam) => async (dispatch) => {
  dispatch(invoicesRequestLoading());
  try {
    const response = await fetchInvoices(requestParam);
    dispatch(invoicesRequestSucceed(response));
  } catch (error) {
    dispatch(invoicesRequestFailed(error));
  }
};
export const isInvoicesLoading = (state) => state.invoice.invoicesLoading;
export const getInvoiceResponse = (state) =>
  state?.invoice?.invoicesData?.data?.responseData || [];

export default invoiceSlice.reducer;
