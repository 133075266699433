import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {
	verifyEmail,
} from '../../../store/signup/signupSlice';

import { SUCCESSCODE } from '../../../utils/constants';
import authImage from '../../../assets/images/authImage.jpg';
import logo from '../../../assets/images/ctdent_logo.png';
import { useAuthStyles } from '../auth.style';
import AppSpinner from '../../../components/UI/spinner/AppSpinner';
import { toastError, toastSuccess } from '../../../utils/globals.utils';
import { emailVerification } from '../../../services/auth.service';

const EmailVerification = () => {
	const classes = useAuthStyles();
	const [loader, setLoader] = useState(false);

	const [isVerified, setIsVerified] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const [responseMessage, setResponseMessage] = React.useState(
		'Uh oh! Something went wrong!',
	);

	const verifyEmailData = useSelector(verifyEmail);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const email = params.get('email');
		if (email) {
			setLoader(true);
			emailVerification({ email_1: email })
				.then((res) => {
					console.log( 'data', res );
					setLoader(false);
					if (res?.data?.responseCode !== 200) {
						setIsError(true);
						toastError(res?.data?.responseMessage || 'Something went wrong on our side during verify doctor.');
						return;
					}
					setResponseMessage(res?.data?.responseMessage);
					setIsVerified(true);
					toastSuccess(res?.data?.responseMessage || 'Doctor verified successfully.');
				})
				.catch((err) => {
					setLoader(false);
					const res = err?.response?.data;
					toastError(res?.responseMessage || 'Something went wrong! Please try again later');
					if(!res?.responseCode || res?.responseCode !== 2228) {
						setIsError(true);
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (verifyEmailData?.data?.responseCode === SUCCESSCODE) {
			setIsVerified(true);
		}
	}, [verifyEmailData]);

	return (
		<Fragment>
			{ loader && <AppSpinner /> }
			<Grid container style={{ height: '100vh' }}>
				<Grid item xs={false} md={6} className={classes.image}>
					<img src={logo} alt="" className={classes.logo} />
					<img src={authImage} alt="" className={classes.aiImage} />
					<Box className={classes.info}>
						<Typography variant="h2" className={classes.heading}>
							Wise Dentists
						</Typography>
						<Typography variant="h2" className={classes.subHeading}>
							Choose AI
						</Typography>
					</Box>
				</Grid>

				<Grid
					container
					item
					xs={12}
					lg={6}
					component={Paper}
					elevation={6}
					square
					alignItems="center"
					justifyContent="center"
					style={{ background: '#F4F4F4', boxShadow: 'unset' }}
				>
					<div className="success_msg_box">
						<div className="success_msg thankyou_box">
							<h1 style={{ textAlign: 'center' }}>
								<span>Thank you</span>
							</h1>
							<Box
								component="div"
								fullWidth
								display="flex"
								alignItems="center"
								flexDirection="column"
							>
								{isVerified && <p className="green-box">{responseMessage}</p>}
								{isError && <p className="red-box">{responseMessage}</p>}
							</Box>
							<Box
								component="div"
								fullWidth
								display="flex"
								justifyContent="center"
							>
								<Link className="back_btn" to="/auth/sign-in">
									Sign In
								</Link>
							</Box>
						</div>
					</div>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export default EmailVerification;
