import { makeStyles } from '@material-ui/core/styles';

export const useGridStyles = makeStyles((theme) => ({
	grid: {
		height: 475,
		width: '100%',
		marginTop: '0.5em',
		'& .theme-header': {
			backgroundColor: '#D0DF00',
		},
	},
	appointmentGrid: {
		height: 'calc(100vh - 270px)',
	},
}));

export const useFormStyles = makeStyles((theme) => ({
	field: {
		minWidth: '50%',
	},
	'@media (max-width: 1280px)': {
		field: {
			minWidth: '100%',
		},
	},
}));

export const useDialogFormStyles = makeStyles((theme) => ({
	field: {
		minWidth: '100%',
	},
}));

export const spinnerLoader = makeStyles((theme) => ({
	field: {
		display: 'block',
		transform: 'scale(2.5)',
	},
}));

export const backDropLoader = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

export const tableStyle = makeStyles((theme) => ({
	paper: {
		...theme.paper,
		padding: '20px',
	},
	noteText:{
		position: 'absolute',
		marginTop: '-52px',
	}
}));

export const productTourStyle = makeStyles((theme) => ({
	productTourSlide: {
		'& h1, & h2':{
			marginTop: '0px',
			marginBottom: '0px',
			textAlign: 'center',
		},
		'& .parent-image':{
			maxWidth: '1024px',
			width: '100%',
			height: '100%',
			maxHeight: '480px',
			minHeight: '480px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		'& img':{
			display: 'block',
			marginLeft: 'auto',
			marginRight: 'auto',
			maxWidth: '100%',
			objectFit: 'contain',
			maxHeight: '480px',
			border: '1px solid #c9da3a',
		},
		'& .parent, & .parent-content': {
			maxHeight: '150px',
			height: '100%',
			textAlign: 'center',
			display: 'block',
			minHeight: '150px',
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
		},
		'& .parent-content': {
			flexDirection: 'column',
		},
		'& .parent-content p:nth-child(n)': {
			marginTop: '5px',
			marginBottom: '0px'
		},
		'& ul': {
			display: 'inline-block',
			textAlign: 'left',
		},
		'& ul li':{
			fontWeight: '400',
    		fontSize: '16px',
		},
		'& .bold':{
			fontWeight: '700',
		},
		'& .green':{
			color: '#94cf5c',
		}
	},
	modal:{
		'& .MuiPaper-elevation1':{
			boxShadow: 'unset',
		},
		'& .MuiDialog-paperWidthLg':{
			maxHeight: '800px',
			height: '100%',
			width: '100%',
			'@media (min-width: 1024px)': {
				maxWidth: '1024px',
			},
		}
	},
	paperCarousel:{
		position: 'relative',
	},
	finishButton: {
		top: 'unset',
		right: '10px',
		margin: 'auto',
		display: 'flex',
		position: 'absolute',
		bottom: '-34px',
		'@media (max-width: 767px)': {
			bottom: '-104px',
		},
		'& button':{
			background: '#C8DA2E',
			height: '35px',
			borderRadius: 'unset',
		},
		'& button:hover':{
			background: '#C8DA2E',
			opacity: '0.6',
		}
	}
}));
