import instance from '../../axios/ctdentAxios';

import {
	CTDENT_GET_DOCTOR_DETAIL_BY_ID,
	CTDENT_GET_DOCTOR_GENERAL_DISCOUNT_LIST,
	CTDENT_GET_DOCTOR_SPECIAL_DISCOUNT_LIST,
	CTDENT_UPDATE_DOCTOR_GENERAL_DISCOUNT,
	CTDENT_UPDATE_DOCTOR_SPECIAL_DISCOUNT,
	CTDENT_DELETE_DOCTOR_SPECIAL_DISCOUNT,
	CTDENT_ADD_DOCTOR_SPECIAL_DISCOUNT,
	CTDENT_GET_PRICE_LIST,
	CTDENT_DOCTOR_MASTER_PRICE_LIST_API,
} from '../../assets/conf/api-endpoints';
import { apiConfig } from '../common.service';

// * fetch doctor details
export async function getDoctorDetails(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.get(CTDENT_GET_DOCTOR_DETAIL_BY_ID, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

// * fetch doctor general discounts
export async function getDoctorGeneralDiscounts(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.get(CTDENT_GET_DOCTOR_GENERAL_DISCOUNT_LIST, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

// * fetch doctor special discounts
export async function getDoctorSpecialDiscounts(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.get(CTDENT_GET_DOCTOR_SPECIAL_DISCOUNT_LIST, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}



// * Update doctor general discount
export async function updateGeneralDiscount(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_UPDATE_DOCTOR_GENERAL_DISCOUNT,
		requestParam,
		reqConfig,
	);
	return response;
}

// * Update doctor special discount
export async function updateSpecialDiscount(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_UPDATE_DOCTOR_SPECIAL_DISCOUNT,
		requestParam,
		reqConfig,
	);
	return response;
}


// * Delete doctor special discount
export async function deleteSpecialDiscount(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_DELETE_DOCTOR_SPECIAL_DISCOUNT,
		requestParam,
		reqConfig,
	);
	return response;
}

// * get all price list
export async function getPriceList(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.get(CTDENT_GET_PRICE_LIST, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

// * Add doctor special discount
export async function addSpecialDiscount(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_ADD_DOCTOR_SPECIAL_DISCOUNT,
		requestParam,
		reqConfig,
	);
	return response;
}

export const updateMasterPriceListDiscount = async requestParam =>{
	const reqConfig = apiConfig();
	const response = await instance.put(
		`${CTDENT_DOCTOR_MASTER_PRICE_LIST_API}/${requestParam.id}`,
		requestParam,
		reqConfig,
	);
	return response;
}