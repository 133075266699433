import React, { Suspense } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import { ThemeProvider } from '@material-ui/core/styles';

import DashboardLayout from './layouts/dashboard/DashboardLayout';
import AuthLayout from './layouts/auth/AuthLayout';
import EmailVerification from './containers/auth/sign-up/email-verification';
import { getUserId } from './utils/globals.utils.js';

import AppSpinner from './components/UI/spinner/AppSpinner';
import theme from './assets/styles/theme';
import './App.scss';
import { useSelector } from 'react-redux';
import { selectLoggedInUser } from './store/globalSlice';
import { PatientBooking } from './containers/auth/redirect/patientBooking.jsx';

const App = (props) => {
	const isAuthenticatedExist = getUserId();
	const user = useSelector(selectLoggedInUser);

	if (props.location.pathname === '/dashboard/doctor-profile') {
		document.body.classList.add('doctorProfile-cls');
	} else {
		document.body.classList.remove('doctorProfile-cls');
	}

	return (
		<ThemeProvider theme={theme}>
			<ToastContainer
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={true}
				closeOnClick
				pauseOnHover
				transition={Zoom}
			/>
			<Suspense fallback={<AppSpinner />}>
				<Switch>
					<Route path="/auth" render={(props) => <AuthLayout {...props} />} />
					<Route
						path="/dashboard"
						render={(props) => (
							<DashboardLayout {...props} userId={isAuthenticatedExist} />
						)}
					/>
					<Route
						path="/root"
						render={(props) => (
							<DashboardLayout {...props} userId={isAuthenticatedExist} />
						)}
					/>
					<Route
						path="/master-admin"
						render={(props) => (
							<DashboardLayout {...props} userId={isAuthenticatedExist} />
						)}
					/>
					<Route
						exact
						path="/p/:branchId/:referralId"
						component={PatientBooking}
					/>
					<Route
						exact
						path="/sent-confirmation"
						component={EmailVerification}
					/>
					<Redirect from="/" to="/auth/sign-in" />
				</Switch>
			</Suspense>
		</ThemeProvider>
	);
};

export default withRouter(App);
