import instance from '../../axios/ctdentAxios';
import axios from 'axios';

class UploadFilesService {
	openSession(uid, studyId) {
		return instance.post('/upload/open-session', {
			uid: uid,
			study_uid: studyId,
		});
	}

	requestUploadUrls(req) {
		return instance.post('/upload/request-upload-urls', req);
	}

	uploadFiles(cloudStorageUrl, selectedFile) {
		return axios.put(cloudStorageUrl, selectedFile, {
			headers: { 'Content-Type': 'application/json' },
			maxContentLength: Infinity,
			maxBodyLength: Infinity,
		});
	}

	uploadProgressNotify(progressNotifyRequest) {
		return instance.post('/upload/progress-notify', progressNotifyRequest);
	}

	startSessionProcessing(sessionIdRequest) {
		return instance.post('/upload/start-session-processing', sessionIdRequest);
	}

	checkSessionInfo(sessionIdRequest) {
		return instance.post('/upload/session-info', sessionIdRequest);
	}
}

export default new UploadFilesService();
