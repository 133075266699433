import { createSlice } from '@reduxjs/toolkit';

import {
	getDoctorDetails,
	getDoctorGeneralDiscounts,
	getDoctorSpecialDiscounts,
	updateGeneralDiscount,
	updateSpecialDiscount,
	deleteSpecialDiscount,
	getPriceList,
	addSpecialDiscount,
	updateMasterPriceListDiscount} from '../services/doctors/priceAndDiscount.service';

import * as discountComboService from '../services/doctors/discount.combo.service';
import * as discountSpecialService from '../services/doctors/discount.special.service';
import { getResponseTime } from '../utils/globals.utils';

export const priceAndDiscountSlice = createSlice({
	name: 'priceAndDiscount',
	initialState: {
		error: {},
		doctorPriceListLoading: false,
		doctorPriceListData: null,
		doctorDetailLoading: false,
		doctorDetailData: null,
		doctorGeneralDiscountLoading: false,
		doctorGeneralDiscountData: null,
		doctorSpecialDiscountLoading: false,
		doctorSpecialDiscountData: null,
		doctorComboDiscountLoading: false,
		doctorComboDiscountData: null,
		deleteComboDiscountLoading: false,
		deleteComboDiscountData: null,
		addComboDiscountLoading: false,
		addComboDiscountData: null,
		updateGeneralDiscountLoading: false,
		updateGeneralDiscountData: null,
		updateSpecialDiscountLoading: false,
		updateSpecialDiscountData: null,
		deleteSpecialDiscountLoading: false,
		deleteSpecialDiscountData: null,
		addSpecialDiscountLoading: false,
		addSpecialDiscountData: null,
		updateComboDiscountLoading: false,
		updateComboDiscountData: null,
		updatePriceListLoading: false,
		updatePriceListData: null,
	},

	reducers: {
		// * Doctor price list
		doctorPriceListRequestLoading(state, action) {
			if (!state.doctorPriceListLoading) {
				state.doctorPriceListLoading = true;
			}
		},

		doctorPriceListRequestSucceed(state, action) {
			state.doctorPriceListLoading = false;
			state.doctorPriceListData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		doctorPriceListRequestFailed(state, action) {
			if (state.doctorPriceListLoading) {
				state.doctorPriceListLoading = false;
				state.error = action.payload;
			}
		},

		// * Doctor detail
		doctorDetailRequestLoading(state, action) {
			if (!state.doctorDetailLoading) {
				state.doctorDetailLoading = true;
			}
		},

		doctorDetailRequestSucceed(state, action) {
			state.doctorDetailLoading = false;
			state.doctorDetailData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		doctorDetailRequestFailed(state, action) {
			if (state.doctorDetailLoading) {
				state.doctorDetailLoading = false;
				state.error = action.payload;
			}
		},

		// * Doctor general discount
		doctorGeneralDiscountRequestLoading(state, action) {
			if (!state.doctorGeneralDiscountLoading) {
				state.doctorGeneralDiscountLoading = true;
			}
		},

		doctorGeneralDiscountRequestSucceed(state, action) {
			state.doctorGeneralDiscountLoading = false;
			state.doctorGeneralDiscountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		doctorGeneralDiscountRequestFailed(state, action) {
			if (state.doctorGeneralDiscountLoading) {
				state.doctorGeneralDiscountLoading = false;
				state.error = action.payload;
			}
		},

		// * Doctor special discount
		doctorSpecialDiscountRequestLoading(state, action) {
			if (!state.doctorSpecialDiscountLoading) {
				state.doctorSpecialDiscountLoading = true;
			}
		},

		doctorSpecialDiscountRequestSucceed(state, action) {
			state.doctorSpecialDiscountLoading = false;
			state.doctorSpecialDiscountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		doctorSpecialDiscountRequestFailed(state, action) {
			if (state.doctorSpecialDiscountLoading) {
				state.doctorSpecialDiscountLoading = false;
				state.error = action.payload;
			}
		},

		// * Doctor combo discount
		doctorComboDiscountRequestLoading(state, action) {
			if (!state.doctorComboDiscountLoading) {
				state.doctorComboDiscountLoading = true;
			}
		},

		doctorComboDiscountRequestSucceed(state, action) {
			state.doctorComboDiscountLoading = false;
			state.doctorComboDiscountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		doctorComboDiscountRequestFailed(state, action) {
			if (state.doctorComboDiscountLoading) {
				state.doctorComboDiscountLoading = false;
				state.error = action.payload;
			}
		},

		// * Update general discount
		updateGeneralDiscountRequestLoading(state, action) {
			if (!state.updateGeneralDiscountLoading) {
				state.updateGeneralDiscountLoading = true;
			}
		},

		updateGeneralDiscountRequestSucceed(state, action) {
			state.updateGeneralDiscountLoading = false;
			state.updateGeneralDiscountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		updateGeneralDiscountRequestFailed(state, action) {
			if (state.updateGeneralDiscountLoading) {
				state.updateGeneralDiscountLoading = false;
				state.error = action.payload;
			}
		},

		// * Update special discount
		updateSpecialDiscountRequestLoading(state, action) {
			if (!state.updateSpecialDiscountLoading) {
				state.updateSpecialDiscountLoading = true;
			}
		},

		updateSpecialDiscountRequestSucceed(state, action) {
			state.updateSpecialDiscountLoading = false;
			state.updateSpecialDiscountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		updateSpecialDiscountRequestFailed(state, action) {
			if (state.updateSpecialDiscountLoading) {
				state.updateSpecialDiscountLoading = false;
				state.error = action.payload;
			}
		},

		// * delete special discount
		deleteSpecialDiscountRequestLoading(state, action) {
			if (!state.deleteSpecialDiscountLoading) {
				state.deleteSpecialDiscountLoading = true;
			}
		},

		deleteSpecialDiscountRequestSucceed(state, action) {
			state.deleteSpecialDiscountLoading = false;
			state.deleteSpecialDiscountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		deleteSpecialDiscountRequestFailed(state, action) {
			if (state.deleteSpecialDiscountLoading) {
				state.deleteSpecialDiscountLoading = false;
				state.error = action.payload;
			}
		},
		// * delete combo discount
		deleteComboDiscountRequestLoading(state, action) {
			if (!state.deleteComboDiscountLoading) {
				state.deleteComboDiscountLoading = true;
			}
		},

		deleteComboDiscountRequestSucceed(state, action) {
			state.deleteComboDiscountLoading = false;
			state.deleteComboDiscountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		deleteComboDiscountRequestFailed(state, action) {
			if (state.deleteComboDiscountLoading) {
				state.deleteComboDiscountLoading = false;
				state.error = action.payload;
			}
		},

		// * add special discount
		addSpecialDiscountRequestLoading(state, action) {
			if (!state.addSpecialDiscountLoading) {
				state.addSpecialDiscountLoading = true;
			}
		},

		addSpecialDiscountRequestSucceed(state, action) {
			state.addSpecialDiscountLoading = false;
			state.addSpecialDiscountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		addSpecialDiscountRequestFailed(state, action) {
			if (state.addSpecialDiscountLoading) {
				state.addSpecialDiscountLoading = false;
				state.error = action.payload;
			}
		},
		// * add combo discount
		addComboDiscountRequestLoading(state, action) {
			if (!state.addComboDiscountLoading) {
				state.addComboDiscountLoading = true;
			}
		},

		addComboDiscountRequestSucceed(state, action) {
			state.addComboDiscountLoading = false;
			state.addComboDiscountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		addComboDiscountRequestFailed(state, action) {
			if (state.addComboDiscountLoading) {
				state.addComboDiscountLoading = false;
				state.error = action.payload;
			}
		},
		// * Update combo discount
		updateComboDiscountRequestLoading(state, action) {
			if (!state.updateComboDiscountLoading) {
				state.updateComboDiscountLoading = true;
			}
		},

		updateComboDiscountRequestSucceed(state, action) {
			state.updateComboDiscountLoading = false;
			state.updateComboDiscountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		updateComboDiscountRequestFailed(state, action) {
			if (state.updateComboDiscountLoading) {
				state.updateComboDiscountLoading = false;
				state.error = action.payload;
			}
		},
		// * Update price list
		updatePriceListLoading(state, action) {
			if (!state.updatePriceListLoading) {
				state.updatePriceListLoading = true;
			}
		},

		updatePriceListRequestSucceed(state, action) {
			state.updatePriceListLoading = false;
			state.updatePriceListData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		updatePriceListRequestFailed(state, action) {
			if (state.updatePriceListLoading) {
				state.updatePriceListLoading = false;
				state.error = action.payload;
			}
		},
	},
});

export const {
	doctorPriceListRequestLoading,
	doctorPriceListRequestSucceed,
	doctorPriceListRequestFailed,
	doctorDetailRequestLoading,
	doctorDetailRequestSucceed,
	doctorDetailRequestFailed,
	doctorGeneralDiscountRequestLoading,
	doctorGeneralDiscountRequestSucceed,
	doctorGeneralDiscountRequestFailed,
	doctorSpecialDiscountRequestLoading,
	doctorSpecialDiscountRequestSucceed,
	doctorSpecialDiscountRequestFailed,
	doctorComboDiscountRequestLoading,
	doctorComboDiscountRequestSucceed,
	doctorComboDiscountRequestFailed,
	updateGeneralDiscountRequestLoading,
	updateGeneralDiscountRequestSucceed,
	updateGeneralDiscountRequestFailed,
	updateSpecialDiscountRequestLoading,
	updateSpecialDiscountRequestSucceed,
	updateSpecialDiscountRequestFailed,
	deleteSpecialDiscountRequestLoading,
	deleteSpecialDiscountRequestSucceed,
	deleteSpecialDiscountRequestFailed,
	addSpecialDiscountRequestLoading,
	addSpecialDiscountRequestSucceed,
	addSpecialDiscountRequestFailed,
	deleteComboDiscountRequestFailed,
	deleteComboDiscountRequestLoading,
	deleteComboDiscountRequestSucceed,
	addComboDiscountRequestLoading,
	addComboDiscountRequestFailed,
	addComboDiscountRequestSucceed,
	updateComboDiscountRequestLoading,
	updateComboDiscountRequestFailed,
	updateComboDiscountRequestSucceed,
	updatePriceListLoading,
	updatePriceListRequestSucceed,
	updatePriceListRequestFailed,
} = priceAndDiscountSlice.actions;

/**
 * REDUX THUNKS
 * Thunks dispatch action creators
 * */

// * Doctor price list
export const fetchDoctorPriceList = (requestParam) => async (dispatch) => {
	dispatch(doctorPriceListRequestLoading());
	try {
		const response = await getPriceList(requestParam);
		dispatch(doctorPriceListRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(doctorPriceListRequestFailed(error));
	}
};

export const isDoctorPriceListLoading = (state) =>
	state.priceAndDiscount.doctorPriceListLoading;
export const getDoctorPriceListResponse = (state) =>
	state?.priceAndDiscount?.doctorPriceListData?.data?.responseData || [];

// * Doctor detail
export const fetchDoctorDetail = (requestParam) => async (dispatch) => {
	dispatch(doctorDetailRequestLoading());
	try {
		const response = await getDoctorDetails(requestParam);
		dispatch(doctorDetailRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(doctorDetailRequestFailed(error));
	}
};
export const isDoctorDetailLoading = (state) =>
	state.priceAndDiscount.doctorDetailLoading;
export const getDoctorDetailResponse = (state) =>
	state?.priceAndDiscount?.doctorDetailData?.data?.responseData || [];

// * Doctor general discount
export const fetchDoctorGeneralDiscount =
	(requestParam) => async (dispatch) => {
		dispatch(doctorGeneralDiscountRequestLoading());
		try {
			const response = await getDoctorGeneralDiscounts(requestParam);
			dispatch(doctorGeneralDiscountRequestSucceed(response));
			return response;
		} catch (error) {
			dispatch(doctorGeneralDiscountRequestFailed(error));
		}
	};
export const isDoctorGeneralDiscountLoading = (state) =>
	state.priceAndDiscount.doctorGeneralDiscountLoading;
export const getDoctorGeneralDiscountResponse = (state) =>
	state?.priceAndDiscount?.doctorGeneralDiscountData?.data?.responseData || [];

// * Doctor special discount
export const fetchDoctorSpecialDiscount =
	(requestParam) => async (dispatch) => {
		dispatch(doctorSpecialDiscountRequestLoading());
		try {
			const response = await getDoctorSpecialDiscounts(requestParam);
			dispatch(doctorSpecialDiscountRequestSucceed(response));
			return response;
		} catch (error) {
			dispatch(doctorSpecialDiscountRequestFailed(error));
		}
	};

export const isDoctorSpecialDiscountLoading = (state) =>
	state.priceAndDiscount.doctorSpecialDiscountLoading;
export const isDoctorComboDiscountLoading = (state) =>
	state.priceAndDiscount.doctorComboDiscountLoading;
export const getDoctorSpecialDiscountResponse = (state) =>
	state?.priceAndDiscount?.doctorSpecialDiscountData?.data?.responseData || [];
export const getDoctorComboDiscountResponse = (state) =>
	state?.priceAndDiscount?.doctorComboDiscountData?.data?.responseData || [];

// * Combo Discounts
export const fetchDoctorComboDiscounts = requestParam => async dispatch =>{
	dispatch(doctorComboDiscountRequestLoading());
	try{
		const response = await discountComboService.getDoctorComboDiscounts(requestParam);
		dispatch(doctorComboDiscountRequestSucceed(response));
		return response;
	}catch (error){
		dispatch(doctorComboDiscountRequestFailed(error));
	}
}

export const fetchDeleteDoctorComboDiscount  = requestParam => async dispatch => {
	dispatch(deleteComboDiscountRequestLoading());
	try {
		const response = await discountComboService.deleteDoctorComboDiscount(requestParam);
		dispatch(deleteComboDiscountRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(deleteComboDiscountRequestFailed(error));
	}
};

export const fetchAddDoctorComboDiscount = requestParam => async dispatch =>{
	dispatch(addComboDiscountRequestLoading());
	try {
		const response = await discountComboService.addDoctorComboDiscount(requestParam);
		dispatch(addComboDiscountRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(addComboDiscountRequestFailed(error));
	}
}

export const fetchUpdateDoctorComboDiscount = requestParam => async dispatch =>{
	dispatch(updateComboDiscountRequestLoading());
	try {
		const response = await discountComboService.updateDoctorComboDiscount(requestParam);
		dispatch(updateComboDiscountRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(updateComboDiscountRequestFailed(error));
	}
}

export const doUpdatePriceList = requestParam => async dispatch =>{
	dispatch(updatePriceListLoading());
	try {
		const response = await updateMasterPriceListDiscount(requestParam);
		dispatch(updatePriceListRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(updatePriceListRequestFailed(error));
	}
}

// * Special Discounts (NEW)
export const fetchDoctorSpecialDiscount_New =
	(requestParam) => async (dispatch) => {
		dispatch(doctorSpecialDiscountRequestLoading());
		try {
			const response = await discountSpecialService.getDoctorSpecialDiscounts(requestParam);
			dispatch(doctorSpecialDiscountRequestSucceed(response));
			return response;
		} catch (error) {
			dispatch(doctorSpecialDiscountRequestFailed(error));
		}
	};

export const fetchAddSpecialDiscount_New = requestParam => async dispatch => {
	dispatch(addSpecialDiscountRequestLoading());
	try {
		const response = await discountSpecialService.addSpecialDiscount(requestParam);
		dispatch(addSpecialDiscountRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(addSpecialDiscountRequestFailed(error));
	}
};

export const fetchUpdateSpecialDiscount_New = requestParam => async dispatch => {
	dispatch(updateSpecialDiscountRequestLoading());
	try {
		const response = await discountSpecialService.updateSpecialDiscount(requestParam);
		dispatch(updateSpecialDiscountRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(updateSpecialDiscountRequestFailed(error));
	}
};

export const fetchDeleteSpecialDiscount_New = requestParam => async dispatch => {
	dispatch(deleteSpecialDiscountRequestLoading());
	try {
		const response = await discountSpecialService.deleteSpecialDiscount(requestParam);
		dispatch(deleteSpecialDiscountRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(deleteSpecialDiscountRequestFailed(error));
	}
};

// * update general discount
export const fetchUpdateGeneralDiscount =
	(requestParam) => async (dispatch) => {
		dispatch(updateGeneralDiscountRequestLoading());
		try {
			const response = await updateGeneralDiscount(requestParam);
			dispatch(updateGeneralDiscountRequestSucceed(response));
			return response;
		} catch (error) {
			dispatch(updateGeneralDiscountRequestFailed(error));
		}
	};
export const isGeneralDiscountUpdateLoading = (state) =>
	state.priceAndDiscount.updateGeneralDiscountLoading;
export const getUpdatedGeneralDiscountResponse = (state) =>
	state?.priceAndDiscount?.updateGeneralDiscountData?.data?.responseData || [];

// * update special discount
export const fetchUpdateSpecialDiscount =
	(requestParam) => async (dispatch) => {
		dispatch(updateSpecialDiscountRequestLoading());
		try {
			const response = await updateSpecialDiscount(requestParam);
			dispatch(updateSpecialDiscountRequestSucceed(response));
			return response;
		} catch (error) {
			dispatch(updateSpecialDiscountRequestFailed(error));
		}
	};

export const isSpecialDiscountUpdateLoading = (state) =>
	state.priceAndDiscount.updateSpecialDiscountLoading;
export const getUpdatedSpecialDiscountResponse = (state) =>
	state?.priceAndDiscount?.updateSpecialDiscountData?.data?.responseData || [];

// * delete special discount
export const fetchDeleteSpecialDiscount =
	(requestParam) => async (dispatch) => {
		dispatch(deleteSpecialDiscountRequestLoading());
		try {
			const response = await deleteSpecialDiscount(requestParam);
			dispatch(deleteSpecialDiscountRequestSucceed(response));
			return response;
		} catch (error) {
			dispatch(deleteSpecialDiscountRequestFailed(error));
		}
	};

export const isSpecialDiscountDeleteLoading = (state) =>
	state.priceAndDiscount.deleteSpecialDiscountLoading;
export const getDeletedSpecialDiscountResponse = (state) =>
	state?.priceAndDiscount?.deleteSpecialDiscountData?.data?.responseData || [];

// * add special discount
export const fetchAddSpecialDiscount = (requestParam) => async (dispatch) => {
	dispatch(addSpecialDiscountRequestLoading());
	try {
		const response = await addSpecialDiscount(requestParam);
		dispatch(addSpecialDiscountRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(addSpecialDiscountRequestFailed(error));
	}
};

export const isSpecialDiscountAddLoading = (state) =>
	state.priceAndDiscount.addSpecialDiscountLoading;
export const getAddSpecialDiscountResponse = (state) =>
	state?.priceAndDiscount?.addSpecialDiscountData?.data?.responseData || [];

export default priceAndDiscountSlice.reducer;
