import { createSlice } from '@reduxjs/toolkit';

import {
	specialitiesList,
	branchList,
	getProfileDetails,
	emailVerification,
	getUserInfo,
} from '../../services/auth.service';

export const signupSlice = createSlice({
	name: 'signup',
	initialState: {
		error: {},
		specialtiesListLoading: 'idle',
		specialtiesListData: [],
		BranchListLoading: 'idle',
		BranchListData: [],
		myProfileDetailsLoading: 'idle',
		myProfileDetails: [],
		signupEmailVerificationLoading: 'idle',
		signupEmailVerification: [],
		getUserDetailsLoading: 'idle',
		getUserDetailsSucceed: [],
	},
	reducers: {
		loadSpicialistListLoading(state, action) {
			if (state.specialtiesListLoading === 'idle') {
				state.specialtiesListLoading = 'pending';
			}
		},

		loadSpicialistListSucceed(state, action) {
			state.specialtiesListLoading = 'idle';
			state.specialtiesListData = action.payload;
		},

		loadSpicialistListFailed(state, action) {
			if (state.specialtiesListLoading === 'pending') {
				state.specialtiesListLoading = 'idle';
				state.error = action.payload;
			}
		},
		branchListLoading(state, action) {
			if (state.BranchListLoading === 'idle') {
				state.BranchListLoading = 'pending';
			}
		},

		branchListSucceed(state, action) {
			state.BranchListLoading = 'idle';
			state.BranchListData = action.payload;
		},

		branchListFailed(state, action) {
			if (state.BranchListLoading === 'pending') {
				state.BranchListLoading = 'idle';
				state.error = action.payload;
			}
		},
		myProfileDetailsLoading(state, action) {
			if (state.myProfileDetailsLoading === 'idle') {
				state.myProfileDetailsLoading = 'pending';
			}
		},

		myProfileDetailsSucceed(state, action) {
			state.myProfileDetailsLoading = 'idle';
			state.myProfileDetailsData = action.payload;
		},

		myProfileDetailsFailed(state, action) {
			if (state.myProfileDetailsLoading === 'pending') {
				state.myProfileDetailsLoading = 'idle';
				state.error = action.payload;
			}
		},
		signupEmailVerificationLoading(state, action) {
			if (state.signupEmailVerificationLoading === 'idle') {
				state.signupEmailVerificationLoading = 'pending';
			}
		},

		signupEmailVerificationSucceed(state, action) {
			state.signupEmailVerificationLoading = 'idle';
			state.signupEmailVerification = action.payload;
		},

		signupEmailVerificationFailed(state, action) {
			if (state.signupEmailVerificationLoading === 'pending') {
				state.signupEmailVerificationLoading = 'idle';
				state.error = action.payload;
			}
		},
		getUserDetailsLoading(state, action) {
			if (state.getUserDetailsLoading === 'idle') {
				state.getUserDetailsLoading = 'pending';
			}
		},

		getUserDetailsSucceed(state, action) {
			state.getUserDetailsLoading = 'idle';
			state.getUserDetails = action.payload;
		},

		getUserDetailsFailed(state, action) {
			if (state.getUserDetailsLoading === 'pending') {
				state.getUserDetailsLoading = 'idle';
				state.error = action.payload;
			}
		},
	},
});

export const {
	loadSpicialistListLoading,
	loadSpicialistListSucceed,
	loadSpicialistListFailed,
	branchListLoading,
	branchListSucceed,
	branchListFailed,
	myProfileDetailsLoading,
	myProfileDetailsSucceed,
	myProfileDetailsFailed,
	signupEmailVerificationLoading,
	signupEmailVerificationSucceed,
	signupEmailVerificationFailed,
	getUserDetailsLoading,
	getUserDetailsSucceed,
	getUserDetailsFailed,
} = signupSlice.actions;

/**
 * REDUX THUNKS
 * Thunks dispatch action creators
 * */

//Helper functions TODO: extract to service

export const loadAllSpecialist = () => async (dispatch) => {
	dispatch(loadSpicialistListLoading());
	try {
		const specialtiesList = await specialitiesList();
		dispatch(loadSpicialistListSucceed(specialtiesList));
	} catch (error) {
		dispatch(loadSpicialistListFailed(error));
	}
};

export const loadBranchList = () => async (dispatch) => {
	dispatch(branchListLoading());
	try {
		const specialtiesList = await branchList();
		dispatch(branchListSucceed(specialtiesList));
	} catch (error) {
		dispatch(branchListFailed(error));
	}
};

export const myProfileDetails = () => async (dispatch) => {
	dispatch(myProfileDetailsLoading());
	try {
		const myProfileDetails = await getProfileDetails();
		// console.log('myProfileDetails---', myProfileDetails);
		dispatch(myProfileDetailsSucceed(myProfileDetails));
		return myProfileDetails;
	} catch (error) {
		dispatch(myProfileDetailsFailed(error));
	}
};

export const signupEmailVerification = (requestParam) => async (dispatch) => {
	dispatch(signupEmailVerificationLoading());
	try {
		const emailVerificationData = await emailVerification(requestParam);
		dispatch(signupEmailVerificationSucceed(emailVerificationData));
		return emailVerificationData;
	} catch (error) {
		dispatch(signupEmailVerificationFailed(error));
	}
};

export const getUserDetails = (userId) => async (dispatch) => {
	dispatch(getUserDetailsLoading());
	try {
		const getUserInfoDetails = await getUserInfo(userId);
		dispatch(getUserDetailsSucceed(getUserInfoDetails));
	} catch (error) {
		dispatch(getUserDetailsFailed(error));
	}
};

export const selectSpecialist = (state) => state.signup.specialtiesListData;
export const selectSpecialistLoading = (state) =>
	state.signup.specialtiesListLoading;

export const branchListData = (state) => state.signup.BranchListData;
export const branchListDataLoading = (state) => state.signup.BranchListLoading;

export const myProfileDetailData = (state) => state.signup.myProfileDetailsData;
export const myProfileDetailLoading = (state) =>
	state.signup.myProfileDetailsLoading;

export const verifyEmail = (state) => state.signup.signupEmailVerification;
export const verifyEmailLoading = (state) =>
	state.signup.signupEmailVerificationLoading;

export const getUserDetail = (state) => state.signup.getUserDetails;
export const getUserDetailLoading = (state) =>
	state.signup.getUserDetailsLoading;

/**
 * select calendar events correlated to appointments
 * filter deleted and archived appointments
 */
//TODO - handled deleted/archived appointments

export default signupSlice.reducer;
