import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
	LOWER_LEFT_JAW_TEETH,
	LOWER_RIGHT_JAW_TEETH,
	UPPER_LEFT_JAW_TEETH,
	UPPER_RIGHT_JAW_TEETH,
	LOWER_LEFT_JAW_TEETH_VALUES,
	LOWER_RIGHT_JAW_TEETH_VALUES,
	UPPER_LEFT_JAW_TEETH_VALUES,
	UPPER_RIGHT_JAW_TEETH_VALUES,
} from '../../../../../assets/conf/diagnocat.configuration';
import JawTeethCheckboxes from './JawTeethCheckboxes';
import { TABLET_MOBILE_QUERY } from '../../../../../utils/constants';
import { useMediaQuery } from 'react-responsive';
import { getQuadrantTeeth } from '../../../../../utils/teeth.utils';

/**
 * Teeth selection form for order reports forms.
 * The selection is divided to 4 parts - upper left, upper right, bottom left and bottom right,
 * and there are checkboxes that allow selection of all teeth in each part.
 */
const TeethSelection = ({
	availableTeeth,
	isCheckboxes,
	values,
	disableTeeth,
	parentFormName,
	teethChanged,
	teethList,
}) => {
	const [multiSelectType, setMultiSelectType] = useState([]);
	const isTabletOrMobile = useMediaQuery(TABLET_MOBILE_QUERY);

	const isRowDisabled = (teeth) => {
		if (teeth && availableTeeth) {
			return !teeth.every((v) => availableTeeth.includes(v));
		}
		return false;
	};

	const handleChange = (e) => {
		let initialTeethList = values[parentFormName]?.teethList;
		const isExist = values[parentFormName]?.teethList?.includes(
			e?.target?.value,
		);
		if (isExist) {
			initialTeethList = initialTeethList.filter((d) => d !== e?.target?.value);
		} else {
			initialTeethList = [...initialTeethList, e?.target?.value];
		}
		const area = values?.cbctDetails?.area;
		if(area === "quadrant" && initialTeethList.length === 1 && !isExist){
			initialTeethList = getQuadrantTeeth(initialTeethList[0]);
		}
		teethChanged(initialTeethList);
	};

	const handleMultiSelectChange = (type) => {
		const isExist = multiSelectType?.includes(type);
		let latestTypes = multiSelectType;
		let initialTeethList = values[parentFormName].teethList;
		switch (type) {
			case 'upperLeft':
				if (isExist) {
					latestTypes = multiSelectType?.filter((t) => t !== type);
					initialTeethList = initialTeethList.filter(
						(val) => !UPPER_LEFT_JAW_TEETH.includes(val),
					);
				} else {
					latestTypes = [...multiSelectType, type];
					initialTeethList = [...initialTeethList, ...UPPER_LEFT_JAW_TEETH];
				}
				break;
			case 'upperRight':
				if (isExist) {
					latestTypes = multiSelectType?.filter((t) => t !== type);
					initialTeethList = initialTeethList.filter(
						(val) => !UPPER_RIGHT_JAW_TEETH.includes(val),
					);
				} else {
					latestTypes = [...multiSelectType, type];
					initialTeethList = [...initialTeethList, ...UPPER_RIGHT_JAW_TEETH];
				}
				break;
			case 'lowerLeft':
				if (isExist) {
					latestTypes = multiSelectType?.filter((t) => t !== type);
					initialTeethList = initialTeethList.filter(
						(val) => !LOWER_LEFT_JAW_TEETH.includes(val),
					);
				} else {
					latestTypes = [...multiSelectType, type];
					initialTeethList = [...initialTeethList, ...LOWER_LEFT_JAW_TEETH];
				}
				break;
			case 'lowerRight':
				if (isExist) {
					latestTypes = multiSelectType?.filter((t) => t !== type);
					initialTeethList = initialTeethList.filter(
						(val) => !LOWER_RIGHT_JAW_TEETH.includes(val),
					);
				} else {
					latestTypes = [...multiSelectType, type];
					initialTeethList = [...initialTeethList, ...LOWER_RIGHT_JAW_TEETH];
				}
				break;
			default:
				break;
		}
		setMultiSelectType(latestTypes);
		teethChanged(initialTeethList);
	};

	return (
		<React.Fragment>
			<FormControl
				component="fieldset"
				style={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
				}}
			>
				<Box>
					<FormGroup style={{ flexDirection: 'row' }}>
						<Grid container={true} item xs={isTabletOrMobile ? 12 : 6} direction="column">
							<Typography align="left" variant="subtitle2" fullWidth>
								<Button
									variant="text"
									onClick={() => handleMultiSelectChange('upperRight')}
									disabled={isRowDisabled(UPPER_RIGHT_JAW_TEETH)}
								>
									{multiSelectType?.includes('upperRight')
										? 'Clear selection'
										: 'Select All'}
								</Button>
							</Typography>
							<JawTeethCheckboxes
								teethValues={UPPER_RIGHT_JAW_TEETH_VALUES}
								availableTeeth={availableTeeth}
								checked={
									isCheckboxes ? values.upperRight || values.allTeeth : false
								}
								disableTeeth={disableTeeth}
								checkedValues={teethList}
								labelPlacement="bottom"
								alignItems="flex-end"
								handleChange={handleChange}
								borderRight={true}
							/>
						</Grid>
						<Grid container={true} item xs={isTabletOrMobile ? 12 : 6} direction="column">
							<Typography align={isTabletOrMobile ? 'left' : 'right'} variant="subtitle2" fullWidth>
								<Button
									variant="text"
									onClick={() => handleMultiSelectChange('upperLeft')}
									disabled={isRowDisabled(UPPER_LEFT_JAW_TEETH)}
								>
									{multiSelectType?.includes('upperLeft')
										? 'Clear selection'
										: 'Select All'}
								</Button>
							</Typography>
							<JawTeethCheckboxes
								teethValues={UPPER_LEFT_JAW_TEETH_VALUES}
								availableTeeth={availableTeeth}
								checked={
									isCheckboxes ? values.upperLeft || values.allTeeth : false
								}
								disableTeeth={disableTeeth}
								checkedValues={teethList}
								labelPlacement="bottom"
								alignItems="flex-end"
								handleChange={handleChange}
							/>
						</Grid>
					</FormGroup>
					<Grid
						style={{ marginTop: '15px', marginBottom: '15px' }}
						container
						alignItems="center"
						justifyContent="space-between"
						direction="row"
						fullWidth
					>
						<Typography>R</Typography>
						<Divider style={{ width: '90%' }} />
						<Typography>L</Typography>
					</Grid>
					<FormGroup style={{ flexDirection: 'row' }}>
						<Grid container={true} item xs={isTabletOrMobile ? 12 : 6} direction="column">
							<JawTeethCheckboxes
								teethValues={LOWER_RIGHT_JAW_TEETH_VALUES}
								availableTeeth={availableTeeth}
								checked={
									isCheckboxes ? values.lowerRight || values.allTeeth : false
								}
								disableTeeth={disableTeeth}
								checkedValues={teethList}
								alignItems="flex-start"
								handleChange={handleChange}
								borderRight={true}
							/>
							<Typography align="left" variant="subtitle2" fullWidth>
								<Button
									variant="text"
									onClick={() => handleMultiSelectChange('lowerRight')}
									disabled={isRowDisabled(LOWER_RIGHT_JAW_TEETH)}
								>
									{multiSelectType?.includes('lowerRight')
										? 'Clear selection'
										: 'Select All'}
								</Button>
							</Typography>
						</Grid>
						<Grid container={true} item xs={isTabletOrMobile ? 12 : 6} direction="column">
							<JawTeethCheckboxes
								teethValues={LOWER_LEFT_JAW_TEETH_VALUES}
								availableTeeth={availableTeeth}
								checked={
									isCheckboxes ? values.lowerLeft || values.allTeeth : false
								}
								disableTeeth={disableTeeth}
								checkedValues={teethList}
								alignItems="flex-start"
								handleChange={handleChange}
							/>
							<Typography align={isTabletOrMobile ? 'left' : 'right'} variant="subtitle2" fullWidth>
								<Button
									variant="text"
									onClick={() => handleMultiSelectChange('lowerLeft')}
									disabled={isRowDisabled(LOWER_LEFT_JAW_TEETH)}
								>
									{multiSelectType?.includes('lowerLeft')
										? 'Clear selection'
										: 'Select All'}
								</Button>
							</Typography>
						</Grid>
					</FormGroup>
				</Box>
			</FormControl>
		</React.Fragment>
	);
};

export default TeethSelection;
