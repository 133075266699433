import React, { Fragment, useEffect } from 'react';

export const PatientBooking = ({ history, match }) => {
	useEffect(() => {
    const branchId = match && match.params && match.params.branchId ? match.params.branchId : '';
    const referralId = match && match.params && match.params.referralId ? match.params.referralId : '';
    console.log( 'branchId', branchId );
    console.log( 'referralId', referralId );
    if (branchId && referralId) {
      window.location.href = `${process.env.REACT_APP_CT_DENT_BOOKING_URL}?branch_id=${branchId}&referred_app_id=${referralId}`;
    } else {
      history.push('/auth/sign-in');
    }
  }, [history, match]);
	return <Fragment></Fragment>;
};
