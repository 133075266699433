import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';

import withErrorHandler from '../../../../hoc/withErrorHandler';
import TeethSelection from '../../../../containers/diagnocat/dialogs/forms/teethSelection/TeethSelection';
import Typography from '@material-ui/core/Typography';

const RadiographicTemplate = ({ values, setFieldValue, handleChange }) => {
	return (
		<Fragment>
			<Grid item>
				<Typography align="left" variant="h6" color="textPrimary">
					Please indicate Implant placement sites:
				</Typography>
			</Grid>
			<Grid container item justifyContent="flex-start" alignItems="flex-start">
				<TeethSelection
					values={values}
					handleChange={handleChange}
					setFieldValue={setFieldValue}
					teethList={values?.intraoralDetails?.teethList}
					isCheckboxes
					teethChanged={(value) =>
						setFieldValue('intraoralDetails.teethList', value)
					}
					parentFormName="intraoralDetails"
				/>
			</Grid>
		</Fragment>
	);
};

export default withErrorHandler(RadiographicTemplate);
