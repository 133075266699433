import React from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionTooltip from '../DescriptionTooltip';
import { Box, Typography } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
	label: {
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '500',
		lineHeight: '21px',
		letterSpacing: '0em',
		color: '#63676A !important',
	},
	input: {
		marginTop: '5px',
		marginBottom: '5px',
	},
}));

const RadioButton = ({
	className = '',
	color = 'inherit',
	direction = 'row',
	disabled = false,
	error = false,
	helperText = '',
	label,
	name = '',
	onChange,
	options = [],
	required = false,
	size = 'small',
	style = null,
	translatedOptions = false,
	isBoolValue = false,
	isToolTip = false,
	value,
}) => {
	const { t } = useTranslation();
	const classes = useStyle();

	return (
		<FormControl
			component="fieldset"
			color={color}
			style={style}
			className={className}
			disabled={disabled}
			// required={required}
			error={error}
			size={size}
		>
			{label && (
				<FormLabel component="legend" className={classes.label}>
					{label} {required && '*'}
				</FormLabel>
			)}
			<RadioGroup
				aria-label={label}
				name={name}
				value={isBoolValue ? String(value) : value}
				onChange={onChange}
			>
				<Grid
					container
					direction={direction}
					style={{ display: isToolTip ? 'grid' : 'flex' }}
				>
					{options.map((opt) => (
						<>
							<Box
								sx={{
									display: 'flex',
									gap: 2,
								}}
							>
								<FormControlLabel
									value={isBoolValue ? String(opt?.value) : opt?.value}
									control={<Radio />}
									label={
										translatedOptions ? (
											<> {t(opt.name || opt.label)}</>
										) : (
											<>{opt.name || opt.label}</>
										)
									}
								></FormControlLabel>
								{opt?.tooltip && (
									<DescriptionTooltip
										description={opt?.tooltip}
										style={{ marginRight: 10 }}
									/>
								)}
							</Box>
							{opt?.description && (
								<Typography
									className="MuiFormHelperText-root MuiFormHelperText-contained"
									style={{ marginTop: -5, marginLeft: 30 }}
								>
									{opt?.description}
								</Typography>
							)}
						</>
					))}
				</Grid>
			</RadioGroup>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default RadioButton;
