import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import InvalisignForm from './InvisalignForm';
import RadioButton from '../../../UI/inputs/RadioButton';
import withErrorHandler from '../../../../hoc/withErrorHandler';

import { yesNo } from '../../../../utils/forms/options/form-options';
import { LONDON_BRANCH_ID } from '../../../../utils/constants';

const OrthodonticsAligners = ({
	values,
	handleChange,
	isInvalisign,
	setFieldValue,
	errors,
	branchId,
}) => {
	const { t } = useTranslation();

	return (
		<Grid
			container
			spacing={2}
			justifyContent="space-between"
			alignItems="flex-start"
			direction="column"
		>
			<Grid container item alignItems="center" direction="row" spacing={2}>
				<Grid item xs={12} className="global-input-width">
					<RadioButton
						label={t(branchId === LONDON_BRANCH_ID ? 'forms.patientReferral.requireInvalisign' : 'forms.patientReferral.requireItero')}
						name="intraoralDetails.upload_to_invisalign_account"
						error={errors?.patientDetails?.upload_to_invisalign_account}
						value={values?.intraoralDetails?.upload_to_invisalign_account}
						onChange={(e) =>
							setFieldValue(
								'intraoralDetails.upload_to_invisalign_account',
								e.target.value === 'true' ? true : false,
							)
						}
						options={yesNo}
						required={true}
						translatedOptions={true}
						isBoolValue
					/>
				</Grid>
			</Grid>
			{isInvalisign && (
				<Grid item xs={12}>
					<InvalisignForm
						values={values}
						handleChange={handleChange}
						withMessage
						branchId={branchId}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default withErrorHandler(OrthodonticsAligners);
