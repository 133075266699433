export const paymentOptions = [
    {
        value: 'doctor',
        label: 'globals.doctor',
    },
    {
        value: 'patient',
        label: 'globals.patient',
    },
];

export const CBCT = 1;
export const IMAGING2D = 2;
export const INTRAORAL = 3;

export const scansCheckboxOptions = [
    {
        label: 'forms.createAppointment.cbct',
        value: CBCT,
    },
    {
        label: 'forms.createAppointment.2d',
        value: IMAGING2D,
    },
    {
        label: 'forms.createAppointment.intraoral',
        value: INTRAORAL,
    },
];

export const appointmentConfirmationCheckboxOptions = [
    {
        label: 'forms.createAppointment.patientConsents',
        value: 'patientConsents',
        required: true,
    },
    {
        label: 'forms.createAppointment.patientAgrees',
        linkLabel: 'forms.createAppointment.patientAgreesLinkLabel',
        link: 'https://ct-dent.co.uk/terms-conditions',
        value: 'patientAgrees',
        required: true,
    },
    {
        label: 'forms.createAppointment.patientSubjectToSafeguarding',
        value: 'patientSubjectToSafeguarding',
        required: false,
    },
];

export const checkBoxOptions = [
    {
        label: 'globals.hasReferral',
        value: 'hasReferral',
    },
    {
        label: 'globals.wasExplained',
        value: 'wasExplained',
    },
    {
        label: 'globals.isWalkInPatient',
        value: 'isWalkInPatient',
    },
];
