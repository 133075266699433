import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

import authRoutes from '../../routing/routes/authRoutes';
import { getAuthRouteElements } from '../../utils/routing.utils';
import { AUTH_HOME } from '../../utils/constants';
import { useAuthStyles } from '../../containers/auth/auth.style';

const AuthLayout = () => {
	const classes = useAuthStyles();

	return (
		<Grid container className={classes.root}>
			<CssBaseline />
			<Switch>
				{getAuthRouteElements(authRoutes)}
				<Redirect from="/auth/sign-up" to="/auth/register" />
				<Redirect to={AUTH_HOME} />
			</Switch>
		</Grid>
	);
};

export default AuthLayout;
