import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CustomCheckbox from '../../../../../components/UI/inputs/CustomCheckbox';

import { teethImages } from '../../../../../utils/teethImages';
import { Box } from '@material-ui/core';
import { isValidArray } from '../../../../../utils/globals.utils';

const useStyles = makeStyles((theme) => ({
	checkboxLabel: {
		margin: '3px',
		'@media (max-width: 767px)': {
			margin: '0px',
		},
	},
}));

const JawTeethCheckboxes = ({
	alignItems,
	teethValues,
	checked,
	checkedValues = [],
	disableTeeth,
	availableTeeth,
	labelPlacement,
	handleChange,
	borderRight,
}) => {
	const classes = useStyles();

	return (
		<Box
			style={{
				display: 'flex',
				alignItems: alignItems,
				borderRight: borderRight && '1px solid rgb(204, 204, 204)',
			}}
		>
			{teethValues.map((opt) => (
				<CustomCheckbox
					className={classes.checkboxLabel}
					key={opt.value}
					label={opt.label}
					labelPlacement={labelPlacement || 'top'}
					name="teethList"
					value={opt.value}
					checked={
						checked ||
						(isValidArray(checkedValues) && checkedValues?.includes(opt.value))
					}
					onChange={handleChange}
					disabled={
						disableTeeth ? !availableTeeth?.includes(opt.value) : disableTeeth
					}
					icon={
						<img alt="" src={teethImages?.outlined[`outlined${opt?.value}`]} />
					}
					checkedIcon={
						<img alt="" src={teethImages?.filled[`filled${opt?.value}`]} />
					}
				/>
			))}
		</Box>
	);
};

export default JawTeethCheckboxes;
