import { createSlice } from '@reduxjs/toolkit';

import {
	fetchAllBranch,
	postSupportReportRequest,
} from '../services/common.service';
import { fetchBranchEmail } from '../services/doctors/doctors.service';
import { getResponseTime } from '../utils/globals.utils';
import { filterBranches, filterDetailBranch } from '../utils/appointment.utils';

export const commonSlice = createSlice({
	name: 'commonState',

	initialState: {
		error: {},
		supportLoading: false,
		supportData: null,
		branchListLoading: false,
		branchList: null,
		branchEmailLoading: false,
		branchEmail: null,
	},

	reducers: {
		// * support
		supportRequestLoading(state) {
			if (!state.supportLoading) {
				state.supportLoading = true;
			}
		},

		supportRequestSucceed(state, action) {
			state.supportLoading = false;
			state.supportData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		supportRequestFailed(state, action) {
			if (state.supportLoading) {
				state.supportLoading = false;
				state.error = action.payload;
			}
		},

		resetSupportData(state) {
			state.supportData = null;
		},

		// * all branch
		branchListRequestLoading(state) {
			if (!state.branchListLoading) {
				state.branchListLoading = true;
			}
		},

		branchListRequestSucceed(state, action) {
			state.branchListLoading = false;
			state.branchList = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		branchListRequestFailed(state, action) {
			if (state.branchListLoading) {
				state.branchListLoading = false;
				state.error = action.payload;
			}
		},

		// * all branch
		branchEmailRequestLoading(state) {
			if (!state.branchEmailLoading) {
				state.branchEmailLoading = true;
			}
		},

		branchEmailRequestSucceed(state, action) {
			state.branchEmailLoading = false;
			state.branchEmail = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		branchEmailRequestFailed(state, action) {
			if (state.branchEmailLoading) {
				state.branchEmailLoading = false;
				state.error = action.payload;
			}
		},
	},
});

export const {
	supportRequestLoading,
	supportRequestSucceed,
	supportRequestFailed,
	resetSupportData,
	branchListRequestLoading,
	branchListRequestSucceed,
	branchListRequestFailed,
	branchEmailRequestLoading,
	branchEmailRequestSucceed,
	branchEmailRequestFailed,
} = commonSlice.actions;

/**
 * REDUX THUNKS
 * Thunks dispatch action creators
 * */

// * support
export const submitSupportRequest = (requestParam) => async (dispatch) => {
	dispatch(supportRequestLoading());
	try {
		const response = await postSupportReportRequest(requestParam);
		dispatch(supportRequestSucceed(response));
	} catch (error) {
		dispatch(supportRequestFailed(error));
	}
};
export const isSupportRequestLoading = (state) => {
	return state.commonState.supportLoading;
};
export const getSupportResponse = (state) =>
	state?.commonState?.supportData?.data || [];

// * all branch
export const submitBranchListRequest = (requestParam) => async (dispatch) => {
	dispatch(branchListRequestLoading());
	try {
		const response = await fetchAllBranch(requestParam);
		dispatch(branchListRequestSucceed(response));
	} catch (error) {
		dispatch(branchListRequestFailed(error));
	}
};
export const isBranchListRequestLoading = (state) => {
	return state.commonState.branchListLoading;
};
export const getAllBranches = (state) => {
	return state?.commonState?.branchList?.data?.responseData.filter(b => filterBranches(b.id)) || []
};

// * branch Email
export const submitBranchEmailRequest = (requestParam) => async (dispatch) => {
	dispatch(branchEmailRequestLoading());
	try {
		const response = await fetchBranchEmail(requestParam);
		dispatch(branchEmailRequestSucceed(response));
	} catch (error) {
		dispatch(branchEmailRequestFailed(error));
	}
};
export const isBranchEmailRequestLoading = (state) => {
	return state.commonState.branchEmailLoading;
};
export const getBranchEmail = (state) =>
	state?.commonState?.branchEmail?.data?.responseData || [];

export default commonSlice.reducer;
