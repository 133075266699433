import updatedInstance from '../axios/ctdentAxios';
import {
	CTDENT_BRANCH_ALL,
	CTDENT_SUPPORT_REPORT_REQUEST,
} from '../assets/conf/api-endpoints';
import {
	generateHeaderToken,
	getHeaderTimestamp,
	getLocalStorage,
	getNonce,
} from '../utils/globals.utils';

//* Config for api
export const apiConfig = () => {
	const accessToken = getLocalStorage('authToken') || '';
	const tempAccessToken = getLocalStorage('tempAuthToken') || '';
	const appConfigData = {
		method: 'PUT,DELETE,POST,GET,OPTION',
		headers: {
			nonce: getNonce(),
			timestamp: getHeaderTimestamp(),
			token: generateHeaderToken(),
		},
	};
	if (accessToken || tempAccessToken)
		appConfigData.headers.accesstoken = accessToken || tempAccessToken;

	return appConfigData;
};

//* post support & report request
export async function postSupportReportRequest(requestParam) {
	const reqConfig = apiConfig();
	const response = await updatedInstance.post(
		CTDENT_SUPPORT_REPORT_REQUEST,
		requestParam,
		reqConfig,
	);
	return response;
}

//* get all branches
export async function fetchAllBranch(requestParam) {
	const reqConfig = apiConfig();
	const response = await updatedInstance.post(
		CTDENT_BRANCH_ALL,
		requestParam,
		reqConfig,
	);
	return response;
}

/**
 * GET REQUEST: Move files to S3
 * @param {String} requestParam
 * @returns {Object}
 */
export async function fetchCronURL(url) {
	const reqConfig = apiConfig();
	const response = await updatedInstance.get(url, {
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}
