import { createSlice } from '@reduxjs/toolkit';
import { getSalesRepRequestsApprovalsApi, updateSalesRepRequestApprovalsApi } from '../services/doctors/sales-rep-requests-approvals.service';

import { getResponseTime } from '../utils/globals.utils';

export const salesRepRequestApprovalSlice = createSlice({
    name: 'salesRepRequestApproval',
	initialState: {
        error: {},
        salesRepRequestApprovalsLoading: false,
        salesRepRequestApprovalsData: null,
        updateSalesRepRequestApprovalsLoading: false,
        updateSalesRepRequestApprovalsData: null,
    },
    reducers: {
        // * Sales Rep Request Approvals
		salesRepRequestApprovalsRequestLoading(state, action) {
			if (!state.salesRepRequestApprovalsLoading) {
				state.salesRepRequestApprovalsLoading = true;
			}
		},

		salesRepRequestApprovalsRequestSucceed(state, action) {
			state.salesRepRequestApprovalsLoading = false;
			state.salesRepRequestApprovalsData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		salesRepRequestApprovalsRequestFailed(state, action) {
			if (state.salesRepRequestApprovalsLoading) {
				state.salesRepRequestApprovalsLoading = false;
				state.error = action.payload;
			}
		},

        // *  Update Sales Rep Request Approvals
		updateSalesRepRequestApprovalsRequestLoading(state, action) {
			if (!state.updateSalesRepRequestApprovalsLoading) {
				state.updateSalesRepRequestApprovalsLoading = true;
			}
		},

		updateSalesRepRequestApprovalsRequestSucceed(state, action) {
			state.updateSalesRepRequestApprovalsLoading = false;
			state.updateSalesRepRequestApprovalsData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		updateSalesRepRequestApprovalsRequestFailed(state, action) {
			if (state.updateSalesRepRequestApprovalsLoading) {
				state.updateSalesRepRequestApprovalsLoading = false;
				state.error = action.payload;
			}
		},
    }
});

export const {
	salesRepRequestApprovalsRequestLoading,
	salesRepRequestApprovalsRequestSucceed,
	salesRepRequestApprovalsRequestFailed,
	updateSalesRepRequestApprovalsRequestLoading,
	updateSalesRepRequestApprovalsRequestSucceed,
	updateSalesRepRequestApprovalsRequestFailed,
} = salesRepRequestApprovalSlice.actions;


// * Sales Rep Request Approvals
export const isSalesRepRequestApprovalsLoading = (state) =>
	state.salesRepRequestApproval.salesRepRequestApprovalsLoading;

export const getSalesRepRequestApprovalsResponse = (state) =>
	state?.salesRepRequestApproval?.salesRepRequestApprovalsData?.data?.responseData || [];

export const fetchSalesRepRequestApprovals = requestParam => async dispatch =>{
	dispatch(salesRepRequestApprovalsRequestLoading());
	try{
		const response = await getSalesRepRequestsApprovalsApi(requestParam);
		dispatch(salesRepRequestApprovalsRequestSucceed(response));
		return response;
	}catch (error){
		dispatch(salesRepRequestApprovalsRequestFailed(error));
	}
}

// * update sales rep request approvals
export const updateSalesRepRequestApprovals =
	(requestParam) => async (dispatch) => {
		dispatch(updateSalesRepRequestApprovalsRequestLoading());
		try {
			const response = await updateSalesRepRequestApprovalsApi(requestParam);
			dispatch(updateSalesRepRequestApprovalsRequestSucceed(response));
			return response;
		} catch (error) {
			dispatch(updateSalesRepRequestApprovalsRequestFailed(error));
		}
	};

export const isUpdateSalesRepRequestApprovalsLoading = (state) =>
	state.salesRepRequestApproval.updateSalesRepRequestApprovalsLoading;
export const getUpdateSalesRepRequestApprovalsResponse = (state) =>
	state?.salesRepRequestApproval?.updateSalesRepRequestApprovalsData?.data?.responseData || [];

export default salesRepRequestApprovalSlice.reducer;