import { CTDENT_DOCTOR_SPECIAL_DISCOUNT_API } from "../../assets/conf/api-endpoints";
import { apiConfig } from "../common.service";
import instance from '../../axios/ctdentAxios';

export async function getDoctorSpecialDiscounts(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.get(CTDENT_DOCTOR_SPECIAL_DISCOUNT_API, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

export async function addSpecialDiscount(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_DOCTOR_SPECIAL_DISCOUNT_API,
		requestParam,
		reqConfig,
	);
	return response;
}

export async function updateSpecialDiscount(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.put(
		`${CTDENT_DOCTOR_SPECIAL_DISCOUNT_API}/${requestParam.id}`,
		requestParam,
		reqConfig,
	);
	return response;
}

export async function deleteSpecialDiscount(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.delete(
		`${CTDENT_DOCTOR_SPECIAL_DISCOUNT_API}/${requestParam.id}/${requestParam.login_doctor_id}`,
		reqConfig,
	);
	return response;
}

