import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { Box, Toolbar, IconButton } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

import NavbarUserMenu from './NavbarUserMenu';
import NavbarSections from './NavbarSections';
import NavbarHelpMenu from './NavbarHelpMenu';
import NavbarSupport from './NavbarSupport';
import withErrorHandler from '../../../hoc/withErrorHandler';

import {
	selectActiveAccount,
	selectLoggedInUser,
	setActiveAccount,
} from '../../../store/globalSlice';

import { useNavbarStyles } from './AppNavbar.style';
import logo from '../../../assets/images/ctdent_logo.png';
import SelectBox from '../inputs/SelectBox';
import { getConnectedAccounts } from '../../../store/calendarSlice';
import {
	capitalizeFirstLetter,
	isValidArray,
} from '../../../utils/globals.utils';

const AppNavbar = (props) => {
	const classes = useNavbarStyles();
	const dispatch = useDispatch();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

	//TODO: will be moved
	const loggedInUser = useSelector(selectLoggedInUser);
	const connectedAccounts = useSelector(getConnectedAccounts);
	const activeAccountId = useSelector(selectActiveAccount);

	//TODO: combine
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorEl2, setAnchorEl2] = React.useState(null);

	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const [accountOptions, setAccountOptions] = React.useState([]);

	const handleAccountChange = (e) => {
		dispatch(setActiveAccount(e?.target?.value));
	};

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleHelpMenuOpen = (event) => {
		setAnchorEl2(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setAnchorEl2(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	useEffect(() => {
		const currentUser = [
			{
				label: `${capitalizeFirstLetter(loggedInUser?.title)} ${
					loggedInUser?.forename
				} ${loggedInUser?.surname}`,
				value: loggedInUser?.id,
			},
		];

		if (loggedInUser && isValidArray(connectedAccounts)) {
			const connectedAccOptions = connectedAccounts.map((acc) => {
				return {
					label: acc?.connected_doctor_name,
					value: acc?.connected_doctor_id,
				};
			});
			setAccountOptions([...currentUser, ...connectedAccOptions]);
		} else if (loggedInUser) {
			setAccountOptions(currentUser);
		}
	}, [loggedInUser, connectedAccounts]);

	return (
		<Fragment>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: props.open,
				})}
			>
				<Toolbar>
					{/*close drawer - only mobile*/}
					{props.open ? (
						<IconButton
							color="inherit"
							aria-label="close drawer"
							onClick={props.closeDrawerClicked}
							edge="start"
							className={classes.menuButton}
						>
							<MenuOpenIcon htmlColor="#fff" fontSize="large" />
						</IconButton>
					) : (
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={props.openDrawerClicked}
							edge="start"
							className={classes.menuButton}
						>
							<MenuIcon htmlColor="#fff" fontSize="large" />
						</IconButton>
					)}

					<Box>
						<Link to="/dashboard/my-patients" variant="body2">
							<img src={logo} alt="" className={classes.logo} />
						</Link>
					</Box>

					<div className={classes.grow} />
					<Hidden mdDown>
						<Box maxWidth="250px">
							<SelectBox
								variant="outlined"
								style={{
									background: '#fff',
									borderRadius: '5px',
								}}
								placeHolder=""
								options={accountOptions}
								value={isValidArray(accountOptions) ? activeAccountId : ''}
								onChange={handleAccountChange}
							/>
						</Box>
					</Hidden>

					{!isTabletOrMobile && <NavbarSupport />}

					<NavbarSections
						profileMenuClicked={handleProfileMenuOpen}
						helpMenuClicked={handleHelpMenuOpen}
						mobileMenuClicked={handleMobileMenuOpen}
						loggedInUser={loggedInUser}
					/>
				</Toolbar>
			</AppBar>
			<NavbarHelpMenu
				anchorEl={anchorEl2}
				mobileMoreAnchorEl={mobileMoreAnchorEl}
				openProfileMenuClicked={handleHelpMenuOpen}
				closeMobileMenuClicked={handleMobileMenuClose}
				closeMenuClicked={handleMenuClose}
			/>
			<NavbarUserMenu
				anchorEl={anchorEl}
				mobileMoreAnchorEl={mobileMoreAnchorEl}
				openProfileMenuClicked={handleProfileMenuOpen}
				closeMobileMenuClicked={handleMobileMenuClose}
				closeMenuClicked={handleMenuClose}
			/>
		</Fragment>
	);
};

export default withErrorHandler(AppNavbar);
