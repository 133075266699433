import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#C8DA2E',
			light: '#C8DA2E',
			dark: '#9FAD1F',
			contrastText: '#333',
		},
		secondary: {
			main: '#333333',
			light: '#63676A',
			dark: '#333333',
			contrastText: '#C8DA2E',
		},
		type: 'light',
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 425,
			md: 768,
			lg: 1024,
			xl: 1440,
		},
	},
	paper: {
		background: '#FFFFFF',
		boxShadow: '0px 0px 16px rgba(99, 103, 106, 0.16)',
		borderRadius: '8px',
		padding: '20px',
	},
});

export default theme;
