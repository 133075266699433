import {
	FORMATS_APPOINTMENT,
	FORMATS_JAWSCANS,
	CTDENT_CALENDAR_SLOTS,
	CTDENT_CONNECTED_ACCOUNT,
	CTDENT_APPOINTMENT_SERVICE,
	CTDENT_GET_BRANCH_EMAIL,
	CTDENT_ROOT_SAVE_INVISIBLE_SHARE,
	CTDENT_ROOT_SAVE_PARTNERS_ACCOUNTS,
	CTDENT_PARTNER_CREATE_REPORT,
	CTDENT_PARTNER_GET_REPORT,
	CTDENT_ROOT_DETAILED_REPORT,
} from '../../assets/conf/api-endpoints';
import { apiConfig } from '../common.service';
import ctdentInstance from '../../axios/ctdentAxios';
import axios from 'axios';

// * fetch branch email
export async function fetchBranchEmail(requestParam) {
	const reqConfig = apiConfig();
	const response = await ctdentInstance.get(CTDENT_GET_BRANCH_EMAIL, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

// * fetch all Jaw Types
export async function fetchJawTypes() {
	const response = await ctdentInstance.get(FORMATS_JAWSCANS, apiConfig());
	const jaws = response.data.responseData;
	return jaws;
}
// * fetch all Appointment Formats
export async function fetchAppointmentFormats(branchId, scanId) {
	const response = await ctdentInstance.get(
		FORMATS_APPOINTMENT + '?branch_id=' + branchId + '&scan_id=' + scanId,
		apiConfig(),
	);
	return response.data.responseData;
}

// * fetch all calendar slots
let cancelToken
export async function fetchCalendarSlots(requestParam) {
	const reqConfig = apiConfig();
	if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.")
  }

	cancelToken = axios.CancelToken.source()
	const response = await ctdentInstance.get(CTDENT_CALENDAR_SLOTS, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
		cancelToken: cancelToken.token,
	});
	return response;
}

// * fetch connected accounts
export async function fetchConnectedAccounts(requestParam) {
	const reqConfig = apiConfig();
	const response = await ctdentInstance.get(CTDENT_CONNECTED_ACCOUNT, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

// * fetch appointment service
export async function fetchAppointmentService(requestParam) {
	const reqConfig = apiConfig();
	const response = await ctdentInstance.get(CTDENT_APPOINTMENT_SERVICE, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

export async function updateInvisibleShare(requestParam) {
	const response = await ctdentInstance.post(
		CTDENT_ROOT_SAVE_INVISIBLE_SHARE,
		requestParam,
		apiConfig(),
	);
	return response;
}

export async function sendDetailedReport(requestParam) {
	const response = await ctdentInstance.post(
		CTDENT_ROOT_DETAILED_REPORT,
		requestParam,
		apiConfig(),
	);
	return response;
}

export async function updatePartnersAccounts(requestParam) {
	const response = await ctdentInstance.post(
		CTDENT_ROOT_SAVE_PARTNERS_ACCOUNTS,
		requestParam,
		apiConfig(),
	);
	return response;
}

export async function createPartnerReport(requestParam) {
	const response = await ctdentInstance.post(
		CTDENT_PARTNER_CREATE_REPORT,
		requestParam,
		apiConfig(),
	);
	return response;
}

export async function getPartnerReport(requestParam) {
	const response = await ctdentInstance.post(
		CTDENT_PARTNER_GET_REPORT,
		requestParam,
		apiConfig(),
	);
	return response;
}