import updatedInstance from '../../axios/ctdentAxios';

import { DOCTOR_INVOICE_LIST } from '../../assets/conf/api-endpoints';
import { apiConfig } from '../common.service';

// * fetch all invoices
export async function fetchInvoices(requestParam) {
	const reqConfig = apiConfig();
	const response = await updatedInstance.get(DOCTOR_INVOICE_LIST, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}
