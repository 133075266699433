import updatedInstance from '../../axios/ctdentAxios';
import * as _ from 'lodash';

import { GET_APPOINTMENTS_STATUSES } from '../../assets/conf/api-endpoints';

import { apiConfig } from '../common.service';

/* FETCH APPOINTMENT STATUSES BY BRANCH ID */
export async function getAppointmentStatuses(branchId) {
	const response = await updatedInstance.get(
		GET_APPOINTMENTS_STATUSES + '?branch_id=' + branchId,
		apiConfig(),
	);
	return response;
}
