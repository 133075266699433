import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import withErrorHandler from '../../../../hoc/withErrorHandler';
import TextInput from '../../../UI/inputs/TextInput';
import { referPatientStyle } from '../../../../assets/styles/referPatient.style';
import {
	LONDON_BRANCH_ID,
} from '../../../../utils/constants';
import { useSelector } from 'react-redux';
import { myProfileDetailData } from '../../../../store/signup/signupSlice';
import { checkInvisalign } from '../../../../utils/appointment.utils';

const InvisalignForm = ({ values, branchId, handleChange, ...props }) => {
	const classes = referPatientStyle();
	const { t } = useTranslation();
	const myProfileDetailRes = useSelector(myProfileDetailData);
	const myProfileDetail = myProfileDetailRes?.data?.responseData || [];

	const getFullFieldName = (name) => {
		return 'intraoralDetails.' + name;
	};
	const getValue = (name) => {
		return values?.intraoralDetails[name];
	};

	return (
		<React.Fragment>
			{props.withMessage && (
				<Grid container spacing={2} alignItems="flex-start" direction="column">
					{branchId === LONDON_BRANCH_ID && (
						<>
							<Grid item xs={12} className="global-textarea-width">
								<Typography align="left">
									{t('forms.patientReferral.invisalign.3shapeMessage')}
								</Typography>
							</Grid>
							<Grid item xs={12} className="global-textarea-width">
								<Typography align="left">
									{t('forms.patientReferral.invisalign.invisalignMessage')}
								</Typography>
							</Grid>
						</>
					)}
					{checkInvisalign(branchId) && (
						<Grid item xs={12} className="global-textarea-width">
							<Typography align="left">
								{t('forms.patientReferral.invisalign.iteroMessage')}
							</Typography>
						</Grid>
					)}
				</Grid>
			)}

			{branchId === LONDON_BRANCH_ID && (
				<>
					{(!myProfileDetail?.threeshapeDetail?.threeshape_username ||
						!myProfileDetail?.threeshapeDetail?.threeshape_password) && (
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={12} style={{ paddingTop: '25px' }}>
								<Typography className={classes.sectionTitle}>
									{t('forms.patientReferral.invisalign.shapeLoginDetails')}
								</Typography>
							</Grid>
							<Grid item xs={12} className="global-input-width">
								<TextInput
									label={t('forms.username')}
									name={getFullFieldName('threeshape_username')}
									value={getValue('threeshape_username')}
									onChange={handleChange}
									autoComplete="new-password"
								/>
							</Grid>
							<Grid
								item
								xs={12}
								className="global-input-width global-input-margin-left"
							>
								<TextInput
									label={t('forms.password')}
									name={getFullFieldName('threeshape_password')}
									value={getValue('threeshape_password')}
									onChange={handleChange}
									type="password"
									autoComplete="new-password"
								/>
							</Grid>
						</Grid>
					)}

					{myProfileDetail?.threeshapeDetail?.threeshape_username &&
						myProfileDetail?.threeshapeDetail?.threeshape_password && (
							<Grid container spacing={2} alignItems="center">
								<Grid item xs={12} style={{ paddingTop: '25px' }}>
									<Typography>
										<Typography dangerouslySetInnerHTML={{__html: `Your 3shapecomunicator account with username <b>${myProfileDetail?.threeshapeDetail?.threeshape_username}</b> is already in system. To change your details please go to your profile.`}} />
									</Typography>
								</Grid>
							</Grid>
						)}

					{(!myProfileDetail?.invisalignDetail?.invisalign_username ||
						!myProfileDetail?.invisalignDetail?.invisalign_password) && (
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={12} style={{ paddingTop: '25px' }}>
								<Typography className={classes.sectionTitle}>
									{t('forms.patientReferral.invisalign.loginDetails')}
								</Typography>
							</Grid>
							<Grid item xs={12} className="global-input-width">
								<TextInput
									label={t('forms.username')}
									name={getFullFieldName('invisalign_username')}
									value={getValue('invisalign_username')}
									onChange={handleChange}
									autoComplete="new-password"
								/>
							</Grid>
							<Grid
								item
								xs={12}
								className="global-input-width global-input-margin-left"
							>
								<TextInput
									label={t('forms.password')}
									name={getFullFieldName('invisalign_password')}
									value={getValue('invisalign_password')}
									onChange={handleChange}
									type="password"
									autoComplete="new-password"
								/>
							</Grid>
						</Grid>
					)}

					{myProfileDetail?.invisalignDetail?.invisalign_username &&
						myProfileDetail?.invisalignDetail?.invisalign_password && (
							<Grid container spacing={2} alignItems="center">
								<Grid item xs={12} style={{ paddingTop: '25px' }}>
									<Typography dangerouslySetInnerHTML={{__html: `Your Invisalign account with username <b>${myProfileDetail?.invisalignDetail?.invisalign_username}</b> is already in system. To change your details please go to your profile.`}} />
								</Grid>
							</Grid>
						)}
				</>
			)}
			{checkInvisalign(branchId) && (
				<>
					{(!myProfileDetail?.iteroDetail?.itero_username ||
						!myProfileDetail?.iteroDetail?.itero_password) && (
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={12} style={{ paddingTop: '25px' }}>
								<Typography className={classes.sectionTitle}>
									{t('forms.patientReferral.invisalign.iteroLoginDetails')}
								</Typography>
							</Grid>
							<Grid item xs={12} className="global-input-width">
								<TextInput
									label={t('forms.username')}
									name={getFullFieldName('itero_username')}
									value={getValue('itero_username')}
									onChange={handleChange}
									autoComplete="new-password"
								/>
							</Grid>
							<Grid
								item
								xs={12}
								className="global-input-width global-input-margin-left"
							>
								<TextInput
									label={t('forms.password')}
									name={getFullFieldName('itero_password')}
									value={getValue('itero_password')}
									onChange={handleChange}
									type="password"
									autoComplete="new-password"
								/>
							</Grid>
						</Grid>
					)}
					{myProfileDetail?.iteroDetail?.itero_username &&
						myProfileDetail?.iteroDetail?.itero_password && (
							<Grid container spacing={2} alignItems="center">
								<Grid item xs={12} style={{ paddingTop: '25px' }}>
									<Typography dangerouslySetInnerHTML={{__html: `Your itero account with username <b>${myProfileDetail?.iteroDetail?.itero_username}</b> is already in system. To change your details please go to your profile.`}} />
								</Grid>
							</Grid>
						)}
				</>
			)}
		</React.Fragment>
	);
};

export default withErrorHandler(InvisalignForm);
