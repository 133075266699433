import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import DigitalImpression from './DigitalImpression';
import OrthodonticsAligners from './OrthodonticsAligners';
import RadiographicTemplate from './RadiographicTemplate';
import SelectBox from '../../../UI/inputs/SelectBox';
import withErrorHandler from '../../../../hoc/withErrorHandler';

import { intraoralScanningOptions } from '../../../../utils/forms/options/form-options';
import { referPatientStyle } from '../../../../assets/styles/referPatient.style';
import { useState } from 'react';
import { appointmentServiceData } from '../../../../store/doctors/referPatientSlice';
import { useSelector } from 'react-redux';

const REACT_APP_DIGITAL_IMPRESSION_ID = Number(process.env.REACT_APP_DIGITAL_IMPRESSION_ID);
const REACT_APP_ORTHODONTIC_ID = Number(process.env.REACT_APP_ORTHODONTIC_ID);

const IntraoralForm = ({
	values,
	handleChange,
	setFieldValue,
	errors,
	submitCount,
	branchId,
}) => {
	const classes = referPatientStyle();
	const { t } = useTranslation();
	const [options, setOptions] = useState(intraoralScanningOptions);
	const appointmentService = useSelector(appointmentServiceData);

	const renderSwitch = () => {
		switch (values?.intraoralDetails?.intra_oral_scanning_field) {
			case 'digital_impression':
				return (
					<DigitalImpression
						values={values}
						handleChange={handleChange}
						scanOption={values?.intraoralDetails?.cadcam_area}
						errors={errors}
					/>
				);
			case 'orthodontic_aligners':
				return (
					<OrthodonticsAligners
						values={values}
						handleChange={handleChange}
						isInvalisign={
							values?.intraoralDetails?.upload_to_invisalign_account
						}
						errors={errors}
						branchId={branchId}
						setFieldValue={setFieldValue}
					/>
				);
			case 'radiographic_template':
				return (
					<RadiographicTemplate
						values={values}
						setFieldValue={setFieldValue}
						handleChange={handleChange}
					/>
				);
			default:
				return null;
		}
	};

	const handleScanningChange = (value) => {
		setFieldValue('intraoralDetails.intra_oral_scanning_field', value);
		if (value === 'orthodontic_aligners') {
			setFieldValue('intraoralDetails.cadcam_area', '');
		}
	};

	useEffect(() => {
		if(options && options.length > 0 && appointmentService){
			setOptions(options.map(o => {
				const id = o.value === "digital_impression" ? REACT_APP_DIGITAL_IMPRESSION_ID : REACT_APP_ORTHODONTIC_ID;
				const e = appointmentService.find(a => a?.id === id);
				if(e){
					o.label = e?.service_name;
				}
				return o;
			}))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appointmentService])

	return (
		<Grid
			container
			item
			justifyContent="space-between"
			alignItems="flex-start"
			spacing={2}
		>
			<Grid item xs={12}>
				<Typography className={classes.sectionTitle}>
					{t('forms.createAppointment.intraoral')}
				</Typography>
			</Grid>
			<Grid item xs={12} className="global-input-width">
				<SelectBox
					label={t('forms.createAppointment.intraoral')}
					placeHolder="Select"
					name="intraoralDetails.intra_oral_scanning_field"
					value={values?.intraoralDetails?.intra_oral_scanning_field}
					onChange={(e) => handleScanningChange(e?.target?.value)}
					options={intraoralScanningOptions}
					required={true}
					translatedOptions={false}
					error={
						submitCount > 0 &&
						errors?.intraoralDetails?.intra_oral_scanning_field
					}
				/>
			</Grid>
			<Grid item xs={12}>
				{renderSwitch()}
			</Grid>
		</Grid>
	);
};

export default withErrorHandler(IntraoralForm);
