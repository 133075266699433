import { createSlice } from '@reduxjs/toolkit';
import { IDLE, PENDING } from '../../assets/conf/configurations';
import {
	CBCT,
	IMAGING2D,
	INTRAORAL,
} from '../../utils/forms/options/appointmentForm.options';

import {
	fetchAllBranches,
	fetchAppointmentStatuses,
	fetchOrderReportFields,
} from '../../services/doctors/configurations.service';

import { fetchAppointmentFormats } from '../../services/doctors/doctors.service';

export const doctorsConfigurationsSlice = createSlice({
	name: 'doctorsConfigurations',
	initialState: {
		loading: 'idle',
		error: {},
		statuses: [],
		fields: {
			orderReport: null,
		},
		branches: [],
	},
	reducers: {
		fetchAppointmentStatusesLoading(state) {
			if (state.loading === 'idle') {
				state.loading = 'pending';
			}
		},
		fetchAppointmentStatusesSucceed(state, action) {
			state.loading = 'idle';
			state.statuses = action.payload;
		},
		fetchAppointmentStatusesFailed(state, action) {
			if (state.loading === 'pending') {
				state.loading = 'idle';
				state.error = action.payload;
			}
		},

		loadOrderReportFieldsLoading(state) {
			if (state.loading === 'idle') {
				state.loading = 'pending';
			}
		},
		loadOrderReportFieldsSucceed(state, action) {
			state.loading = 'idle';
			state.fields.orderReport = action.payload;
		},
		loadOrderReportFieldsFailed(state, action) {
			if (state.loading === 'pending') {
				state.loading = 'idle';
				state.error = action.payload;
			}
		},

		loadAllBranchesLoading(state) {
			if (state.loading === 'idle') {
				state.loading = 'pending';
			}
		},
		loadAllBranchesSucceed(state, action) {
			state.loading = 'idle';
			state.branches = action.payload;
		},
		loadAllBranchesFailed(state, action) {
			if (state.loading === 'pending') {
				state.loading = 'idle';
				state.error = action.payload;
			}
		},
		loadAppointmentsFormatsLoading(state) {
			if (state.loading === IDLE) {
				state.loading = PENDING;
			}
		},
		loadAppointmentsFormatsSucceed(state, action) {
			state.loading = IDLE;
			state.appointmentsFormats = action.payload;
		},
		loadAppointmentsFormatsFailed(state, action) {
			if (state.loading === PENDING) {
				state.loading = IDLE;
				state.error = action.payload;
			}
		},
		loadJawTypesLoading(state) {
			if (state.loading === IDLE) {
				state.loading = PENDING;
			}
		},
		loadJawTypesSucceed(state, action) {
			state.loading = IDLE;
			state.jawTypes = action.payload;
		},
		loadJawTypesFailed(state, action) {
			if (state.loading === PENDING) {
				state.loading = IDLE;
				state.error = action.payload;
			}
		},
	},
});

export const {
	fetchAppointmentStatusesLoading,
	fetchAppointmentStatusesSucceed,
	fetchAppointmentStatusesFailed,
	loadOrderReportFieldsLoading,
	loadOrderReportFieldsSucceed,
	loadOrderReportFieldsFailed,
	loadAllBranchesLoading,
	loadAllBranchesSucceed,
	loadAllBranchesFailed,
	loadAppointmentsFormatsLoading,
	loadAppointmentsFormatsSucceed,
	loadAppointmentsFormatsFailed,
	loadJawTypesLoading,
	loadJawTypesSucceed,
	loadJawTypesFailed,
} = doctorsConfigurationsSlice.actions;

/**
 * REDUX THUNKS
 * Thunks dispatch action creators
 * */
export const fetchAppointmentStatusesState = () => async (dispatch) => {
	dispatch(fetchAppointmentStatusesLoading());
	try {
		const appointments = await fetchAppointmentStatuses();
		dispatch(fetchAppointmentStatusesSucceed(appointments));
	} catch (error) {
		dispatch(fetchAppointmentStatusesFailed(error));
	}
};

export const loadOrderReportFields = () => async (dispatch) => {
	dispatch(loadOrderReportFieldsLoading());
	try {
		const orderReportFields = await fetchOrderReportFields();
		dispatch(loadOrderReportFieldsSucceed(orderReportFields));
	} catch (error) {
		dispatch(loadOrderReportFieldsFailed(error));
	}
};

export const loadAllBranches = () => async (dispatch) => {
	dispatch(loadAllBranchesLoading());
	try {
		const branches = await fetchAllBranches();
		dispatch(loadAllBranchesSucceed(branches));
	} catch (error) {
		dispatch(loadAllBranchesFailed(error));
	}
};

export const loadAppointmentsFormats = () => async (dispatch, getState) => {
	const branchId = getState().global.branch.id;
	dispatch(loadAppointmentsFormatsLoading());
	try {
		const appointmentCBCTFormats = await fetchAppointmentFormats(
			branchId,
			CBCT,
		);
		const appointmentImaging2DFormats = await fetchAppointmentFormats(
			branchId,
			IMAGING2D,
		);
		const appointmentIntraoralFormats = await fetchAppointmentFormats(
			branchId,
			INTRAORAL,
		);

		dispatch(
			loadAppointmentsFormatsSucceed({
				CBCT: appointmentCBCTFormats,
				IMAGING2D: appointmentImaging2DFormats,
				INTRAORAL: appointmentIntraoralFormats,
			}),
		);
	} catch (error) {
		dispatch(loadAppointmentsFormatsFailed(error));
	}
};
//TODO - might need optimization
export const selectStatus = (statusId) => (state) =>
	state.doctorsConfigurations.statuses.filter(
		(status) => status.id === statusId,
	)[0];
export const selectOrderReportFields = (state) =>
	state.doctorsConfigurations.fields.orderReport;
export const selectBranches = (state) => state.doctorsConfigurations.branches;
export const selectAppointmentFormats = (state) =>
	state.doctorsConfigurations.appointmentsFormats;
export const selectJawTypes = (state) => state.doctorsConfigurations.jawTypes;

export default doctorsConfigurationsSlice.reducer;
