import React from 'react';

const SignIn = React.lazy(() => import('../../containers/auth/sign-in/SignIn'));
const SignUp = React.lazy(() => import('../../containers/auth/sign-up/SignUp'));
const ForgotPassword = React.lazy(() =>
	import('../../containers/auth/forgot-password/forgotPassword'),
);
const ResetPassword = React.lazy(() =>
	import('../../containers/auth/forgot-password/resetPassword'),
);
const ThankYou = React.lazy(() =>
	import('../../containers/auth/sign-up/thankyou'),
);

const authRoutes = [
	{
		path: '/sign-in',
		layout: '/auth',
		name: 'auth.signIn',
		component: SignIn,
	},
	{
		path: '/register',
		layout: '/auth',
		name: 'auth.signUp',
		component: SignUp,
	},
	{
		path: '/forgot-password',
		layout: '/auth',
		name: 'auth.forgotPassword',
		component: ForgotPassword,
	},
	{
		path: '/reset-password/:token',
		layout: '/auth',
		name: 'auth.resetPassword',
		component: ResetPassword,
	},
	{
		path: '/thank-you',
		layout: '/auth',
		name: 'auth.thankyou',
		component: ThankYou,
	},
];

export default authRoutes;
