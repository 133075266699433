import { CTDENT_DOCTOR_COMBO_DISCOUNT_API } from "../../assets/conf/api-endpoints";
import { apiConfig } from "../common.service";
import instance from '../../axios/ctdentAxios';

export const getDoctorComboDiscounts = async requestParam =>{
	const reqConfig = apiConfig();
	const response = await instance.get(CTDENT_DOCTOR_COMBO_DISCOUNT_API, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

export const addDoctorComboDiscount = async requestParam =>{
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_DOCTOR_COMBO_DISCOUNT_API,
		requestParam,
		reqConfig,
	);
	return response;
}

export const updateDoctorComboDiscount = async requestParam =>{
	const reqConfig = apiConfig();
	const response = await instance.put(
		`${CTDENT_DOCTOR_COMBO_DISCOUNT_API}/${requestParam.id}`,
		requestParam,
		reqConfig,
	);
	return response;
}

export const deleteDoctorComboDiscount = async requestParam => {
	const reqConfig = apiConfig();
	const response = await instance.delete(
		`${CTDENT_DOCTOR_COMBO_DISCOUNT_API}/${requestParam.id}/${requestParam.login_doctor_id}`,
		reqConfig
	);
	return response;
}