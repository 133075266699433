import { makeStyles } from '@material-ui/core/styles';

export const referPatientStyle = makeStyles((theme) => ({
	paper: {
		...theme.paper,
		marginBottom: '20px',
	},
	paperHightlight: {
		...theme.paper,
		marginBottom: '20px',
		border: '2px solid #C8DA2E',
	},
	linkImage: {
		width: '170px',
		display: 'flex',
		textAlign: 'center',
		justifyContent: 'center',
		padding: '0px !important',
	},
	sectionHeader: {},
	sectionTitle: {
		fontSize: '20px',
		fontWeight: '400',
		lineHeight: '21px',
		letterSpacing: '0em',
		color: '#333333',
		paddingBottom: '20px',
	},
	sectionInfo: {
		fontSize: '16px',
		fontWeight: '400',
		lineHeight: '21px',
		letterSpacing: '0em',
		color: '#63676A',
		margin: '8px 0px 8px 0px',
	},
	successBoxPaper: {
		...theme.paper,
		width: '506px',
		[theme.breakpoints.down('sm')]: {
			width: 'unset',
		},
	},
	unscheduledBoxPaper: {
		...theme.paper,
		width: 'unset',
	},
	unscheduleOptionGroup: {
		width: '100%',
		'& .MuiToggleButton-root.Mui-disabled': {
			color: '#000',
		},
	},
	some: { border: '1px solid #000' },
	successTitle: {
		color: '#63676A',
		fontSize: '24px',
		fontWeight: '400',
		lineHeight: '33px',
		letterSpacing: '0.01em',
		textTransform: 'capitalize',
	},
	successInfo: {
		color: '#898c8f',
		fontSize: '20px',
		fontStyle: 'normal',
		lineHeight: '21px',
		letterSpacing: '0em',
		marginTop: '10px',
	},
	printBtn: { marginBottom: '8px' },
	priceBreakdown: {
		fontSize: '14px',
		cursor: 'pointer',
		color: '#63676A',
	},
	priceDivider: {
		width: '33%',
		'@media (max-width: 768px)': {
			width: '48%',
		},
		'@media (max-width: 425px)': {
			width: '100%',
		},
	},
	outlinedBtn: {
		borderColor: '#AAAAAA',
		marginRight: '5px',
	},
	backdrop: {
		position: 'absolute',
		zIndex: theme.zIndex.drawer + 1,
		borderRadius: '8px',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
	textLeft: {
		width: '33.33333%',
		textAlign: 'left',
	},
	textCenter: {
		fontSize: '20px',
		fontWeight: '500',
		lineHeight: '21px',
		letterSpacing: '0em',
		color: '#000',
		paddingBottom: '20px',
		textAlign: 'center',
		width: '33.33333%',
		'@media (max-width: 767px)': {
			width: '64%',
			textAlign: 'left',
		},
	},
	textRight: {
		width: '33.33333%',
		textAlign: 'right',
	},
	gridFlex: {
		display: 'flex',
		flexFlow: 'row wrap',
		alignItems: 'baseline',
	},
	box: {
		// border: '2px solid #C8DA2E',
		padding: 8,
		width: '200px',
	},
	fileDeleteBox: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '8px',
		position: 'relative',
		'& .MuiSvgIcon-root': {
			display: 'none',
		},
		'&:hover': {
			'& .MuiSvgIcon-root': {
				display: 'block',
			},
		},
	},
}));
