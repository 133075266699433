import { CTDENT_ROOT_SALES_REP_REQUEST_APPROVALS_API, CTDENT_ROOT_UDPATE_SALES_REP_REQUEST_APPROVALS_API } from "../../assets/conf/api-endpoints";
import { apiConfig } from "../common.service";
import instance from '../../axios/ctdentAxios';

export const getSalesRepRequestsApprovalsApi = async requestParam =>{
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_ROOT_SALES_REP_REQUEST_APPROVALS_API,
		requestParam,
		reqConfig,
	);
	return response;
}

export const updateSalesRepRequestApprovalsApi = async requestParam =>{
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_ROOT_UDPATE_SALES_REP_REQUEST_APPROVALS_API,
		requestParam,
		reqConfig,
	);
	return response;
}