import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import withErrorHandler from '../../../hoc/withErrorHandler';
import { getRouteByLayoutAndPath } from '../../../utils/routing.utils';
import { TABLET_MOBILE_QUERY } from '../../../utils/constants';
import { useStyles } from './AppDrawer.style';

const MenuItems = (props) => {
	const classes = useStyles();
	const isTabletOrMobile = useMediaQuery(TABLET_MOBILE_QUERY);
	const location = useLocation();
	const { t } = useTranslation();

	return (
		<List className={'left_menu_ul ' + classes.list}>
			{props.routes.map((route) => (
				<ListItem
					button
					className={route.className + ' ' + classes.listItem}
					onClick={isTabletOrMobile ? props.menuItemClicked : null} // * close the drawer after menu item selected, only on mobile
					selected={route.layout + route.path === location.pathname}
					key={route.name}
					component={Link}
					to={getRouteByLayoutAndPath(route.layout, route.path)}
				>
					<ListItemIcon>
						<img src={route.icon} alt="" />
					</ListItemIcon>
					<ListItemText
						className={classes.listItemText}
						disableTypography
						primary={t(route.name)}
					/>
				</ListItem>
			))}
		</List>
	);
};

export default withErrorHandler(MenuItems);
