import hmacSHA256 from 'crypto-js/hmac-sha256';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

/**
 * Return doctor's full name
 * @param {String} firstName
 * @param String*} lastName
 * @returns {String}
 */
export const getFullName = (firstName, lastName) => {
	return firstName + ' ' + lastName;
};

/**
 * Return doctor's full name (Capitalize)
 * @param {Object} obj
 * @returns {String}
 */
export const getDoctorFullName = (obj) => {
	return (
		capitalizeFirstLetter(obj?.title) + ' ' + obj?.forename + ' ' + obj?.surname
	);
};

export const timer = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * Get logged in user ID
 * @param {Number} userId
 */
export const setUserId = (userId) => {
	localStorage.setItem('userId', userId);
};

/**
 * Get logged in user ID
 * @returns {Number}
 */
export const getUserId = () => {
	return localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : null;
};

export const removeLocalStorageItem = (key) => {
	return localStorage.removeItem(key);
};
export const setLocalStorageItem = (key, items) => {
	return localStorage.setItem(key, items);
};
export const getLocalStorageItem = (key) => {
	return localStorage.getItem(key);
};
export const getNonce = () => {
	return Math.round(new Date().getTime() / 1000);
};

export const getHeaderTimestamp = () => {
	return Math.round(new Date().getTime() / 1000) * 1000;
};

export const generateHeaderToken = () => {
	const nonce = getNonce();
	const timestamp = getHeaderTimestamp();
	const secretKey = process.env.REACT_APP_SECRET_KEY;
	const privateKey = process.env.REACT_APP_PRIVATE_KEY;

	const tokenString =
		'nonce=' + nonce + '&timestamp=' + timestamp + '|' + secretKey;

	return hmacSHA256(tokenString, privateKey).toString();
};

export const getLocalStorage = (key) => {
	return localStorage.getItem(key);
};

export const apiToastSuccess = (data) => {
	if (data && data.data && data.data.message) {
		toast.success(data.data.message, { position: 'top-right' });
	}
};
export const toastSuccess = (message) => {
	toast.success(message);
};

export const toastError = (message) => {
	toast.error(message);
};

export const convertToFirstLetterUpper = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const PaymentType = () => {
	const { t } = useTranslation();

	return {
		doctor: t('paymentType.doctorsPays'),
		patient: t('paymentType.patientPays'),
	};
};

export const getCurrentTimestamp = () => {
	return Date.now();
};
export const removeObjectProperties = (obj, props) => {
	for (var i = 0; i < props.length; i++) {
		if (obj.hasOwnProperty(props[i])) {
			delete obj[props[i]];
		}
	}

	return obj;
};
export const removeArrayItem = (arr, itemToRemove) => {
	// var array = [...arr];
	// var index = array.indexOf(itemToRemove)
	// if (index !== -1) {
	//   array.splice(index, 1);
	//   return array;
	// }
	return arr.filter((item) => item !== itemToRemove);
};
export const storeImageInCache = () => {
	const Images = async (srcArray) => {
		const promises = await srcArray.map((src) => {
			return new Promise(function (resolve, reject) {
				const img = new Image();
				img.src = src;
				img.onload = resolve();
				img.onerror = reject();
			});
		});

		await Promise.all(promises);
	};
};
export const hasValue = (data) => {
	return data !== undefined && data !== null && data !== '' ? 1 : 0;
};

//* console API execution time
export const getResponseTime = (responseTime = null) => {
	if (responseTime) {
		const requestTimestamp = parseInt(responseTime?.reqTime);
		const queryTimestamp = parseInt(responseTime?.queryTime) * 1000;
		const responseTimestamp = getCurrentTimestamp();

		const executionTimeFormat = Math.floor(
			((queryTimestamp - requestTimestamp) / 1000) % 60,
		);
		console.group();
		try {
			console.log('Endpoint name:', responseTime?.apiName);
			console.log('Request sent:', new Date(requestTimestamp));
			console.log('Response received:', new Date(responseTimestamp));
			console.log('Total execution time:', `${executionTimeFormat} sec`);
		} catch (error) {
			console.error('error printing timestamp', error);
		}
		console.groupEnd();
	}
};

//* check array/object is valid
export const isValidArray = (data) => {
	return data && Array.isArray(data) && data.length > 0;
};

export const isValidObject = (data) => {
	return typeof data === 'object' && data
		? Object.keys(data).length > 0
		: false;
};

// * cache image
export const checkImage = (path) => {
	new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(path);
		img.onerror = () => reject();

		img.src = path;
	});
};

// * stripe amount
export const stripeAmount = (amount) => {
	return Number(amount) / 100;
};

export const capitalizeFirstLetter = (string) => {
	return string ? string?.charAt(0)?.toUpperCase() + string?.slice(1) : string;
};

// * return if given URL is valid or not
export const isValidURL = (string) => {
	const res = string.match(
		/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
	);
	return res !== null;
};

// * check for string and number validation
export const strictTextValidation = (value, maxLength, isNumber = false) => {
	if (value?.length <= maxLength) {
		const regex = isNumber ? /^\d+$/ : /^[a-zA-Z ]*$/;
		if (regex.test(value) || value === '') {
			return true;
		}
	}
	return false;
};

// * check for email validation
export const strictEmailValidation = (value) => {
	if (value) {
		const emailRegExp =
			/^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
		if (emailRegExp.test(value)) {
			return true;
		}
	}
	return false;
};

export const moveArrayElementPosition = (arr, old_index, new_index) => {
	try {
		if (new_index >= arr.length) {
			var k = new_index - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		return arr;
	} catch (error) {
		return arr;
	}
};

export const getDiscountOptions = (maxValue = 50, isInternational = 0) => {
	let options = [];
	if(isInternational){
		let count = -105;
		while (count < -5) {
			count += 5;
			options = [...options, { label: `${count}%`, value: count }];
		}
	}
	options = [...options, { label: '0%', value: '0' }];
	let count = 0;
	while (count < maxValue) {
		count += 5;
		options = [...options, { label: `${count}%`, value: count }];
	}
	return options;
};

export const getDiscountUsingOptions = (minValue = 0, maxValue = 50, isInternational = 0, interval = 1) => {
	let options = [];
	if(isInternational){
		let count = -101;
		while (count < -1) {
			count += interval;
			options = [...options, { label: `${count}%`, value: count }];
		}
	}
	options = [...options, { label: `${minValue}%`, value: minValue }];
	let count = minValue;
	while (count < maxValue) {
		count += interval;
		options = [...options, { label: `${count}%`, value: count }];
	}
	return options;
};

export const checkOffIntraOral = (date) => {
	return moment(date).format('YYYY-MM-DD') === '2022-08-04'
}

export const christmasModalStorage = () => {
	localStorage.setItem('_ct_last_christmas_popup', moment())
}

export const checkChristmasModal = () => {
	const today = moment();
	const start = moment("2022-12-09");
	const end = moment("2023-01-03");
	const lastDisplay = localStorage.getItem('_ct_last_christmas_popup');
	if(!lastDisplay || !moment(lastDisplay).isSame(today, "day")) {
		if(today >= start && today <= end){
			return true;
		}
	}
	return false;
}