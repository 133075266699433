import axios from 'axios';
import { REACT_APP_API_URL } from '../assets/conf/api-endpoints';
import { getLocalStorage, removeLocalStorageItem, toastError } from '../utils/globals.utils';
import { AUTH_HOME } from '../utils/constants';

const ctdentInstance = axios.create({
	baseURL: REACT_APP_API_URL,
});

ctdentInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const accessToken = getLocalStorage('authToken') || '';
		const tempAccessToken = getLocalStorage('tempAuthToken') || '';
		if (
			error?.response?.data?.responseCode === 401 &&
			(accessToken || tempAccessToken)
		) {
			removeLocalStorageItem('userId');
			removeLocalStorageItem('authToken');
			window.location.href = AUTH_HOME;
			toastError('Session expired. Please login again.');
		}
		return Promise.reject(error);
	},
);

export default ctdentInstance;
