import { configureStore } from '@reduxjs/toolkit';

import globalReducer from './globalSlice';
import diagnocatReducer from './diagnocat/diagnocatSlice';
import uploadReducer from './diagnocat/uploadSlice';
import doctorsReducer from './doctors/doctorsSlice';
import signupReducer from './signup/signupSlice';
import referPatientReducer from './doctors/referPatientSlice';
import invoiceReducer from './invoiceSlice';
import agreementPersonsReducer from './doctors/agreementPersonsSlice';
import orderReportReducer from './orderReportSlice';
import commonReducer from './commonSlice';
import calendarReducer from './calendarSlice';
import stripeReducer from './doctors/stripeSlice';
import priceAndDiscountReducer from './priceAndDiscountSlice';
import salesRepRequestApprovalReducer from './salesRepRequestApprovalSlice';
import doctorsConfigurationsReducer from './doctors/doctorsConfigurationsSlice';

const store = configureStore({
	reducer: {
		diagnocat: diagnocatReducer,
		doctors: doctorsReducer,
		doctorsConfigurations: doctorsConfigurationsReducer,
		global: globalReducer,
		upload: uploadReducer,
		signup: signupReducer,
		invoice: invoiceReducer,
		orderReport: orderReportReducer,
		referPatient: referPatientReducer,
		commonState: commonReducer,
		calendar: calendarReducer,
		stripe: stripeReducer,
		priceAndDiscount: priceAndDiscountReducer,
		agreementPersons: agreementPersonsReducer,
		salesRepRequestApproval: salesRepRequestApprovalReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export default store;
