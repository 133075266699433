import React, { useState } from 'react';

import HelpIcon from '@material-ui/icons/Help';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip, withStyles } from '@material-ui/core';

import withErrorHandler from '../../hoc/withErrorHandler';

export const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#1b1a1e',
		color: '#fff',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);

const DescriptionTooltip = (props) => {
	const [showFilledIcon, setShowFilledIcon] = useState(false);

	const isValidURL = (string) => {
		const res = string.match(
			/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
		);
		return res !== null;
	};
	// * open link from description tooltip in a new tab
	const contentClickHandler = (e) => {
		const targetLink = e?.target?.closest('span');
		if (!targetLink) return;

		e.preventDefault();
		const link = targetLink?.getAttribute('href');
		const isValid = isValidURL(link);
		const finalLink = isValid ? link : 'https://ct-dent.co.uk' + link;
		const win = window.open(finalLink, '_blank');
		win.focus();
	};

	return (
		<HtmlTooltip
			interactive
			enterTouchDelay={0}
			style={props?.style ? props?.style : {}}
			leaveTouchDelay={100000}
			title={
				<div
					className="refer-checkbox-tooltip"
					onClick={contentClickHandler}
					dangerouslySetInnerHTML={{
						__html: props.description,
					}}
				></div>
			}
		>
			<IconButton
				size="small"
				onMouseEnter={() => setShowFilledIcon(true)}
				onMouseLeave={() => setShowFilledIcon(false)}
			>
				{showFilledIcon ? (
					<HelpIcon color="inherit" />
				) : (
					<HelpOutlineOutlinedIcon color="inherit" />
				)}
			</IconButton>
		</HtmlTooltip>
	);
};

export default withErrorHandler(DescriptionTooltip);
