import { createSlice } from "@reduxjs/toolkit";
import { getDoctorAgreementPerson } from "../../services/auth.service";

export const agreementPersonsSlice = createSlice({
    name: "agreementPersons",

    initialState: {
      error: {},
      agreementPersonsLoading: false,
      agreementPersonsData: null,
    },

    reducers: {
      // * Agreement Persons
      agreementPersonsRequestLoading(state, action) {
        if (!state.agreementPersonsLoading) {
          state.agreementPersonsLoading = true;
        }
      },

      agreementPersonsRequestSucceed(state, action) {
        state.agreementPersonsLoading = false;
        state.agreementPersonsData = action.payload;
      },

      agreementPersonsRequestFailed(state, action) {
        if (state.agreementPersonsLoading) {
          state.agreementPersonsLoading = false;
          state.error = action.payload;
        }
      },
    },
});

export const {
  agreementPersonsRequestLoading,
  agreementPersonsRequestSucceed,
  agreementPersonsRequestFailed,
} = agreementPersonsSlice.actions;

/**
 * REDUX THUNKS
 * Thunks dispatch action creators
 * */

// * Agreement Persons
export const getAgreementPersons = (requestParam) => async (dispatch) => {
  dispatch(agreementPersonsRequestLoading());
  console.log( 'requestParam>>>>>>>>>>>>>', requestParam );
  try {
    const response = await getDoctorAgreementPerson(requestParam);
    dispatch(agreementPersonsRequestSucceed(response));
  } catch (error) {
    dispatch(agreementPersonsRequestFailed(error));
  }
};
export const isAgreementPersonsLoading = (state) => state.agreementPersons.agreementPersonsLoading;
export const getAgreementPersonsResponse = (state) =>
  state?.agreementPersons?.agreementPersonsData?.data?.responseData || [];

export default agreementPersonsSlice.reducer;
