import instance from '../../axios/ctdentAxios';

import {
	CTDENT_API_KEY,
	CTDENT_APPOINTMENT_CREATE,
	CTDENT_APPOINTMENT_GET,
	CTDENT_APPOINTMENT_PRICE_BREAKDOWN,
	CTDENT_APPOINTMENT_SEND_EMAIL,
	CTDENT_APPOINTMENT_SHARE,
	CTDENT_APPOINTMENT_UPDATE,
	CTDENT_APPOINTMENT_UPLOAD_FILE,
	CTDENT_GET_ALL_APPOINTMENTS_BY_PARAMETERS,
	CTDENT_GET_APPOINTMENT_OTHER_CASE,
	CTDENT_POST_JOIN_ACCOUNT,
	DOCTOR_PRICE_LIST_UPDATED,
	CTDENT_APPOINTMENT_SUCCESS_UPDATE,
	CTDENT_APPOINTMENT_ARCHIVE,
	CTDENT_APPOINTMENT_DATE_UPDATE,
	CTDENT_PARTNER_APPOINTMENT_STATUS_UPDATE,
	CTDENT_APPOINTMENT_GET_UPLOADED_FILE,
	CTDENT_APPOINTMENT_REMOVE_UPLOADED_FILE,
	APPOINMENT_SEARCH,
	CTDENT_APPOINTMENT_PRICE_UPDATE,
	CTDENT_APPOINTMENT_PAYMENT_UPDATE,
	CTDENT_APPOINTMENT_REGENERATE_INVOICE,
} from '../../assets/conf/api-endpoints';

import { APPOINMENT_BY_ID } from '../../assets/conf/api-endpoints';

import { CHECKED_BY_PROCESSING_TEAM } from '../../assets/conf/diagnocat.configuration';

import { apiConfig } from '../common.service';

//TODO - split to different functions with fieldName as part of te function, for example: updateAppointmentRadiologist
export async function updateAppointment(appointmentId, fieldName, fieldValue) {
	let req = {
		api_key: CTDENT_API_KEY,
		id: appointmentId,
		field: fieldName,
		value: fieldValue,
	};
	const response = await instance.post('/appointment/update/field', req);
	return response.data;
}

//* Currently supports only 1 appointment per patient
export async function getAppointmentByPatientId(patientId) {
	let req = {
		api_key: CTDENT_API_KEY,
		offset: 0,
		limit: 1,
		patient_id: patientId,
	};
	const response = await instance.post(
		CTDENT_GET_ALL_APPOINTMENTS_BY_PARAMETERS,
		req,
	);
	return response.data.data ? response.data.data[0] : null;
}

// export async function getAppointmentById(id) {
//   let req = {
//     api_key: CTDENT_API_KEY,
//     id: id,
//   };
//   const response = await instance.post(CTDENT_GET_APPOINTMENT, req);
//   return response.data.data ? response.data.data : null;
// }

//* Get all appointments assigned to the given radiologist,
//* which have diagnocat_status = CHECKED_BY_PROCESSING_TEAM
export async function fetchAllRadiologistAppointments(radiologistId) {
	const ctdent_req = {
		api_key: CTDENT_API_KEY,
		offset: 0,
		limit: 1000,
		assigned_radiologist: radiologistId,
		diagnocat_status: CHECKED_BY_PROCESSING_TEAM,
	};
	const appointments = await fetchAppointments(ctdent_req);
	console.log('appointments', appointments);
	return appointments;
}

//* fetch first appointments to populate table
export async function fetchNextDoctorAppointments(doctorId) {
	const ctdent_req = {
		api_key: CTDENT_API_KEY,
		offset: 0,
		limit: 10000, //TODO: pagination
		doctor_id: doctorId,
	};
	const appointments = await fetchAppointments(ctdent_req);
	return appointments;
}

//* fetch first waiting-list(unscheduled referrals) appointments to populate table
export async function fetchNextWaitingListAppointments(doctorId) {
	const ctdent_req = {
		api_key: CTDENT_API_KEY,
		offset: 0,
		limit: 10000, //TODO: pagination
		doctor_id: doctorId,
		on_waiting_list: 1,
	};
	const appointments = await fetchAppointments(ctdent_req);
	return appointments;
}

//* fetch first shared appointments to populate table
export async function fetchNextSharedAppointments(doctorId) {
	const ctdent_req = {
		api_key: CTDENT_API_KEY,
		offset: 0,
		limit: 10000, //TODO: pagination
		doctor_id: doctorId,
	};
	const ctdent_appointments_response = await instance.post(
		'doctor/referredappointments',
		ctdent_req,
	);
	const appointments_response = ctdent_appointments_response.data.data;
	const copiedAppointment = appointments_response.copied;
	const sharedAppointment = appointments_response.shared;

	const allSharedAppointments = copiedAppointment.concat(sharedAppointment);
	return allSharedAppointments;
}

// * fetch all appointments
async function fetchAppointments(ctdent_req) {
	const ctdent_appointments_response = await instance.post(
		'appointment/all',
		ctdent_req,
	);
	const appointments_response = ctdent_appointments_response.data.data;
	return appointments_response;
}

// * fetch appointment other cases
export async function otherCasesAppointments(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_GET_APPOINTMENT_OTHER_CASE,
		requestParam,
		reqConfig,
	);
	return response;
}

// * submit/create appointment
export async function createAppointment(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_CREATE,
		requestParam,
		reqConfig,
	);
	return response;
}

// * upload appointment files
export async function uploadAppointmentFiles(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_UPLOAD_FILE,
		requestParam,
		reqConfig,
	);
	return response;
}
//fetch appointment by id
export async function getAppointmentById(requestParam) {
	const response = await instance.get(
		APPOINMENT_BY_ID,
		requestParam,
		apiConfig(),
	);
	return response.data.responseData;
}

// * fetch price list
export async function fetchPriceList(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.get(DOCTOR_PRICE_LIST_UPDATED, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

export async function postJoinAccountRequest(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_POST_JOIN_ACCOUNT,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  send case/email to patient
export async function sendCaseToPatient(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_SEND_EMAIL,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  fetch/get appointment
export async function fetchAppointment(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_GET,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  archive appointment
export async function archiveAppointment(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_ARCHIVE,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  update appointment
export async function updateAppointmentData(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_UPDATE,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  update appointment date
export async function updateAppointmentDate(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_DATE_UPDATE,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  update appointment price
export async function updateAppointmentPrice(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_PRICE_UPDATE,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  update appointment payment
export async function updateAppointmentPayment(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_PAYMENT_UPDATE,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  share appointment
export async function shareAppointmentData(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_SHARE,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  appointment price breakdown
export async function appointmentPriceBreakdown(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_PRICE_BREAKDOWN,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  appointment success update/email
export async function appointmentSuccessUpdate(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_SUCCESS_UPDATE,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  update appointment status by partner
export async function partnerAppointmentStatusUpdate(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_PARTNER_APPOINTMENT_STATUS_UPDATE,
		requestParam,
		reqConfig,
	);
	return response;
}

export async function getAppointmentFiles(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.get(CTDENT_APPOINTMENT_GET_UPLOADED_FILE, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

export async function removeAppointmentFiles(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_REMOVE_UPLOADED_FILE,
		requestParam,
		reqConfig,
	);
	return response;
}

export async function getSearchedAppointments(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		APPOINMENT_SEARCH,
		requestParam,
		reqConfig,
	);
	return response;
}

// *  regenerate appointment invoice
export async function regenerateAppointmentInvoice(requestParam) {
	const reqConfig = apiConfig();
	const response = await instance.post(
		CTDENT_APPOINTMENT_REGENERATE_INVOICE,
		requestParam,
		reqConfig,
	);
	return response;
}