import { createSlice } from '@reduxjs/toolkit';
import {
	fetchCalendarSlots,
	fetchConnectedAccounts,
} from '../services/doctors/doctors.service';

import { getResponseTime } from '../utils/globals.utils';

export const calendarSlice = createSlice({
	name: 'calendar',

	initialState: {
		error: {},
		slotsLoading: false,
		slotList: null,
		accountsLoading: false,
		accountList: null,
	},

	reducers: {
		// * calendar slots
		slotRequestLoading(state) {
			if (!state.slotsLoading) {
				state.slotsLoading = true;
			}
		},

		slotRequestSucceed(state, action) {
			state.slotsLoading = false;
			state.slotList = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		slotRequestFailed(state, action) {
			if (state.slotsLoading) {
				state.slotsLoading = false;
				state.error = action.payload;
			}
		},

		// * connected accounts
		accountsRequestLoading(state) {
			if (!state.accountsLoading) {
				state.accountsLoading = true;
			}
		},

		accountsRequestSucceed(state, action) {
			state.accountsLoading = false;
			state.accountList = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		accountsRequestFailed(state, action) {
			if (state.accountsLoading) {
				state.accountsLoading = false;
				state.error = action.payload;
			}
		},
	},
});

export const {
	slotRequestLoading,
	slotRequestSucceed,
	slotRequestFailed,
	accountsRequestLoading,
	accountsRequestSucceed,
	accountsRequestFailed,
} = calendarSlice.actions;

/**
 * REDUX THUNKS
 * Thunks dispatch action creators
 * */

// * calendar slots
export const submitSlotRequest = (requestParam) => async (dispatch) => {
	dispatch(slotRequestLoading());
	try {
		const response = await fetchCalendarSlots(requestParam);
		dispatch(slotRequestSucceed(response));
	} catch (error) {
		dispatch(slotRequestFailed(error));
	}
};
export const isSlotRequestLoading = (state) => {
	return state.calendar.slotsLoading;
};
export const getSlots = (state) => state?.calendar?.slotList?.data || [];

// * connected accounts
export const submitAccountsRequest = (requestParam) => async (dispatch) => {
	dispatch(accountsRequestLoading());
	try {
		const response = await fetchConnectedAccounts(requestParam);
		dispatch(accountsRequestSucceed(response));
	} catch (error) {
		dispatch(accountsRequestFailed(error));
	}
};
export const isAccountRequestLoading = (state) => {
	return state.calendar.accountsLoading;
};
export const getConnectedAccounts = (state) =>
	state?.calendar?.accountList?.data?.responseData || [];

export default calendarSlice.reducer;
