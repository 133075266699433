// * filled
import filled11 from '../assets/images/teeth/11_filled.png';
import filled12 from '../assets/images/teeth/12_filled.png';
import filled13 from '../assets/images/teeth/13_filled.png';
import filled14 from '../assets/images/teeth/14_filled.png';
import filled15 from '../assets/images/teeth/15_filled.png';
import filled16 from '../assets/images/teeth/16_filled.png';
import filled17 from '../assets/images/teeth/17_filled.png';
import filled18 from '../assets/images/teeth/18_filled.png';
import filled21 from '../assets/images/teeth/21_filled.png';
import filled22 from '../assets/images/teeth/22_filled.png';
import filled23 from '../assets/images/teeth/23_filled.png';
import filled24 from '../assets/images/teeth/24_filled.png';
import filled25 from '../assets/images/teeth/25_filled.png';
import filled26 from '../assets/images/teeth/26_filled.png';
import filled27 from '../assets/images/teeth/27_filled.png';
import filled28 from '../assets/images/teeth/28_filled.png';
import filled31 from '../assets/images/teeth/31_filled.png';
import filled32 from '../assets/images/teeth/32_filled.png';
import filled33 from '../assets/images/teeth/33_filled.png';
import filled34 from '../assets/images/teeth/34_filled.png';
import filled35 from '../assets/images/teeth/35_filled.png';
import filled36 from '../assets/images/teeth/36_filled.png';
import filled37 from '../assets/images/teeth/37_filled.png';
import filled38 from '../assets/images/teeth/38_filled.png';
import filled41 from '../assets/images/teeth/41_filled.png';
import filled42 from '../assets/images/teeth/42_filled.png';
import filled43 from '../assets/images/teeth/43_filled.png';
import filled44 from '../assets/images/teeth/44_filled.png';
import filled45 from '../assets/images/teeth/45_filled.png';
import filled46 from '../assets/images/teeth/46_filled.png';
import filled47 from '../assets/images/teeth/47_filled.png';
import filled48 from '../assets/images/teeth/48_filled.png';

// * outlined
import outlined11 from '../assets/images/teeth/11_outlined.png';
import outlined12 from '../assets/images/teeth/12_outlined.png';
import outlined13 from '../assets/images/teeth/13_outlined.png';
import outlined14 from '../assets/images/teeth/14_outlined.png';
import outlined15 from '../assets/images/teeth/15_outlined.png';
import outlined16 from '../assets/images/teeth/16_outlined.png';
import outlined17 from '../assets/images/teeth/17_outlined.png';
import outlined18 from '../assets/images/teeth/18_outlined.png';
import outlined21 from '../assets/images/teeth/21_outlined.png';
import outlined22 from '../assets/images/teeth/22_outlined.png';
import outlined23 from '../assets/images/teeth/23_outlined.png';
import outlined24 from '../assets/images/teeth/24_outlined.png';
import outlined25 from '../assets/images/teeth/25_outlined.png';
import outlined26 from '../assets/images/teeth/26_outlined.png';
import outlined27 from '../assets/images/teeth/27_outlined.png';
import outlined28 from '../assets/images/teeth/28_outlined.png';
import outlined31 from '../assets/images/teeth/31_outlined.png';
import outlined32 from '../assets/images/teeth/32_outlined.png';
import outlined33 from '../assets/images/teeth/33_outlined.png';
import outlined34 from '../assets/images/teeth/34_outlined.png';
import outlined35 from '../assets/images/teeth/35_outlined.png';
import outlined36 from '../assets/images/teeth/36_outlined.png';
import outlined37 from '../assets/images/teeth/37_outlined.png';
import outlined38 from '../assets/images/teeth/38_outlined.png';
import outlined41 from '../assets/images/teeth/41_outlined.png';
import outlined42 from '../assets/images/teeth/42_outlined.png';
import outlined43 from '../assets/images/teeth/43_outlined.png';
import outlined44 from '../assets/images/teeth/44_outlined.png';
import outlined45 from '../assets/images/teeth/45_outlined.png';
import outlined46 from '../assets/images/teeth/46_outlined.png';
import outlined47 from '../assets/images/teeth/47_outlined.png';
import outlined48 from '../assets/images/teeth/48_outlined.png';

/**
 * Teeth images for checkbox
 */
export const teethImages = {
	filled: {
		filled11: filled11,
		filled12: filled12,
		filled13: filled13,
		filled14: filled14,
		filled15: filled15,
		filled16: filled16,
		filled17: filled17,
		filled18: filled18,
		filled21: filled21,
		filled22: filled22,
		filled23: filled23,
		filled24: filled24,
		filled25: filled25,
		filled26: filled26,
		filled27: filled27,
		filled28: filled28,
		filled31: filled31,
		filled32: filled32,
		filled33: filled33,
		filled34: filled34,
		filled35: filled35,
		filled36: filled36,
		filled37: filled37,
		filled38: filled38,
		filled41: filled41,
		filled42: filled42,
		filled43: filled43,
		filled44: filled44,
		filled45: filled45,
		filled46: filled46,
		filled47: filled47,
		filled48: filled48,
	},
	outlined: {
		outlined11: outlined11,
		outlined12: outlined12,
		outlined13: outlined13,
		outlined14: outlined14,
		outlined15: outlined15,
		outlined16: outlined16,
		outlined17: outlined17,
		outlined18: outlined18,
		outlined21: outlined21,
		outlined22: outlined22,
		outlined23: outlined23,
		outlined24: outlined24,
		outlined25: outlined25,
		outlined26: outlined26,
		outlined27: outlined27,
		outlined28: outlined28,
		outlined31: outlined31,
		outlined32: outlined32,
		outlined33: outlined33,
		outlined34: outlined34,
		outlined35: outlined35,
		outlined36: outlined36,
		outlined37: outlined37,
		outlined38: outlined38,
		outlined41: outlined41,
		outlined42: outlined42,
		outlined43: outlined43,
		outlined44: outlined44,
		outlined45: outlined45,
		outlined46: outlined46,
		outlined47: outlined47,
		outlined48: outlined48,
	},
};
