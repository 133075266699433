import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import tour1 from '../../../assets/images/tour/tour-1.png';
import tour2 from '../../../assets/images/tour/tour-2.png';
import tour3 from '../../../assets/images/tour/tour-3.png';
import tour4 from '../../../assets/images/tour/tour-4.png';
import tour5 from '../../../assets/images/tour/tour-5.png';
import tour6 from '../../../assets/images/tour/tour-6.png';
import tour7 from '../../../assets/images/tour/tour-7.png';
import tour8 from '../../../assets/images/tour/tour-8.png';
import tour9 from '../../../assets/images/tour/tour-9.png';
import tour10 from '../../../assets/images/tour/tour-10.png';
import { productTourStyle } from '../../../assets/styles/styles';
import { useMediaQuery } from 'react-responsive';
import { RESPONSIVE_MOBILE_QUERY } from '../../../utils/constants';

export default function ProductTourModal({ open, onClose }) {
  const [maxWidth] = React.useState('lg');
  const productTourClasses = productTourStyle();
  const isTabletOrMobile = useMediaQuery(RESPONSIVE_MOBILE_QUERY);
  const tourProductNavButtonsStyle = {
    backgroundColor: '#C8DA2E',
    borderRadius: 0,
    width: '35px',
    height: '35px',
    color: 'black'
  }
  const tourProductNavButtonsWrapperStyle = {
    bottom: '-35px',
    top: 'unset'
  }
  const slides = [
    {
      'key': 1,
      'name': 'What’s New?',
      'image': tour1,
      'isUlHtml': 0,
      'description': [
        'Welcome to our <span class="bold">NEW Management System for Doctors!</span>',
        'Learn what’s new in the following slides.',
      ],
    },
    {
      'key': 2,
      'name': 'The Beauty and the Speed',
      'image': tour2,
      'isUlHtml': 1,
      'description': [
        'Updated design, familiar interface',
        'Fast and seamless operation',
        'Improved search and filters',
      ],
    },
    {
      'key': 3,
      'name': 'Secured Cloud Storage',
      'image': tour3,
      'isUlHtml': 1,
      'description': [
        'All patients’ files kept at CTdent’s secured cloud',
        'E-mail and SMS notifications sent when new results are ready',
        'Cloud storage and notifications – set as default, at no cost',
        'Additional output options are available',
      ],
    },
    {
      'key': 4,
      'name': 'Choose AI Reports',
      'image': tour4,
      'isUlHtml': 1,
      'description': [
        'New <span class="bold">AI reports section</span> in the referral form',
        'AI report options fit the selected CBCT formats and the justifications',
      ],
    },
    {
      'key': 5,
      'name': 'See Price Breakdown',
      'image': tour5,
      'isUlHtml': 1,
      'description': [
        'Prices automatically added according to the selected services in the referral form',
        '<span class="green">Click to view breakdown</span>',
        'Your discounts still apply, and are reduced from the full prices',
      ],
    },
    {
      'key': 6,
      'name': 'Updated Price List',
      'image': tour6,
      'isUlHtml': 1,
      'description': [
        '<span class="green">View Price list under your details menu</span>',
        'Prices slightly updated',
        'Prices rounded to the nearest £5 increments',
      ],
    },
    {
      'key': 7,
      'name': 'More Booking Options',
      'image': tour7,
      'isUlHtml': 1,
      'description': [
          'Booking options appear only AFTER sending a referral form',
          'Do the booking yourself or let CTdent team, the patient or <span class="bold">an assistant</span> do the booking',
          'Automatic e-mail requests are sent to the selected booking party',
          '<span class="green">Define assistant’s e-mail at your profile, to save time</span>',
          'You can also book directly via the <span class="bold">“Book a Patient”</span> tab',
      ],
    },
    {
      'key': 8,
      'name': 'Manage Invoices Easier',
      'image': tour8,
      'isUlHtml': 1,
      'description': [
        '<span class="green">Search and review invoices</span>',
        'Balance display',
        'One click payments',
      ],
    },
    {
      'key': 9,
      'name': 'Pay via Stripe',
      'image': tour9,
      'isUlHtml': 1,
      'description': [
          '<span class="green">Add your Credit Card details in your profile</span>',
          'Details kept on a secured server at Stripe',
          'Secured one click payments, once a month',
          'Update Credit Card details whenever you change a card',
      ],
    },
    {
      'key': 10,
      'name': 'Various Support Options',
      'image': tour10,
      'isUlHtml': 1,
    }
  ];

  return (
    <React.Fragment>
      <Dialog
        maxWidth={maxWidth}
        fullScreen={isTabletOrMobile}
        open={open}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        onBackdropClick="false"
        className={`popup-model ${productTourClasses.modal}`}
      >
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Skip
            </Button>
        </DialogActions>
        <DialogContent>
          <DialogContentText>
            <Carousel
                fullHeightHover={false}
                navButtonsProps={{
                  style: tourProductNavButtonsStyle
                }}
                navButtonsWrapperProps={{
                  style: tourProductNavButtonsWrapperStyle
                }}
                indicatorContainerProps={{
                  style: {
                    width: '100%',
                    marginTop: isTabletOrMobile ? '80px' : '10px',
                    textAlign: 'center',
                  }
                }}
                changeOnFirstRender={true}
                animation={"slide"}
                swipe={true}
                autoPlay={false}
                cycleNavigation={false}
                navButtonsAlwaysVisible={true}
                stopAutoPlayOnHover={false}
								className={productTourClasses.productTourSlide}
            >
              {
                slides.map( (slide) => <Item slide={slide} onClose={onClose} key={slide.key.toString()}/> )
              }
            </Carousel>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const Item = ({
  slide,
  onClose,
  key,
}) => {
  const productTourClasses = productTourStyle();
  const isTabletOrMobile = useMediaQuery(RESPONSIVE_MOBILE_QUERY);
  return (
    <Paper className={productTourClasses.paperCarousel}>
        {isTabletOrMobile ? <h2>{slide.name}</h2> : <h1>{slide.name}</h1>}
        <div class="parent-image model-img">
          <img src={slide.image} alt="" class={slide.className}/>
        </div>
        {
          slide.key == 10
          ? <div className={productTourClasses.finishButton}>
              <Button
                  onClick={onClose}
                  color="primary.dark"
                  size="small"
                >
                Finish
              </Button>
            </div>
          : ''
        }
        <div className={slide.isUlHtml ? 'parent' : 'parent-content'}>
          {
            slide.description && slide.isUlHtml
            ? <ItemDescription items={slide.description} key={slide.key.toString()}/>
            : ''
          }
          {
            slide.description && !slide.isUlHtml
            ? slide.description.map(function(object){
                return renderHTML(object);
              })
            : ''
          }
        </div>
    </Paper>
  )
}

const ItemDescription = (
  props
) => {
  return (
    <ul>
      { props.items &&
        props.items.map(function(object, i){
          return <li>{renderHTML(object)}</li>;
        })
      }
    </ul>
  )
}

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });