import { createSlice } from '@reduxjs/toolkit';

import {
	getAllInvoices,
	getDebitBalance,
	getInvoiceDetail,
	stripeSaveCard,
	saveBillingDetails,
	saveBillingInvoiceEmailDetails,
	saveBillingAddressDetails,
} from '../../services/doctors/stripe.service';
import { getResponseTime } from '../../utils/globals.utils';

export const stripeSlice = createSlice({
	name: 'stripe',

	initialState: {
		error: {},
		saveCardLoading: false,
		saveCardData: null,
		invoicesLoading: false,
		invoicesData: null,
		debitBalanceLoading: false,
		debitBalanceData: null,
		invoiceDetailLoading: false,
		invoiceDetailData: null,
		saveBillingDetailsLoading: false,
		saveBillingDetailsData: null,
		saveBillingInvoiceEmailDetailsLoading: false,
		saveBillingInvoiceEmailDetailsData: null,
		saveBillingAddressDetailsLoading: false,
		saveBillingAddressDetailsData: null,
	},

	reducers: {
		// * Stripe save card
		saveCardRequestLoading(state, action) {
			if (!state.saveCardLoading) {
				state.saveCardLoading = true;
			}
		},

		saveCardRequestSucceed(state, action) {
			state.saveCardLoading = false;
			state.saveCardData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		saveCardRequestFailed(state, action) {
			if (state.saveCardLoading) {
				state.saveCardLoading = false;
				state.error = action.payload;
			}
		},

		// * Save billing details
		saveBillingDetailsRequestLoading(state, action) {
			if (!state.saveBillingDetailsLoading) {
				state.saveBillingDetailsLoading = true;
			}
		},

		saveBillingDetailsRequestSucceed(state, action) {
			state.saveBillingDetailsLoading = false;
			state.saveBillingDetailsData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		saveBillingDetailsRequestFailed(state, action) {
			if (state.saveBillingDetailsLoading) {
				state.saveBillingDetailsLoading = false;
				state.error = action.payload;
			}
		},

		// * Save billing invoice email details
		saveBillingInvoiceEmailRequestLoading(state, action) {
			if (!state.saveBillingInvoiceEmailDetailsLoading) {
				state.saveBillingInvoiceEmailDetailsLoading = true;
			}
		},

		saveBillingInvoiceEmailDetailsRequestSucceed(state, action) {
			state.saveBillingInvoiceEmailDetailsLoading = false;
			state.saveBillingInvoiceEmailDetailsData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		saveBillingInvoiceEmailDetailsRequestFailed(state, action) {
			if (state.saveBillingInvoiceEmailDetailsLoading) {
				state.saveBillingInvoiceEmailDetailsLoading = false;
				state.error = action.payload;
			}
		},

		// * Save billing address details
		saveBillingAddressRequestLoading(state, action) {
			if (!state.saveBillingAddressDetailsLoading) {
				state.saveBillingAddressDetailsLoading = true;
			}
		},

		saveBillingAddressDetailsRequestSucceed(state, action) {
			state.saveBillingAddressDetailsLoading = false;
			state.saveBillingAddressDetailsData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		saveBillingAddressDetailsRequestFailed(state, action) {
			if (state.saveBillingAddressDetailsLoading) {
				state.saveBillingAddressDetailsLoading = false;
				state.error = action.payload;
			}
		},

		// * Stripe get invoices
		invoicesRequestLoading(state, action) {
			if (!state.invoicesLoading) {
				state.invoicesLoading = true;
			}
		},

		invoicesRequestSucceed(state, action) {
			state.invoicesLoading = false;
			state.invoicesData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		invoicesRequestFailed(state, action) {
			if (state.invoicesLoading) {
				state.invoicesLoading = false;
				state.error = action.payload;
			}
		},

		// * Stripe get debit balance
		debitBalanceRequestLoading(state, action) {
			if (!state.debitBalanceLoading) {
				state.debitBalanceLoading = true;
			}
		},

		debitBalanceRequestSucceed(state, action) {
			state.debitBalanceLoading = false;
			state.debitBalanceData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		debitBalanceRequestFailed(state, action) {
			if (state.debitBalanceLoading) {
				state.debitBalanceLoading = false;
				state.error = action.payload;
			}
		},

		// * Stripe get invoice detail
		invoiceDetailRequestLoading(state, action) {
			if (!state.invoiceDetailLoading) {
				state.invoiceDetailLoading = true;
			}
		},

		invoiceDetailRequestSucceed(state, action) {
			state.invoiceDetailLoading = false;
			state.invoiceDetailData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		invoiceDetailRequestFailed(state, action) {
			if (state.invoiceDetailLoading) {
				state.invoiceDetailLoading = false;
				state.error = action.payload;
			}
		},
	},
});

export const {
	saveCardRequestLoading,
	saveCardRequestSucceed,
	saveCardRequestFailed,
	saveBillingDetailsRequestLoading,
	saveBillingDetailsRequestSucceed,
	saveBillingDetailsRequestFailed,
	saveBillingInvoiceEmailRequestLoading,
	saveBillingInvoiceEmailDetailsRequestSucceed,
	saveBillingInvoiceEmailDetailsRequestFailed,
	saveBillingAddressRequestLoading,
	saveBillingAddressDetailsRequestSucceed,
	saveBillingAddressDetailsRequestFailed,
	invoicesRequestLoading,
	invoicesRequestSucceed,
	invoicesRequestFailed,
	debitBalanceRequestLoading,
	debitBalanceRequestSucceed,
	debitBalanceRequestFailed,
	invoiceDetailRequestLoading,
	invoiceDetailRequestSucceed,
	invoiceDetailRequestFailed,
} = stripeSlice.actions;

/**
 * REDUX THUNKS
 * Thunks dispatch action creators
 * */

// * Stripe save card
export const saveCard = (requestParam) => async (dispatch) => {
	dispatch(saveCardRequestLoading());
	try {
		const response = await stripeSaveCard(requestParam);
		dispatch(saveCardRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(saveCardRequestFailed(error));
	}
};
export const isSaveCardLoading = (state) => state.stripe.saveCardLoading;
export const getSaveCardResponse = (state) =>
	state?.stripe?.saveCardData?.data?.responseData || [];

// * Save billing details
export const postSaveBillingDetails = (requestParam) => async (dispatch) => {
	dispatch(saveBillingDetailsRequestLoading());
	try {
		const response = await saveBillingDetails(requestParam);
		dispatch(saveBillingDetailsRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(saveBillingDetailsRequestFailed(error));
	}
};
export const isSaveBillingDetailsLoading = (state) =>
	state.stripe.saveBillingDetailsLoading;
export const getSaveBillingDetailsResponse = (state) =>
	state?.stripe?.saveBillingDetailsData?.data?.responseData || [];

// * Save billing invoice email details
export const postSaveBillingInvoiceEmailDetails = (requestParam) => async (dispatch) => {
	dispatch(saveBillingInvoiceEmailRequestLoading());
	try {
		const response = await saveBillingInvoiceEmailDetails(requestParam);
		dispatch(saveBillingInvoiceEmailDetailsRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(saveBillingInvoiceEmailDetailsRequestFailed(error));
	}
};
export const isSaveBillingInvoiceEmailDetailsLoading = (state) =>
	state.stripe.saveBillingInvoiceEmailDetailsLoading;
export const getSaveBillingInvoiceEmailDetailsResponse = (state) =>
	state?.stripe?.saveBillingDetailsData?.data?.responseData || [];

// * Save billing address details
export const postSaveBillingAddressDetails = (requestParam) => async (dispatch) => {
	dispatch(saveBillingAddressRequestLoading());
	try {
		const response = await saveBillingAddressDetails(requestParam);
		dispatch(saveBillingAddressDetailsRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(saveBillingAddressDetailsRequestFailed(error));
	}
};
export const isSaveBillingAddressDetailsLoading = (state) =>
	state.stripe.saveBillingAddressDetailsLoading;
export const getSaveBillingAddressDetailsResponse = (state) =>
	state?.stripe?.saveBillingDetailsData?.data?.responseData || [];

// * Stripe get invoices
export const stripeInvoices = (requestParam) => async (dispatch) => {
	dispatch(invoicesRequestLoading());
	try {
		const response = await getAllInvoices(requestParam);
		dispatch(invoicesRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(invoicesRequestFailed(error));
	}
};
export const isInvoicesLoading = (state) => state.stripe.invoicesLoading;
export const getInvoicesResponse = (state) =>
	state?.stripe?.invoicesData?.data?.responseData || [];

// * Stripe get invoices
export const stripeInvoiceDebitBalance = (requestParam) => async (dispatch) => {
	dispatch(debitBalanceRequestLoading());
	try {
		const response = await getDebitBalance(requestParam);
		dispatch(debitBalanceRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(debitBalanceRequestFailed(error));
	}
};
export const isDebitBalanceLoading = (state) =>
	state.stripe.debitBalanceLoading;
export const getDebitBalanceResponse = (state) =>
	state?.stripe?.debitBalanceData?.data?.responseData || null;

// * Stripe get invoices
export const stripeInvoiceDetail = (requestParam) => async (dispatch) => {
	dispatch(invoiceDetailRequestLoading());
	try {
		const response = await getInvoiceDetail(requestParam);
		dispatch(invoiceDetailRequestSucceed(response));
		return response;
	} catch (error) {
		dispatch(invoiceDetailRequestFailed(error));
	}
};
export const isInvoiceDetailLoading = (state) =>
	state.stripe.invoiceDetailLoading;
export const getInvoiceDetailResponse = (state) =>
	state?.stripe?.invoiceDetailData?.data?.responseData || [];

export default stripeSlice.reducer;
