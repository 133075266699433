import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import MUILink from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { HELP_MENU_ID } from '../../../utils/constants';
import { doctorResourceRoutes } from '../../../routing/routes/doctorRoutes';
import {
	getSupportResponse,
	isSupportRequestLoading,
	resetSupportData,
	submitSupportRequest,
} from '../../../store/commonSlice';
import { selectLoggedInUser, setLoggedInUser } from '../../../store/globalSlice';
import { getRouteByLayoutAndPath } from '../../../utils/routing.utils';
import { getUserId, toastSuccess } from '../../../utils/globals.utils';
import { useNavbarStyles } from './AppNavbar.style';
import ProductTourModal from './ProductTourModal';
import { updateDoctorProductModal } from '../../../services/auth.service';
import { myProfileDetails } from '../../../store/signup/signupSlice';
import { ChristmasPopup } from '../../general/ChristmasPopup';

const DialogBox = ({
	dialogTitle,
	type,
	isOpen,
	isLoading,
	toggle,
	setValue,
	onSubmit,
	content,
}) => {
	return (
		<Dialog
			open={isOpen}
			onClose={() => toggle(!isOpen)}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
			<DialogContent>
				<TextField
					type="text"
					variant="outlined"
					size="small"
					label={type === 'support' ? 'Support Title' : 'Report Title'}
					name="title"
					margin="normal"
					InputLabelProps={{
						shrink: true,
					}}
					required
					fullWidth
					onChange={setValue}
					value={content?.title}
					//   error={errors?.patient_email && submitCount}
				/>
				<TextField
					variant="outlined"
					size="small"
					label={type === 'support' ? 'Support Message' : 'Report Message'}
					name="message"
					margin="normal"
					InputLabelProps={{
						shrink: true,
					}}
					minRows={4}
					required
					fullWidth
					multiline
					onChange={setValue}
					value={content?.message}
					// error={errors?.indication_for_scan && submitCount}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => toggle(!isOpen)}
					color="secondary"
					variant="contained"
					size="small"
				>
					Close
				</Button>
				<Button
					onClick={() => onSubmit(type)}
					color="primary"
					variant="contained"
					size="small"
					style={{ minHeight: '30px' }}
				>
					{isLoading ? (
						<CircularProgress color="secondary" size={20} />
					) : (
						'Submit'
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const NavbarHelpMenu = (props) => {
	const dispatch = useDispatch();
	const classes = useNavbarStyles();

	const { t } = useTranslation();
	const message = window ? `\n\n\n\n\nURL: ${window.location.href}` : '';

	const [isSupportOpen, setSupportOpen] = useState(false);
	const [isProductTourOpen, setProductTourOpen] = useState(false);
	const [isReportOpen, setReportOpen] = useState(false);
	const [supportContent, setSupportContent] = useState({
		title: 'SUPPORT - Doctors Portal-CTDent',
		message: message,
	});
	const [reportContent, setReportContent] = useState({
		title: 'REPORT - Doctors Portal-CTDent',
		message: message,
	});

	const doctor = useSelector(selectLoggedInUser);
	const supportResponseData = useSelector(getSupportResponse);
	const isLoading = useSelector(isSupportRequestLoading);

	const isMenuOpen = Boolean(props.anchorEl);

	//* handle changes
	const handleSupportBoxChange = (e) => {
		if (e?.target?.name === 'title') {
			setSupportContent({ ...supportContent, title: e?.target?.value });
		} else {
			setSupportContent({ ...supportContent, message: e?.target?.value });
		}
	};

	const handleReportBoxChange = (e) => {
		if (e?.target?.name === 'title') {
			setReportContent({ ...reportContent, title: e?.target?.value });
		} else {
			setReportContent({ ...reportContent, message: e?.target?.value });
		}
	};

	// const handleProductTour = () => {
	// 	const pdfURL = process.env.REACT_APP_PRODUCT_TOUR_LINK;

	// 	if (window) {
	// 		window.open(pdfURL, '_blank');
	// 	}
	// };

	//* toggle popup
	const toggleSupport = (value) => {
		setSupportOpen(value);
		setSupportContent({ ...supportContent, message: message });
	};
	const toggleReport = (value) => {
		setReportOpen(value);
		setReportContent({ ...reportContent, message: message });
	};
	const toggleProductTour = (value) => {
		setProductTourOpen(value);
	};

	//* submit support/problem request
	const doSubmit = (type) => {
		const apiData = {
			doctor_id: getUserId(),
			branch_id: doctor?.branch_id || null,
			title: type === 'support' ? supportContent?.title : reportContent?.title,
			content:
				type === 'support' ? supportContent?.message : reportContent?.message,
			type: type === 'support' ? 'support_inbox' : 'report_problem',
		};
		dispatch(submitSupportRequest(apiData));
	};

	useEffect(() => {
		if (supportResponseData && supportResponseData?.responseCode === 200) {
			toastSuccess(supportResponseData?.responseMessage);
			setSupportOpen(false);
			setReportOpen(false);
		}
		return () => {
			dispatch(resetSupportData());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [supportResponseData]);

	useEffect(() => {
		if(doctor && doctor.gdc_number && !doctor.is_product_tour_completed && doctor?.dyno_isNewUser == 1){
			setTimeout(function(){
				updateDoctorProductModal({is_product_tour_completed: 1, doctor_id: doctor.id}).then((res) => {
					dispatch(myProfileDetails()).then((res) => {
						const userDetails = {
							...res.data.responseData.UserDetails,
							...res.data.responseData.preferedDetail,
							...res.data.responseData?.practiceDetail,
						};
						dispatch(setLoggedInUser(userDetails));
					});
				});
				setProductTourOpen(true);
			}, 3000);
		}
	}, [doctor]);

	return (
		<React.Fragment>
			<Popper
				open={isMenuOpen}
				anchorEl={props.anchorEl}
				role={undefined}
				transition
				disablePortal
				style={{
					zIndex: 99,
					marginTop: '9px',
				}}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={props.closeMenuClicked}>
								<MenuList id={HELP_MENU_ID} className={classes.menuList}>
									<MenuItem
										onClick={() => toggleProductTour(!isProductTourOpen)}
										className={classes.menuIem}
									>
										Product Tour
									</MenuItem>
									{doctorResourceRoutes.map((route) => (
										<MenuItem
											key={route.name}
											component={Link}
											onClick={props.closeMenuClicked}
											to={getRouteByLayoutAndPath(route.layout, route.path)}
											className={classes.menuIem}
										>
											{t(route.name)}
										</MenuItem>
									))}
									<MenuItem
										onClick={() => toggleSupport(!isSupportOpen)}
										className={classes.menuIem}
									>
										Support Inbox
									</MenuItem>
									<MenuItem
										onClick={() => toggleReport(!isReportOpen)}
										className={classes.menuIem}
									>
										Report a Problem
									</MenuItem>
									<Divider className={classes.divider} variant="middle" />
									<MenuItem className={classes.menuIem}>
										<Box>
											<Typography>Helpline: (0)20-7487-5717</Typography>
											<MUILink href="mailto:support@ct-dent.co.uk">
												support@ct-dent.co.uk
											</MUILink>
										</Box>
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>

			{isProductTourOpen && (
				<ProductTourModal
					open={isProductTourOpen}
					onClose={() => toggleProductTour(!isProductTourOpen)}
				/>
			)}

			<ChristmasPopup />

			{isSupportOpen && (
				<DialogBox
					dialogTitle="Support Inbox"
					type="support"
					isOpen={isSupportOpen}
					content={supportContent}
					isLoading={isLoading}
					toggle={toggleSupport}
					setValue={handleSupportBoxChange}
					onSubmit={doSubmit}
				/>
			)}
			{isReportOpen && (
				<DialogBox
					dialogTitle="Report a Problem"
					type="report"
					isOpen={isReportOpen}
					content={reportContent}
					isLoading={isLoading}
					toggle={toggleReport}
					setValue={handleReportBoxChange}
					onSubmit={doSubmit}
				/>
			)}
		</React.Fragment>
	);
};

export default NavbarHelpMenu;
