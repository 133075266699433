import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH } from '../../utils/constants';

export const useLayoutStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		flexGrow: 1,
		// padding: theme.spacing(2),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		flex: '1 0 auto',
		//full height minus the top navbar
		// minHeight: 'calc(100vh - 64px)',
		// marginLeft: -drawerWidth,
	},
	appBarSpacer: {
		height: '25px',
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: DRAWER_WIDTH,
	},
}));
